import { ThemeProvider, Tooltip } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import { APP_NAME } from "../../constants";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#f2f2f2",
        },
      },
    },
  },
});

const details = [
  {
    data: [
      {
        title: "BUILD SURVEYS TOGETHER",
        description:
          "Let people on your team view, edit, or comment on your surveys.",
        text: "Survey sharing with fine control over who can view and edit",
      },
      {
        title: "BUILD SURVEYS TOGETHER",
        description:
          "Let people on your team view, edit, or comment on your surveys.",
        text: "Survey sharing with fine control over who can view and edit",
      },
      {
        title: "BUILD SURVEYS TOGETHER",
        description:
          "Let people on your team view, edit, or comment on your surveys.",
        text: "Survey sharing with fine control over who can view and edit",
      },
    ],
  },
  {
    data: [
      {
        title: "COMMENTING FEATURE",
        description:
          " Invite people to provide feedback on your survey projects all in one place. Our commenting feature allows you toshare both survey drafts or results with anyone you want.",
        text: "Gather comments all in one place",
      },
      {
        title: "COMMENTING FEATURE",
        description:
          " Invite people to provide feedback on your survey projects all in one place. Our commenting feature allows you toshare both survey drafts or results with anyone you want.",
        text: "Gather comments all in one place",
      },
      {
        title: "COMMENTING FEATURE",
        description:
          " Invite people to provide feedback on your survey projects all in one place. Our commenting feature allows you toshare both survey drafts or results with anyone you want.",
        text: "Gather comments all in one place",
      },
    ],
  },
  {
    data: [
      {
        title: "Analyze results together",
        description:
          " Give your team access to survey results and export tools.",
        text: "Let team members analyze, filter, and export results",
      },
      {
        title: "Analyze results together",
        description:
          " Give your team access to survey results and export tools.",
        text: "Let team members analyze, filter, and export results",
      },
      {
        title: "Analyze results together",
        description:
          " Give your team access to survey results and export tools.",
        text: "Let team members analyze, filter, and export results",
      },
    ],
  },
  {
    data: [
      {
        title: "Response alerts",
        description:
          " Receive email notifications about new survey responses. You can share Response Alerts with other people (in or outside your team)-as long as you're the survey owner.",
        text: "Notify others when you get new responses",
      },
      {
        title: "Response alerts",
        description:
          " Receive email notifications about new survey responses. You can share Response Alerts with other people (in or outside your team)-as long as you're the survey owner.",
        text: "Notify others when you get new responses",
      },
      {
        title: "Response alerts",
        description:
          " Receive email notifications about new survey responses. You can share Response Alerts with other people (in or outside your team)-as long as you're the survey owner.",
        text: "Notify others when you get new responses",
      },
    ],
  },
  {
    data: [
      {
        title: "Shared library",
        description:
          " Get access to shared images, documents, themes, and templates that everyone in your team can use.",
        text: "Shared asset library for on-brand surveys",
      },
      {
        title: "Shared library",
        description:
          " Get access to shared images, documents, themes, and templates that everyone in your team can use.",
        text: "Shared asset library for on-brand surveys",
      },
      {
        title: "Shared library",
        description:
          " Get access to shared images, documents, themes, and templates that everyone in your team can use.",
        text: "Shared asset library for on-brand surveys",
      },
    ],
  },
  {
    data: [
      {
        title: "Contributor seats",
        description: `While purchasing a team plan, you can add Contributor seats as a cost-effective way to include more stakeholders to analyze survey results. Contributors can use ${APP_NAME} analysis features but cannot create or send surveys. Full Users on your team can share surveys with Contributors.`,
        text: "Add Contributor Seats",
      },
      {
        title: "Contributor seats",
        description: `While purchasing a team plan, you can add Contributor seats as a cost-effective way to include more stakeholders to analyze survey results. Contributors can use ${APP_NAME} analysis features but cannot create or send surveys. Full Users on your team can share surveys with Contributors.`,
        text: "Add Contributor Seats",
      },
      {
        title: "Contributor seats",
        description: `While purchasing a team plan, you can add Contributor seats as a cost-effective way to include more stakeholders to analyze survey results. Contributors can use ${APP_NAME} analysis features but cannot create or send surveys. Full Users on your team can share surveys with Contributors.`,
        text: "Advanced collaboration features",
      },
    ],
  },
  {
    data: [
      {
        title: "Team management",
        description:
          "If you're the Primary Admin or Admin of a team, you can invite more people to join the team and manage existing accounts.",
        text: "Add or reassign accounts at any time",
      },
      {
        title: "Team management",
        description:
          " If you're the Primary Admin or Admin of a team, you can invite more people to join the team and manage existing accounts.",
        text: "Add or reassign accounts at any time",
      },
      {
        title: "Team management",
        description:
          " If you're the Primary Admin or Admin of a team, you can invite more people to join the team and manage existing accounts.",
        text: "Add or reassign accounts at any time",
      },
    ],
  },
  {
    data: [
      {
        title: "Integrations",
        description: `${APP_NAME} integrates with popular apps like Office 365, Google Drive, and Slack, so it's easy to use with your existing workflows.`,
        text: "Free integrations with popular collaboration apps",
      },
      {
        title: "Integrations",
        description: `${APP_NAME} integrates with popular apps like Office 365, Google Drive, and Slack, so it's easy to use with your existing workflows.`,
        text: "Free integrations with popular collaboration apps",
      },
      {
        title: "Integrations",
        description: `${APP_NAME} integrates with popular apps like Office 365, Google Drive, and Slack, so it's easy to use with your existing workflows.`,
        text: "Free integrations with popular collaboration apps",
      },
    ],
  },
  {
    data: [
      {
        title: "Number of responses",
        description:
          "Each plan has a limited number of responses as indicated. Basic users must purchase a paid plan to view responses in excess of the Response Limit. For other plans, users will be invoiced up to $0.15 or equivalent, for each response over the indicated Response Limit.",
        text: "50,000 responses per year**",
      },
      {
        title: "Number of responses",
        description:
          " Each plan has a limited number of responses as indicated. Basic users must purchase a paid plan to view responses in excess of the Response Limit. For other plans, users will be invoiced up to $0.15 or equivalent, for each response over the indicated Response Limit.",
        text: "100,000 responses per year**",
      },
      {
        title: "Number of responses",
        description:
          " Each plan has a limited number of responses as indicated. Basic users must purchase a paid plan to view responses in excess of the Response Limit. For other plans, users will be invoiced up to $0.15 or equivalent, for each response over the indicated Response Limit.",
        text: "Unlimited surveys and questions",
      },
    ],
  },
  {
    data: [
      {
        title: "24/7 Customer support via email",
        description:
          "We offer 24/7 email support for all customers. Emails from ADVANTAGE and PREMIER customers are prioritized to receive responses as quickly as possible. We also offer phone support for our PREMIER customers.",
        text: "24/7 expedited email support",
      },
      {
        title: "24/7 Customer support via email",
        description:
          " We offer 24/7 email support for all customers. Emails from ADVANTAGE and PREMIER customers are prioritized to receive responses as quickly as possible. We also offer phone support for our PREMIER customers.",
        text: "Phone support and 24/7 email support",
      },
      {
        title: "24/7 Customer support via email",
        description:
          " We offer 24/7 email support for all customers. Emails from ADVANTAGE and PREMIER customers are prioritized to receive responses as quickly as possible. We also offer phone support for our PREMIER customers.",
        text: "Phone support and 24/7 email support",
      },
    ],
  },
  {
    data: [
      {
        title: "Quizzes",
        description:
          "Easily create tests and assessments with automatic scoring for employees, customers, students, and more. You can choose whether you want to display your respondent's score to them after they complete your quiz. You'll also be able to see rankings, segment response data, and access statistics for your results.",
        text: "24/7 expedited email support",
      },
      {
        title: "Quizzes",
        description:
          " Easily create tests and assessments with automatic scoring for employees, customers, students, and more. You can choose whether you want to display your respondent's score to them after they complete your quiz. You'll also be able to see rankings, segment response data, and access statistics for your results.",
        text: "Phone support and 24/7 email support",
      },
      {
        title: "Quizzes",
        description:
          " Easily create tests and assessments with automatic scoring for employees, customers, students, and more. You can choose whether you want to display your respondent's score to them after they complete your quiz. You'll also be able to see rankings, segment response data, and access statistics for your results.",
        text: "Phone support and 24/7 email support",
      },
    ],
  },
  {
    data: [
      {
        title: "Customized surveys",
        description:
          "Customize your survey's look and feel by adding your logo, selecting colors and web fonts, and uploading images. Create and save custom themes to quickly build on-brand surveys. You can also modify the ending of your survey's URL. For example: https://www.reviregenie.com/s/your-survey.",
        text: "Add or reassign accounts at any time",
      },
      {
        title: "Customized surveys",
        description:
          " Customize your survey's look and feel by adding your logo, selecting colors and web fonts, and uploading images. Create and save custom themes to quickly build on-brand surveys. You can also modify the ending of your survey's URL. For example: https://www.reviewgenie.com/s/your-survey",
        text: "Add or reassign accounts at any time",
      },
      {
        title: "Customized surveys",
        description:
          " Customize your survey's look and feel by adding your logo, selecting colors and web fonts, and uploading images. Create and save custom themes to quickly build on-brand surveys. You can also modify the ending of your survey's URL. For example: https://www.reviewgenie.com/s/your-survey",
        text: "Add or reassign accounts at any time",
      },
    ],
  },
  {
    data: [
      {
        title: "Customized survey experience",
        description:
          "Customize your survey's look and feel by adding your logo, selecting colors and web fonts, and uploading images. Create and save custom themes to quickly build on-brand surveys. You can also modify the ending of your survey's URL. For example: https://www.reviewgenie.com/s/your-survey",
        text: "Custom logo, colors, and survey URL",
      },
      {
        title: "Customized survey experience",
        description:
          "Customize your survey's look and feel by adding your logo, selecting colors and web fonts, and uploading images. Create and save custom themes to quickly build on-brand surveys. You can also modify the ending of your survey's URL. For example: https://www.reviewgenie.com/s/your-survey",
        text: "Custom logo, colors, and survey URL",
      },
      {
        title: "Customized survey experience",
        description:
          "Customize your survey's look and feel by adding your logo, selecting colors and web fonts, and uploading images. Create and save custom themes to quickly build on-brand surveys. You can also modify the ending of your survey's URL. For example: https://www.reviewgenie.com/s/your-survey",
        text: "Custom logo, colors, and survey URL",
      },
    ],
  },
];
const TeamPlans = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Table style={props} ref={ref}>
        <thead>
          <tr>
            <TopTh>
              <Ribbon backColor={"#007FAA"}>SAVE 22% *</Ribbon>
              <H2>TEAM ADVANTAGE</H2>
              <Text
                style={{
                  fontWeight: 400,
                  fontSize: "3rem",
                  marginTop: "2rem",
                  color: "black",
                }}
              >
                ₨ 1,550
                <span
                  style={{
                    fontSize: "2rem",
                    color: "#6B787F",
                    marginLeft: "5px",
                  }}
                >
                  / user / month
                </span>
              </Text>
            </TopTh>
            <TopTh>
              <Ribbon backColor={"#671E75"}>SAVE 22% *</Ribbon>
              <H2>TEAM PREMIER</H2>
              <Text
                style={{
                  fontWeight: 400,
                  fontSize: "3rem",
                  marginTop: "2rem",
                  color: "black",
                }}
              >
                ₨ 3,800
                <span
                  style={{
                    fontSize: "2rem",
                    color: "#6B787F",
                    marginLeft: "5px",
                  }}
                >
                  / user / month
                </span>
              </Text>
            </TopTh>
            <TopTh>
              <H2>ENTERPRISE</H2>
              <Text style={{ fontWeight: 300, marginTop: "2rem" }}>
                Powerful admin tools, integrations, and collaboration features
                for your organization.
              </Text>
            </TopTh>
          </tr>
          <tr>
            <Th>
              <Text style={{ marginTop: "1rem" }}>
                Starting at 3 users, billed annually
              </Text>
            </Th>
            <Th>
              <Text style={{ marginTop: "1rem" }}>
                Starting at 3 users, billed annually
              </Text>
            </Th>
            <Th>
              <Text style={{ marginTop: "1rem" }}></Text>
            </Th>
          </tr>
          <tr>
            <Th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverBackgroundColor={"#fff"}
                    hoverColor="#007FAA"
                    backColor={"#007FAA"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Th>
            <Th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverBackgroundColor={"#fff"}
                    hoverColor="#671E75"
                    backColor={"#671E75"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Th>
            <Th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverBackgroundColor={"#fff"}
                    hoverColor="#05467E"
                    backColor={"#05467E"}
                  >
                    CONTACT SALES
                  </Button>
                </CustomLink>
              </div>
            </Th>
          </tr>
        </thead>
        <tbody>
          {details.map((detail, index) => (
            <tr key={index}>
              {detail.data.map((item, index) => (
                <Td key={index}>
                  <ThemeProvider theme={theme}>
                    <Tooltip
                      title={
                        <div>
                          <Text
                            style={{
                              marginTop: "1rem",
                              fontWeight: 600,
                              color: "var(--color-primary)",
                            }}
                          >
                            {item.title}
                          </Text>
                          <Text style={{ marginTop: "1rem" }}>
                            {item.description}
                          </Text>
                        </div>
                      }
                      arrow
                      placement="top"
                    >
                      <TableText>
                        <ul>
                          <li style={{ fontWeight: "large" }}>{item.text}</li>
                        </ul>
                      </TableText>
                    </Tooltip>
                  </ThemeProvider>
                </Td>
              ))}
            </tr>
          ))}
          <tr>
            <LastTh>
              <Text style={{ textAlign: "left", margin: "2rem " }}>
                {/* <Link to="/">See all plan features</Link> */}
              </Text>
            </LastTh>
            <LastTh>
              <Text style={{ textAlign: "left", margin: "2rem " }}>
                {/* <Link to="/">See all plan features</Link> */}
              </Text>
            </LastTh>
            <LastTh>
              <Text style={{ textAlign: "left", margin: "2rem 2rem 0 2rem" }}>
                {/* <Link to="/">See all plan features</Link> */}
              </Text>
            </LastTh>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <Td style={{ border: "none" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverBackgroundColor={"#fff"}
                    hoverColor="#007FAA"
                    backColor={"#007FAA"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Td>
            <Td style={{ border: "none" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverBackgroundColor={"#fff"}
                    hoverColor="#671E75"
                    backColor={"#671E75"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Td>
            <Td style={{ border: "none" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverBackgroundColor={"#fff"}
                    hoverColor="#05467E"
                    backColor={"#05467E"}
                  >
                    CONTACT SALES
                  </Button>
                </CustomLink>
              </div>
            </Td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default TeamPlans;

const Text = styled.p`
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
  color: #6b787f;
  font-weight: 300;
  word-break: break-word;
  padding: 0 5px;
`;

const CustomLink = styled(Link)`
  width: 100%;
`;

const TableText = styled.p`
  font-size: 1.5rem;
  margin: 1rem 5px 0 5px;
  text-align: left;
  color: #6b787f;
  font-weight: 300;
  word-break: break-word;
`;

const Table = styled(animated.table)`
  border-collapse: separate;
  border-spacing: 20px 0;
  margin: 0 auto 4rem auto;
  // width: 1080px;
  width: 60%;
  tbody tr:hover td {
    border-color: var(--color-primary);
    background-color: #f9f9f9;
  }
  @media (max-width: 1550px) {
    width: 70%;
  }
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const TopTh = styled.th`
  position: relative;
  width: 33%;
  border-radius: 5px 5px 0 0;
  border: 1px solid #d0d2d3;
  border-bottom: 0;
  overflow: hidden;
`;

const Th = styled.th`
  width: 33%;
  border: 1px solid #d0d2d3;
  border-bottom: 0;
  border-top: 0;
`;
const Td = styled.td`
  width: 33%;
  border: 1px solid #d0d2d3;
  border-bottom: 0;
  border-top: 0;
`;

const H2 = styled.h2`
  color: #333e48;
  font-size: 21px;
  font-weight: 500;
  padding: 32px 5px 0 5px;
  text-align: center;
  word-break: break-word;
`;

const Ribbon = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  background-color: ${(props) => props.backColor};
  color: white;
  padding: 1px 0;
  transform: translate(50%, -50%) rotate(45deg);
  z-index: 1;
  font-size: 12px;
  width: 100%;
  text-align: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Button = styled.button`
  width: 90%;
  padding: 1rem 0;
  background-color: ${(props) => props.backColor};
  color: white;
  line-height: 1.5;
  font-size: 15px;
  border: 1px solid ${(props) => props.backColor};
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
    border: 1px solid ${(props) => props.hoverColor};
  }
  transition: all 0.5s ease;

  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

const LastTh = styled.th`
  width: 33%;
  border: 1px solid #d0d2d3;
  border-top: 0;
  border-radius: 0 0 5px 5px;
`;
