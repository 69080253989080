/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const Item = ({ heading, text }) => {
  return (
    <ItemBox>
      <ItemHeading>
        <img
          style={{
            maxWidth: "100%",
            height: "auto",
            width: "20px",
            marginRight: "10px",
          }}
          src="./image/degreeFeedback/14.svg"
        />
        {heading}
      </ItemHeading>
      <ItemText>{text}</ItemText>
    </ItemBox>
  );
};
const TailoredSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Content>
          <Heading>Tailored 360 Degree Feedback Process</Heading>
          <Text>
            At ReviewGenie, we simplify creating a unique 360 feedback process.
            Enjoy the flexibility to build custom questionnaires, choose rating
            scales, and select your preferred level of anonymity. Enhance your
            feedback strategy with ReviewGenie's comprehensive tools tailored to
            meet your specific needs.
          </Text>
        </Content>
      </Container>

      <Box style={props} ref={ref}>
        <LeftItem>
          <Img src="./image/degreeFeedback/Survey_Results.png"></Img>
        </LeftItem>
        <RightItem>
          <Item
            heading="Customizable Questionnaires"
            text="Create as many questionnaires as needed for your organization. Utilize sample questionnaires, copy questions from the gallery, or add custom rating, multiple-choice, and open-ended questions to tailor your 360 feedback process."
          />
          <Item
            heading="Flexible Rating Scales"
            text="Select the ideal rating scale for your 360 feedback surveys, ranging from a 3-point to a 10-point scale. Customize labels and add N/A options when necessary to suit your organization's needs."
          />
          <Item
            heading="Ensured Anonymity"
            text="Maintain fairness and confidentiality by comparing different managers' rating styles across the organization. View each manager’s lowest, highest, and median scores for all completed appraisals."
          />
          <Btn>
            <Link to="/demo-request">
              <ReqButton variant="primary">REQUEST DEMO</ReqButton>
            </Link>
          </Btn>
        </RightItem>
      </Box>
    </div>
  );
};

const Container = styled(animated.div)`
  // width: 80%;
  max-width: 1250px;
  margin: auto;
  position: relative;
  only screen and (min-width: 1350px) {
    padding: 27px 0;
  }
  @media (max-width: 980px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Content = styled.div`
  font-size: 18px;
  padding-right: 128px;
  padding-left: 128px;
  margin-bottom: 49px;
  text-align: center;
  word-wrap: break-word;
  @media (max-width: 980px) {
    padding-right: 0px;
    padding-left: 0px;
    text-align: left;
  }
`;

const Heading = styled.h1`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-weight: 300;
  color: #46586a;
`;

const Box = styled(animated.div)`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  padding-bottom: 8rem;
  @media (max-width: 980px) {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const LeftItem = styled.div`
  width: 100%;
  margin-right: 6rem;

  @media (max-width: 980px) {
    max-width: 60%;
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
const RightItem = styled.div`
  width: 100%;
  //   padding: 3.6rem 0 3.6rem 10rem;
  @media (max-width: 980px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const ItemBox = styled.div`
  margin-bottom: 3rem;
  text-align: left;
  word-wrap: break-word;
`;
const ItemHeading = styled.h3`
  font-size: 20px;
  line-height: 1.2em;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
  color: #161a24;
  font-size: 20px;
`;

const ItemText = styled.p`
  color: #46586a;
  font-size: 16px;
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ReqButton = styled(Button)`
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
  border-radius: 25px;
  font-weight: 100;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.2rem 1rem;
  }
`;
export default TailoredSection;
