import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import Button from "../../components/button";
import { Link } from "react-router-dom";

const WorkforceSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div
      ref={inViewRef}
      // style={{ boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.2)" }}
    >
      <WorkForce style={props} ref={ref}>
        <WorkForceBox>
          <WorkForceBoxLeftItem>
            <Heading>Create Your Own Surveys</Heading>
            <Text>
              Explore our platform to discover how easily you can find the
              answers you need with customizable surveys and forms.
            </Text>
            <WorkForceBtn>
              <Link to="/demo-request">
                <WorkForceButton variant="tertiary">
                  Request a Demo
                </WorkForceButton>
              </Link>
              <Link to="/all-templates">
                <WorkForceButton variant="secondary">
                  Explore Templates
                </WorkForceButton>
              </Link>
            </WorkForceBtn>
          </WorkForceBoxLeftItem>
        </WorkForceBox>
      </WorkForce>
    </div>
  );
};

const WorkForce = styled(animated.div)`
  background-image: url(./image/product/Product_Bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;

const WorkForceBox = styled.div`
  height: 71vh;
  padding: 15rem 0 8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 980px) {
    width: 90%;
    padding-top: 0;
  }
`;
const WorkForceBoxLeftItem = styled.div`
  justify-content: center;
`;
const Heading = styled.h1`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 40px;
  color: #000000;
  text-align: center;
  line-height: 1.4em;
  font-weight: 600;
  padding-bottom: 10px;
  margin: 0;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 2.1rem;
  font-weight: 400;
  color: black;
  opacity: 0.8;
  line-height: 2em;
  text-align: center;
  padding: 2rem 0;
  margin: 0;
`;
const WorkForceBtn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  padding-top: 2.5rem;
  justify-content: center;
  display: flex;
  gap: 2rem;
  width: 100%;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    width: 100%;
    font-size: 2rem;
  }
  @media (max-width: 470px) {
    width: 90%;
    flex-direction: column;
  }
`;
const WorkForceButton = styled(Button)`
  width: 100%;
  font-size: 1.7rem;
  border-radius: 28px;
  padding: 1.3rem 2rem;
  font-weight: 100;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.5rem 2rem;
  }
`;

export default WorkforceSection;
