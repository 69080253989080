import React from "react";
import Header from "../common/header/Header";
import HomeSection from "./Homesection";
import ContentSection from "./Contentsection";
import OrganizationSection from "./Organizationsection";
import ProductivitySection from "./Productivitysection";
import PrimalogikSection from "./Primalogiksection";
// import SubscribeSection from "../common/SubscribeSection";
import FooterSection from "../common/FooterSection";
import Faqssection from "./Faqssection";
import GetStartedSection from "../product/GetStartedSection";
import Blog from "./Blog";
import KeyFeaturesSection from "./KeyFeaturessection";
import { APP_NAME } from "../../constants";
// import PopularPlansection from "./PopularPlansection";

const Home = () => {
  document.title = `${APP_NAME}`;
  return (
    <>
      <Header />
      <HomeSection />
      <ContentSection />
      <ProductivitySection />
      <OrganizationSection />
      <KeyFeaturesSection />
      {/* <VideoSectionn /> */}
      <PrimalogikSection />
      {/* <PopularPlansection /> */}
      {/* <IntegrationsSection /> */}
      {/* <FreeebookSection /> */}
      <GetStartedSection />
      <Blog />
      <Faqssection />
      {/* <SubscribeSection /> */}
      <FooterSection />
    </>
  );
};

export default Home;
