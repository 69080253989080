import React, { useEffect, useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import Card from "./Card";
import api from "../../utils/api";
import { CircularProgress } from "@mui/material";

const Content = () => {
  const [activeTab, setActiveTab] = useState("");
  const [hasAnimated, setHasAnimated] = useState(false);
  const [blogs, setBlogs] = useState({
    data: [],
  });
  const [categories, setCategories] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [blogsLoading, setBlogsLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setCategoriesLoading(true);
        const response = await api.get(`/v1/blog-category`);

        setCategories(response.data.data);
        setCategoryId(response.data.data[0].id);
        setCategoryName(response.data.data[0].name);
        setActiveTab(response.data.data[0].id);
        setCategoriesLoading(false);
      } catch (error) {
        setCategoriesLoading(false);
      }
    };

    fetchCategory();
  }, []);

  useEffect(() => {
    if (categoryId) {
      const fetchTemplates = async () => {
        try {
          setBlogsLoading(true);
          const response = await api.get(`/v1/blog/category/${categoryId}`);
          setBlogs({
            data: response.data.data,
          });

          setBlogsLoading(false);
        } catch (error) {
          setBlogsLoading(false);
        }
      };

      fetchTemplates();
    }
  }, [categoryId]);

  const ref = useRef();
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container>
        {categoriesLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "30vh",
            }}
          >
            <CircularProgress
              size={20}
              sx={{
                mr: 2,
                color: "black",
              }}
            />
          </div>
        ) : (
          <>
            <Text
              style={{
                fontSize: "3.2rem",
                fontWeight: 600,
                textAlign: "center",
                margin: "7rem 0 2rem 0",
              }}
            >
              Browse by Category {categoryName}
            </Text>
            <Categories style={props} ref={ref}>
              <Box style={{ marginTop: "2rem" }}>
                {categories?.map((category) => (
                  <TabTitle
                    onClick={() => {
                      handleTabClick(category.id);
                      setCategoryId(category.id);
                      setCategoryName(category.name);
                    }}
                    active={activeTab === category.id}
                  >
                    {category.name}
                  </TabTitle>
                ))}
              </Box>
            </Categories>
          </>
        )}
        {blogsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "30vh",
            }}
          >
            <CircularProgress
              size={20}
              sx={{
                mr: 2,
                color: "black",
              }}
            />
          </div>
        ) : (
          <Card data={blogs?.data} />
        )}
      </Container>
    </div>
  );
};

export default Content;

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0 5rem;
  }
`;

const Box = styled(animated.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 990px) {
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  word-wrap: break-word;
  &:hover {
    opacity: ${(props) => (props.hover ? "0.7" : "1")};
    cursor: ${(props) => (props.hover ? "pointer" : "default")};
  }
`;

const Categories = styled(animated.div)`
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const TabTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--color-primary);
  text-decoration: none;
  margin: 0 1rem;
  border: 1px solid var(--color-primary);
  padding: 0.5rem 1.3rem;
  border-radius: 20px;
  transition: all 0.3s ease;
  &:hover {
    background-color: var(--color-primary);
    color: white;
    cursor: pointer;
    border: 1px solid var(--color-primary);
  }

  ${(props) =>
    props.active &&
    `
    background-color: var(--color-primary);
    color: white;
    cursor: pointer;
    border: 1px solid var(--color-primary);
  `}
`;
