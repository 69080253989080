import React from "react";
import Title from "./components/Title";

const MultiChoice = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.text}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      {block.settings.answerType === "RADIO"
        ? block.settings.options.map((item, index) => (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <input name="choice" type="radio" value={item.label} />
              <label style={{ fontSize: "14px" }} for={item.label}>
                {item.label}
              </label>
            </div>
          ))
        : block.settings.options.map((item, index) => (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <input name={item.label} type="checkbox" value={item.label} />
              <label style={{ fontSize: "14px" }} for={item.label}>
                {item.label}
              </label>
            </div>
          ))}
    </div>
  );
};

export default MultiChoice;
