import React from "react";
import LogInHeader from "../common/LogInHeader";
import Password from "./Password";
import LogInFooter from "../common/LogInFooter";

const ForgotPassword = () => {
  return (
    <div>
      <LogInHeader />
      <Password />
      <LogInFooter />
    </div>
  );
};

export default ForgotPassword;
