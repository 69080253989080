import React from "react";
import Header from "../common/header/Header";
import Tab from "./Tab";
import FooterSection from "../common/FooterSection";

const Pricing = () => {
  return (
    <>
      <Header />
      <Tab />
      <FooterSection />
    </>
  );
};

export default Pricing;
