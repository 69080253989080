import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const PrimalogikForFreeSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container>
        <Content style={props} ref={ref}>
          <TextItem>
            <Heading>Try ReviewGenie for Free</Heading>
            <Text>
              Get started right away! Sign up for your 30-day free trial.
            </Text>
          </TextItem>
          <BtnBox>
            <Btn>
              <Link to="/free-trial">
                <ReqButton variant="secondary">GET STARTED FOR FREE</ReqButton>
              </Link>
            </Btn>
          </BtnBox>
        </Content>
      </Container>
    </div>
  );
};

const Container = styled.div`
  background-color: #0261ef !important;
  padding: 54px 0;

  @media (max-width: 768px) {
    padding: 0 0 20px 0;
  }
`;
const Content = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 0;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
  @media (max-width: 980px) {
    align-items: center;
    flex-wrap: wrap;
  }
`;

const TextItem = styled.div`
  width: 64.833%;
  margin-right: 5.5%;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
  text-align: left;
  word-wrap: break-word;
  color: #fff;
  @media (max-width: 980px) {
    width: 100%;
    text-align: center;
  }
`;
const Heading = styled.h2`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1.4em;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  word-wrap: break-word;
`;

const BtnBox = styled.div`
  // width: 29.6667%;
  @media (max-width: 1100px) {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ReqButton = styled(Button)`
  font-size: 20px;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: 100;
  word-wrap: break-word;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: #0261ef;
    transition: all 0.5s ease;
  }
  @media (max-width: 1100px) {
    font-weight: 100;
  }
`;

export default PrimalogikForFreeSection;
