import React from "react";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import { APP_NAME } from "../../constants";
import { Box, IconButton } from "@mui/material";
import Button from "../../components/button";
import CloseIcon from "@mui/icons-material/Close";

const Check = ({ text }) => {
  return (
    <TextBox>
      <CheckImageBox>
        <span
          style={{
            width: "22px",
            display: "block",
            margin: "auto",
          }}
        >
          <img
            src="../image/demo-request/demo_check.svg"
            alt="check"
            style={{
              height: "auto",
              maxWidth: "100%",
            }}
          ></img>
        </span>
      </CheckImageBox>
      <Text>{text}</Text>
    </TextBox>
  );
};

const DemoRequest = () => {
  document.title = `Request a Demo - ${APP_NAME}`;
  return (
    <div>
      <BackIcon>
        <IconButton onClick={() => (window.location.href = "/")}>
          <CloseIcon sx={{ fontSize: "30px" }} />
        </IconButton>
      </BackIcon>
      <Content>
        <LeftContent>
          <LeftItem>
            <A>
              <img
                src="./Review-Genie-Logo-Light.png"
                alt="logo"
                style={{ height: "auto", width: "200px", cursor: "pointer" }}
                onClick={() => (window.location.href = "/")}
              ></img>
            </A>

            <Heading>Unlock Peak Performance</Heading>
            <Title>
              Revolutionize your employee and business development with the
              award-winning ReviewGenie!
            </Title>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Check text="Empower Personalized Growth." />
              <Check text="Build a Culture of Appreciation." />
              <Check text="Foster Continuous Improvement." />
              <Check text="Gather Valuable Insights." />
            </Box>
          </LeftItem>
        </LeftContent>
        <RightContent>
          <LeftItem>
            <Slider>
              <Carousel data-bs-theme="dark">
                <Carousel.Item>
                  <div style={{ padding: "0 6%" }}>
                    <ProfileBox>
                      <Item>
                        <Star>
                          {[1, 2, 3, 4, 5].map((index) => (
                            <StarImage
                              key={index}
                              src="./image/home/PrimalogikSection/Icons_star-filled-color.svg"
                              alt={`Star ${index}`}
                            />
                          ))}
                        </Star>
                        <CardText>
                          Our website stayed up. The form stayed up. And
                          everything worked. It was absolutely dreamy.
                        </CardText>
                        <TeamMemberName>Diane Dickinson</TeamMemberName>
                      </Item>
                    </ProfileBox>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{ padding: "0 6%" }}>
                    <ProfileBox>
                      <Item>
                        <Star>
                          {[1, 2, 3, 4, 5].map((index) => (
                            <StarImage
                              key={index}
                              src="./image/home/PrimalogikSection/Icons_star-filled-color.svg"
                              alt={`Star ${index}`}
                            />
                          ))}
                        </Star>
                        <CardText>
                          I don’t know of a company like this that is willing to
                          spend over 30 minutes making a client happy!
                        </CardText>
                        <TeamMemberName>Ava Chan</TeamMemberName>
                      </Item>
                    </ProfileBox>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Slider>
            <HeadingBookDemo>Get a demo: fill out the form.</HeadingBookDemo>
            <InputBoxes>
              <form>
                <p style={{ paddingRight: "8px", paddingBottom: "1em" }}>
                  <div>
                    <label style={{ fontSize: "16px", color: "#46586a" }}>
                      Name
                    </label>
                  </div>
                  <Input type="text"></Input>
                </p>
                <p style={{ paddingRight: "8px", paddingBottom: "1em" }}>
                  <div>
                    <label style={{ fontSize: "16px", color: "#46586a" }}>
                      Work email
                    </label>
                  </div>
                  <Input type="text"></Input>
                </p>
                <p style={{ paddingRight: "8px", paddingBottom: "1em" }}>
                  <div>
                    <label style={{ fontSize: "16px", color: "#46586a" }}>
                      Company name
                    </label>
                  </div>
                  <Input type="text"></Input>
                </p>
                <Btn>
                  <ReqButton variant="tertiary">BOOK A FREE DEMO</ReqButton>
                </Btn>
              </form>
            </InputBoxes>
          </LeftItem>
        </RightContent>
      </Content>
    </div>
  );
};

export default DemoRequest;

const BackIcon = styled.div`
  position: absolute;
  right: 2%;
  top: 3.5%;
`;

const Content = styled.div`
  display: flex;
  @media (max-width: 910px) {
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  width: 50%;
  min-height: 100vh;
  background-color: var(--color-primary);
  @media (max-width: 910px) {
    width: 100%;
    min-height: 100%;
  }
`;

const LeftItem = styled.div`
  max-width: 80%;
  min-height: 90%;
  margin: 4rem auto 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 910px) {
    margin: 4rem auto 4rem auto;
  }
`;

const A = styled.div`
  @media (max-width: 910px) {
    text-align: center;
  }
`;

const RightContent = styled.div`
  width: 50%;
  @media (max-width: 910px) {
    width: 100%;
  }
`;

const Heading = styled.h1`
  margin-top: 2rem;
  color: white;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  font-size: 44px;
  line-height: 1.3em;
  @media (max-width: 1515px) {
    font-size: 35px;
  }
  @media (max-width: 1200px) {
    font-size: 30px;
  }
`;

const Title = styled.h4`
  margin-bottom: 2rem;
  color: white;
  font-weight: 400;
  font-size: 20px;
  @media (max-width: 1515px) {
    font-size: 18px;
  }
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CheckImageBox = styled.div`
  width: 32px;
  max-width: 100%;
`;
const Text = styled.h4`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  margin: 0;
  font-weight: 300;
  font-size: 17px;
  color: #fff;
`;
const Slider = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileBox = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const Item = styled.div`
  padding: 0 30px 50px 30px;
  margin: auto;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;
const Star = styled.p`
  padding-bottom: 0;
  font-weight: 400;
  margin: 0;
`;
const StarImage = styled.img`
  height: 2rem;
  width: 2rem;
  @media (max-width: 980px) {
    height: 3rem;
    width: 3rem;
  }
`;

const CardText = styled.p`
  font-family: Poppins, sans-serif;
  color: var(--color-subtext);
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.35px;
  font-weight: 300;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 16px;
    width: 82%;
    text-align: center;
    margin: 0 auto;
  }
`;

const TeamMemberName = styled.h3`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  padding-top: 1.5rem;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  @media (max-width: 980px) {
    padding-top: 2.5rem;
  }
`;

const HeadingBookDemo = styled.h2`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 28px;
  color: #000000 !important;
  text-align: center;
  font-weight: 600;
  @media (max-width: 750px) {
    font-size: 28px;
  }
  @media (max-width: 452px) {
    font-size: 22px;
  }
`;
const InputBoxes = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 14px 0 22px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const Input = styled.input`
  background-color: #fff;
  border: 1px solid #bbb;
  padding: 2px;
  color: #4e4e4e;
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border-color: #d6d6d6;
  padding: 12px;
  font-size: 14px;
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  text-align: center;
  width: 100%;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ReqButton = styled(Button)`
  font-size: 1.5rem;
  padding: 0.8rem;
  font-weight: 100;
  width: 100%;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.2rem 1rem;
  }
`;
