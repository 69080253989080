import React from "react";
import { APP_NAME } from "../../constants";
import Contact from "../../view/contact";

const ContactPage = () => {
  document.title = `Contact Us - ${APP_NAME}`;
  return <Contact />;
};

export default ContactPage;
