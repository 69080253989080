/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Intuitive = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Heading>
          <Text style={{ fontSize: "30px", fontWeight: 900 }}>
            Intuitive and effective solution
          </Text>
        </Heading>
        <Card>
          <Box>
            <LeftBox>
              <Text
                style={{ color: "#6d6d6d", fontWeight: 300, fontSize: "16px" }}
              >
                <img
                  src="./image/customer-stories/8.png"
                  width="63px"
                  style={{ verticalAlign: "baseline", marginRight: "1rem" }}
                />
                Once we got onto ReviewGenie, the time required for each review
                went from 4 – 8 hours to 15 minutes. Everyone was more
                enthusiastic about doing the evaluations and putting forth good
                information.”
              </Text>
              <Text
                style={{
                  color: "#6d6d6d",
                  fontWeight: 900,
                  fontSize: "16px",
                  marginTop: "1rem",
                }}
              >
                Alex Chan – Sensiba San Filippo LLC
              </Text>
              <Text
                style={{
                  color: "var(--color-primary)",
                  fontWeight: 300,
                  fontSize: "16px",
                  marginTop: "1rem",
                }}
              >
                Read review on Capterra
              </Text>
            </LeftBox>
            <RightBox>
              <Img src="./image/customer-stories/13.webp"></Img>
            </RightBox>
          </Box>
        </Card>
      </Container>
    </div>
  );
};

export default Intuitive;

const Container = styled(animated.div)`
  max-width: 1080px;
  margin: 5rem auto 0 auto;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  font-weight: 300;
`;

const Card = styled.div`
  margin-top: 2rem;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 3rem 0;
  @media (max-width: 980px) {
    margin: 4rem;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5rem;
  @media (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const LeftBox = styled.div`
  width: 45%;
  @media (max-width: 980px) {
    width: 80%;
    text-align: center;
  }
`;
const RightBox = styled.div`
  width: 45%;
  @media (max-width: 980px) {
    width: 80%;
    margin-top: 2rem;
  }
`;

const Img = styled.img`
  margin-top: 2rem;
  padding-top: 2rem;
  width: 80%;
  text-align: right;
`;
