import React from "react";
import styled from "styled-components";

const data = [
  {
    logo: "./logo/logo-template.webp",
    title: "Customizable survey experiences",
    text: "Tailor your surveys to align perfectly with your brand identity using our extensive customization options. Choose from out-of-the-box themes, accessible color palettes, custom fonts, and branding elements to create a unique and engaging survey experience.",
    button: "see features",
  },
  {
    logo: "./logo/check.jpg",
    title: "Many ways to send your surveys",
    text: "Maximize your survey reach by utilizing our diverse distribution channels. Collect responses through multiple platforms to ensure you capture a wide range of feedback.",
    button: "Explore surveys",
  },
  {
    logo: "./logo/line-chart.png",
    title: "Survey analysis, no training required",
    text: "Simplify data interpretation with our intuitive analysis tools. Our platform provides automatic summaries, filters, and custom dashboards, making it easy for anyone to analyze survey results without prior training.",
    button: "Learn more",
  },
];
const Features = () => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontWeight: "600", fontSize: "30px" }}>
          Discover the Key Features of Our Survey Template
        </h1>
        <Text>
          Launching surveys has never been easier! Our feature-rich survey
          template offers a range of powerful tools to help you get started
          quickly. Here are the most popular features that new users love:
        </Text>
        <Box>
          {data.map((item, index) => (
            <Item key={index}>
              <Logo src={item.logo}></Logo>
              <h1 style={{ textAlign: "left" }}>{item.title}</h1>
              <Text style={{ textAlign: "left" }}>{item.text}</Text>
              <Button>{item.button}</Button>
            </Item>
          ))}
        </Box>
      </div>
    </Container>
  );
};

export default Features;

const Container = styled.div`
  width: 90%;
  max-width: 1100px;
  text-align: center;
  margin: 10rem auto;
`;

const Text = styled.p`
  font-size: 16px;
  padding: 1rem 0 5rem 0;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
  min-height: 300px;
  @media (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 790px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  box-shadow: 1px 1px 3px 0 rgba(70, 88, 106, 0.16),
    0 20px 80px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border: 0 solid #333;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.03);
    transition: transform 0.5s ease;
  }
`;

const Logo = styled.img`
  width: 100px;
`;

const Button = styled.button`
  padding: 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  font-size: 18px;
  color: var(--color-primary);
  width: 70%;
  &:hover {
    background-color: white;
  }
`;
