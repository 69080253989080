import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Welcome = ({ back, discretion, title, category }) => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <Div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Box>
          <LeftBox>
            <Text
              style={{ fontSize: "50px", fontWeight: 500, textAlign: "center" }}
            >
              Welcome to the ReviewGenie Blog
            </Text>
            <Text
              style={{
                marginTop: "2rem",
                fontSize: "18px",
                opacity: 0.8,
                fontWeight: 300,
                textAlign: "center",
              }}
            >
              Stay up to date on current ReviewGenie and workplace trends by
              signing up for our newsletter.
            </Text>
          </LeftBox>
        </Box>
      </Container>
    </Div>
  );
};

export default Welcome;

const Div = styled.div`
  padding: 13rem 0;
  background-image: url("/image/product/Product_Bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.2);
`;

const Container = styled(animated.div)`
  margin: 0 auto;
  padding-top: 5rem;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftBox = styled.div`
  width: 60%;
  @media (max-width: 990px) {
    width: 80%;
  }
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  word-wrap: break-word;
`;
