/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const EmployeeRelationshipSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container>
        <Box>
          <LeftItem>
            <Img src="./image/product/Opinion.jpg"></Img>
          </LeftItem>
          <RightItem style={props} ref={ref}>
            <Heading>
              Build a Stronger Manager-Employee Relationship with ReviewGenie
            </Heading>
            <Text>
              Enhance your workplace dynamics with effective one-on-one meetings
              between managers and employees. These essential interactions
              provide the perfect opportunity to discuss ongoing projects, offer
              guidance on career goals.
            </Text>
            <Text>Why Choose ReviewGenie?</Text>
            <ul>
              <Text>
                <li>
                  Performance Tracking: Monitor and track employee performance
                  over time with ease.
                </li>
              </Text>
              <Text>
                <li>
                  Comprehensive Features: From light check-ins to detailed
                  performance appraisals, ReviewGenie equips managers with the
                  right tools.
                </li>
              </Text>
              <Text>
                <li>
                  Career Development: Provide actionable feedback and career
                  guidance to foster employee growth and satisfaction.
                </li>
              </Text>
            </ul>
            <MoreButton>
              <Linked to="/opinion-surveys">LEARN MORE</Linked>
            </MoreButton>
          </RightItem>
        </Box>
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin-top: 10rem;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, #f6fcff 0%, #ffffff 10%) !important;
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    padding-top: 10rem;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const LeftItem = styled.div`
  width: 48.5%;
  margin-right: 32px;
  background-color: #ffffff;
  z-index: 1;

  @media (max-width: 980px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
const RightItem = styled(animated.div)`
  width: 50%;
  padding: 0 0 0 10rem;
  @media (max-width: 980px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Heading = styled.h2`
  padding: 2rem 0;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
  word-wrap: break-word;
`;

const MoreButton = styled.div`
  width: 38%;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1130px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
    margin: 15px 0 25px 0;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export default EmployeeRelationshipSection;
