/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Award = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container>
        <Heading style={props} ref={ref}>
          <Title style={{ fontSize: "30px", fontWeight: 900 }}>
            ReviewGenie Tech Award Winner 2021
          </Title>
          <TitleText
            style={{ fontSize: "20px", fontWeight: 300, color: "#4a4a4a" }}
          >
            ReviewGenie has been recognized as an Our technology leader by
            TalentCulture.
          </TitleText>
        </Heading>
        <Box style={props} ref={ref}>
          <LeftBox>
            <Img src="./image/customer-stories/7.webp"></Img>
          </LeftBox>
          <RightBox>
            <Text
              style={{ color: "#6d6d6d", fontWeight: 300, fontSize: "16px" }}
            >
              <img
                src="./image/customer-stories/8.png"
                width="63px"
                style={{ verticalAlign: "baseline", marginRight: "1rem" }}
              />
              We are thrilled to showcase ReviewGenie as one of the best
              ReviewGenie technologies available to a buying audience. has a
              solid performance management system that provides the end-user
              with a great experience, and all at an affordable price.”
            </Text>
            <Text
              style={{
                color: "#6d6d6d",
                fontWeight: 900,
                fontSize: "16px",
                fontStyle: "italic",
                marginTop: "1rem",
              }}
            >
              Cyndy Trivella, Managing Partner, TalentCulture
            </Text>
          </RightBox>
        </Box>
        <Heading style={{ marginTop: "8rem" }}>
          <TitleText style={{ fontSize: "20px", fontWeight: 900 }}>
            Find out what people are saying on these trusted software review
            sites
          </TitleText>
        </Heading>
        <Box style={props} ref={ref}>
          <SitesImg
            style={{ width: "100px", height: "auto" }}
            src="./image/customer-stories/9.svg"
          />
          <SitesImg
            style={{ width: "15%", height: "100%" }}
            src="./image/customer-stories/10.png"
          />
          <SitesImg
            style={{ width: "100px", height: "auto" }}
            src="./image/customer-stories/11.webp"
          />
          <SitesImg
            style={{ width: "auto", height: "100px" }}
            src="./image/customer-stories/12.webp"
          />
        </Box>
      </Container>
    </div>
  );
};

export default Award;

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 5rem;
`;

const Heading = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  font-weight: 300;
  @media (max-width: 990px) {
    margin: 0 1rem;
  }
`;

const TitleText = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  font-weight: 300;
  @media (max-width: 990px) {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
`;

const Title = styled.h5`
  margin: 0;
`;

const Box = styled(animated.div)`
  display: flex;
  margin-top: 8rem;
  text-align: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const LeftBox = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 80%;
    text-align: center;
  }
`;

const RightBox = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 80%;
    margin-top: 2rem;
  }
`;

const Img = styled.img`
  width: 50%;
`;

const SitesImg = styled.img`
  width: 100px;
  height: auto;
`;
