/* eslint-disable jsx-a11y/alt-text */
import AccountCircle from "@mui/icons-material/AccountCircle";
import AdsClickSharpIcon from "@mui/icons-material/AdsClickSharp";
import AssignmentTurnedInSharpIcon from "@mui/icons-material/AssignmentTurnedInSharp";
import AutorenewSharpIcon from "@mui/icons-material/AutorenewSharp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContactsIcon from "@mui/icons-material/Contacts";
import EventAvailableSharpIcon from "@mui/icons-material/EventAvailableSharp";
import FilterListIcon from "@mui/icons-material/FilterList";
import ForumSharpIcon from "@mui/icons-material/ForumSharp";
import HomeIcon from "@mui/icons-material/Home";
import ListSharpIcon from "@mui/icons-material/ListSharp";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RateReviewSharpIcon from "@mui/icons-material/RateReviewSharp";
import RecordVoiceOverSharpIcon from "@mui/icons-material/RecordVoiceOverSharp";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import TimelineIcon from "@mui/icons-material/Timeline";

import {
  Badge,
  Button,
  Card,
  Collapse,
  FormControl,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const menuItems = [
  { title: "Dashboard", icon: HomeIcon },
  { title: "Tasks", icon: ListSharpIcon },
  { title: "Goals", icon: AdsClickSharpIcon },
  { title: "Journal", icon: RateReviewSharpIcon },
  {
    title: "Instant Feedback",
    icon: ForumSharpIcon,
  },
  { title: "Appraisals", icon: AssignmentTurnedInSharpIcon },
  { title: "360° Surveys", icon: AutorenewSharpIcon },
  { title: "Check-Ins", icon: EventAvailableSharpIcon },
  { title: "Opinion Surveys", icon: RecordVoiceOverSharpIcon },
  { title: "Directory", icon: ContactsIcon },
  { title: "Reports", icon: TimelineIcon },
  {
    title: "Settings",
    icon: SettingsIcon,
    subMenu: [
      {
        title: "Users",
      },
      {
        title: "Teams",
      },
      {
        title: "Questionnaires",
      },
      {
        title: "Rating Scales",
      },
      {
        title: "Manage Account",
      },
    ],
  },
];

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  document.title = "Admin - ReviewGenie";

  const isMaxUserLimitReached = true;

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [openDrop, setDropOpen] = React.useState(true);

  const handleClick = () => {
    setDropOpen(!openDrop);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{ mt: "50px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography
        onClick={handleMenuClose}
        sx={{ fontSize: "1.5rem", margin: "0.6rem 1.6rem" }}
      >
        Vrushik Kardni
      </Typography>
      <MenuItem onClick={handleMenuClose} sx={{ fontSize: "1.5rem" }}>
        Profile
      </MenuItem>
      <MenuItem onClick={handleMenuClose} sx={{ fontSize: "1.5rem" }}>
        My account
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = () => (
    <Menu
      sx={{ mt: "50px" }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={1} color="error">
            <NotificationsIcon sx={{ fontSize: "25px" }} />
          </Badge>
        </IconButton>
        <p style={{ fontSize: "15px" }}>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle sx={{ fontSize: "25px" }} />
        </IconButton>
        <p style={{ fontSize: "15px" }}>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ fontSize: "25px" }} />
          </IconButton>
          <Typography variant="h3" noWrap component="div">
            <img src="./download.png" />
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon sx={{ fontSize: "30px" }} />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle sx={{ fontSize: "30px" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon sx={{ fontSize: "25px" }} />
            </IconButton>
          </Box>
        </Toolbar>
        {renderMobileMenu()}
        {renderMenu}
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ fontSize: "25px" }} />
            ) : (
              <ChevronLeftIcon sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.slice(0, 5).map((item) => (
            <SidebarMenuItem item={item} key={item.text} open={open} />
          ))}
        </List>
        <Divider />
        <List>
          {menuItems.slice(5, 9).map((item) => (
            <SidebarMenuItem item={item} key={item.text} open={open} />
          ))}
        </List>
        <Divider />
        <List>
          {menuItems.slice(9, 11).map((item) => (
            <SidebarMenuItem item={item} key={item.text} open={open} />
          ))}
        </List>
        <Divider />
        <List>
          <SidebarMenuItem
            item={{
              ...menuItems[11],
              subMenuOpen: openDrop,
              handleClick: handleClick,
            }}
            open={open}
          />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, backgroundColor: "#f0f3f6" }}
      >
        <DrawerHeader />
        <Typography variant="h3" gutterBottom>
          Manage Users
        </Typography>
        <Card sx={{ backgroundColor: "#fff" }}>
          <FormControl
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <OutlinedInput
                placeholder="Enter name or email address..."
                size="small"
                sx={{
                  width: 200,
                  fontSize: "1.3rem",
                  padding: 0,
                  borderColor: "black",
                }}
              />
              <Button
                variant="outlined"
                sx={{
                  fontSize: "24px",
                  mr: 1,
                  "&&.MuiButton-root": {
                    backgroundColor: "gray",
                    color: "white",
                  },
                }}
              >
                <SearchIcon sx={{ fontSize: "23px" }} />
              </Button>
              <Button
                variant="outlined"
                startIcon={<FilterListIcon />}
                sx={{ fontSize: "12px" }}
              >
                Filters
              </Button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {isMaxUserLimitReached ? (
                <Typography
                  variant="h5"
                  sx={{ color: "red", mr: 1, textAlign: "center", mt: "10px" }}
                >
                  Max user limit reached
                </Typography>
              ) : null}
              <Button
                variant="contained"
                sx={{
                  "&.Mui-disabled": {
                    background: "#eaeaea",
                    color: "#c0c0c0",
                  },
                }}
                disabled={isMaxUserLimitReached}
              >
                Disabled
              </Button>
            </Box>
          </FormControl>
        </Card>
      </Box>
    </Box>
  );
}

const SidebarMenuItem = ({
  open,
  item: { title, icon: Icon, subMenu, subMenuOpen, handleClick },
}) => {
  return (
    <ListItem key={title} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            color: "rgba(100,100,100,1)",
          }}
        >
          <Icon sx={{ fontSize: "25px" }} />
        </ListItemIcon>
        <ListItemText
          primary={title}
          sx={{
            opacity: open ? 1 : 0,
            "&& .MuiListItemText-primary": {
              fontSize: "1.5rem",
            },
          }}
        />
      </ListItemButton>
      {subMenu ? (
        <Collapse in={subMenuOpen} timeout="auto">
          <List component="div" disablePadding>
            {subMenu.map(({ title }) => (
              <ListItem key={title} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 10,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemText
                    primary={title}
                    sx={{
                      opacity: open ? 1 : 0,
                      marginLeft: "52px",
                      "&& .MuiListItemText-primary": {
                        fontSize: "1.5rem",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : null}
    </ListItem>
  );
};
