import React from "react";
import styled from "styled-components";

const Button = (props) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};

const StyledButton = styled.button((props) => ({
  fontFamily: "Poppins, Helvetica, Arial, Lucida, sans-serif",
  borderRadius: "5px",
  fontWeight: "normal",
  // textTransform: "uppercase",
  textDecoration: "none",
  padding: "0.7rem 1.5rem",
  color: "white",
  border: "none",
  wordBreak: "break-word",

  ...(props.variant === "secondary" && {
    color: "#222222",
    backgroundColor: "white",
    border: "1px solid #aaa",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "whitesmoke",
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    },
  }),

  ...(props.variant === "primary" && {
    color: "white",
    background: "var(--color-primary)",
    transition: "all 0.3s ease",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "var(--color-primary-dark)",
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    },
  }),

  ...(props.variant === "tertiary" && {
    color: "white",
    background: "var(--color-primary)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "var(--color-primary-dark)",
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    },
  }),

  ...(props.variant === "main" && {
    color: "white",
    backgroundColor: "transparent",
    border: "3px solid white",
    transition: "all 0.3s ease",

    "&:hover": {
      backgroundColor: "whitesmoke",
      color: "var(--color-primary)",
    },
  }),
}));

export default Button;
