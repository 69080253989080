import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/button";
import api from "../../../utils/api";
import NavbarItem from "./NavbarItem";
import MobileManuBar from "./MobileManuBar";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [templates, setTemplates] = useState({
    count: 0,
    data: [],
  });
  const [templatesLoading, setTemplatesLoading] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setTemplatesLoading(true);
        const response = await api.get("/v1/templates");

        setTemplates({
          count: response.data.pagination.count,
          data: response.data.data,
        });
        setTemplatesLoading(false);
      } catch (error) {
        setTemplatesLoading(false);
      }
    };
    fetchTemplates();
  }, []);
  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        height: "100%",
        position: "relative",
      }}
    >
      <Navbar>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/">
            <Logo src="/Review-Genie-Logo-Two.png" alt="logo" />
          </Link>
          <NavbarMenu>
            <Menu>
              <NavbarItem
                title="Product"
                left="-40%"
                children={[
                  {
                    title: "Overview",
                    link: "/product",
                  },
                  {
                    title: "360 Degree Feedback",
                    link: "/360-degree-feedback",
                  },
                  {
                    title: "Performance Reviews",
                    link: "/performance-reviews",
                  },
                  {
                    title: "Analytics & Reporting",
                    link: "/analytics-reporting-performance-management-software-review-genie",
                  },
                  // {
                  //   title: "Goals and OKRs",
                  //   link: "/goals",
                  // },
                  {
                    title: "Instant Feedback",
                    link: "/instant-feedback",
                  },
                  {
                    title: "Opinion Surveys",
                    link: "/opinion-surveys",
                  },
                ]}
              />
              <NavbarItem title="Pricing" link="/pricing" />
              <NavbarItem
                title="Template"
                left="-46%"
                menuPosition="center"
                loading={templatesLoading}
                data={templates.data.map((template) => ({
                  title: template.name,
                  link: `/template#${template.slug}`,
                  des: template.description,
                  slug: template.slug,
                }))}
              />
              <NavbarItem
                title="Resources"
                left="-35%"
                children={[
                  {
                    title: "Help Center",
                    link: "/help-center",
                  },
                  {
                    title: "Blog",
                    link: "/blog",
                  },
                  // {
                  //   title: "Customer Stories",
                  //   link: "/customers",
                  // },
                ]}
              />
              <NavbarItem
                title="About"
                left="-40%"
                children={[
                  {
                    title: "About Us",
                    link: "/about",
                  },
                  {
                    title: "Contact Us",
                    link: "/contact-us",
                  },
                ]}
              />
            </Menu>
          </NavbarMenu>
        </div>
        <NavbarMenu>
          <Menu>
            <Link
              to={`${process.env.REACT_APP_ADMIN_URL}/login`}
              style={{
                textDecoration: "none",
                color: "#222222",
              }}
            >
              <Button
                variant="secondary"
                style={{ marginRight: "1.5rem", borderRadius: "20px" }}
              >
                <AccountCircleIcon
                  sx={{ marginRight: "0.5rem", fontSize: 16 }}
                />
                LOGIN
              </Button>
            </Link>
            <Link
              to="/demo-request"
              style={{ textDecoration: "none", color: "#222222" }}
            >
              <Button
                variant="secondary"
                style={{ marginRight: "1.5rem", borderRadius: "20px" }}
              >
                REQUEST DEMO
              </Button>
            </Link>
            <Link
              to={`${process.env.REACT_APP_ADMIN_URL}/register`}
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button
                variant="primary"
                style={{
                  marginRight: "2rem",
                  borderRadius: "20px",
                  border: "1px solid var(--color-primary)",
                }}
              >
                FREE TRIAL
              </Button>
            </Link>
          </Menu>
        </NavbarMenu>
        <HamburgerMenuWrapper>
          <IconButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            size="large"
          >
            <MenuIcon
              sx={{ fontSize: "4rem", color: "var(--color-primary)" }}
              fontSize="large"
            />
          </IconButton>
        </HamburgerMenuWrapper>
      </Navbar>
      <div
        style={{
          position: "relative",
        }}
      >
        <MobileManuBar
          items={[
            {
              title: "Product",
              children: [
                {
                  title: "Overview",
                  link: "/product",
                },
                {
                  title: "360 Degree Feedback",
                  link: "/360-degree-feedback",
                },
                {
                  title: "Performance Reviews",
                  link: "/performance-reviews",
                },
                {
                  title: "Analytics & Reporting",
                  link: "/analytics-reporting-performance-management-software-review-genie",
                },
                // {
                //   title: "Goals and OKRs",
                //   link: "/goals",
                // },
                {
                  title: "Instant Feedback",
                  link: "/instant-feedback",
                },
                {
                  title: "Opinion Surveys",
                  link: "/opinion-surveys",
                },
              ],
            },
            {
              title: "Template",
              children: [
                ...templates.data.map((template) => ({
                  title: template.name,
                  link: `/templates/${template.slug}`,
                })),
                {
                  title: "See all Template",
                  link: "/templates",
                },
              ],
            },
            {
              title: "Pricing",
              link: "/pricing",
            },
            {
              title: "Resources",
              children: [
                {
                  title: "Help Center",
                  link: "/help-center",
                },
                {
                  title: "Blog",
                  link: "/blog",
                },
                {
                  title: "Customer Stories",
                  link: "/customers",
                },
              ],
            },
            {
              title: "About",
              children: [
                {
                  title: "About Us",
                  link: "/about",
                },
                // {
                //   title: "Careers",
                //   link: "/careers",
                // },
                {
                  title: "Contact Us",
                  link: "/contact-us",
                },
              ],
            },
            {
              title: "login",
              component: (
                <Link
                  to={`${process.env.REACT_APP_ADMIN_URL}/login`}
                  style={{ textDecoration: "none", color: "#222222" }}
                >
                  <Button
                    variant="secondary"
                    style={{
                      marginRight: "2rem",
                      padding: "0.8rem 1.8rem",
                      borderRadius: "20px",
                    }}
                  >
                    <AccountCircleIcon
                      sx={{ marginRight: "0.5rem", fontSize: 18 }}
                    />
                    Login
                  </Button>
                </Link>
              ),
            },
            {
              title: "request demo",
              component: (
                <Link
                  to="/demo-request"
                  style={{ textDecoration: "none", color: "#222222" }}
                >
                  <Button
                    variant="secondary"
                    style={{
                      marginRight: "2rem",
                      padding: "0.8rem 2rem",
                      borderRadius: "20px",
                    }}
                  >
                    REQUEST DEMO
                  </Button>
                </Link>
              ),
            },
            {
              title: "free trial",
              component: (
                <Link
                  to={`${process.env.REACT_APP_ADMIN_URL}/register`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button
                    variant="primary"
                    style={{
                      marginRight: "2rem",
                      padding: "1.1rem 2.7rem",
                      borderRadius: "20px",
                    }}
                  >
                    Free Trial
                  </Button>
                </Link>
              ),
            },
          ]}
          hidden={!isOpen}
        />
      </div>
    </div>
  );
};

const Navbar = styled.nav`
  color: black;
  width: 100%;
  position: fixed;
  padding: 1.5rem 3rem 1.5rem 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  @media (max-width: 1020px) {
    justify-content: space-between;
    padding: 1rem 3.5rem 1rem 3.5rem;
    position: static;
  }
`;

const Logo = styled.img`
  width: 250px;
  @media (max-width: 1020px) {
    height: 6.7rem;
  }
  @media (max-width: 400px) {
    height: 4.5rem;
    width: 200px;
  }
  @media (max-width: 320px) {
    height: 4rem;
    width: 150px;
  }
`;

const NavbarMenu = styled.div`
  display: flex;
  @media (max-width: 1020px) {
    display: none;
  }
`;

const HamburgerMenuWrapper = styled.div`
  display: none;
  @media (max-width: 1020px) {
    display: block;
    cursor: pointer;
  }
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0px;
  font-size: 1.5rem;
  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }
  @media (max-width: 1085px) {
    font-size: 1rem;
  }
`;

export default Header;
