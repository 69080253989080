import React from "react";
import Header from "../common/header/Header";
import Heading from "./Heading";
import Address from "./Address";
import FooterSection from "../common/FooterSection";

const Contact = () => {
  return (
    <>
      <Header />
      <Heading />
      <Address />
      <FooterSection />
    </>
  );
};

export default Contact;
