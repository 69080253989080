import React from "react";
import Title from "./components/Title";

const BasicAndPersonal = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        description={block.settings?.description}
        required={block.settings.required}
        blockIndex={blockIndex}
      />
      <input
        style={{
          height: " 30px",
          fontSize: "15px",
          width: "100%",
        }}
        type="text"
        placeholder={block.settings.placeholders}
      ></input>
    </div>
  );
};

export default BasicAndPersonal;
