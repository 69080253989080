import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Item = ({ heading, text, imageUrl, link }) => {
  return (
    <ItemBox>
      <Link to={link} style={{ textDecoration: "none", color: "#161a24" }}>
        <TextBox>
          <Heading>{heading}</Heading>
          <Text>{text}</Text>
        </TextBox>
        <ImgBox>
          <Img src={imageUrl} />
        </ImgBox>
      </Link>
    </ItemBox>
  );
};

const DiscoverMoreSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <TitleBox>
          <Title>Discover more</Title>
        </TitleBox>
        <Box>
          <Item
            heading="Performance Appraisals"
            text="Discuss employees’ ongoing performance, achievements and growth."
            imageUrl="./image/goalsandOKRs/9.webp"
            link="/performance-reviews"
          />
          <Item
            heading="360 Degree Feedback"
            text="Manage your entire 360° feedback process efficiently."
            imageUrl="./image/goalsandOKRs/10.png"
            link="/360-degree-feedback"
          />
          <Item
            heading="Instant Feedback"
            text="Empower employees to share positive and constructive feedback."
            imageUrl="./image/goalsandOKRs/11.png"
            link="/instant-feedback"
          />
        </Box>
      </Container>
    </div>
  );
};

const Container = styled(animated.div)`
  padding: 54px 0;
`;

const TitleBox = styled.div`
  padding: 27px 0;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
`;

const Title = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  line-height: 1.4em;
  color: #161a24;
  word-wrap: break-word;
`;

const Box = styled.div`
  padding: 0 57px 22px 57px;
  display: flex;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
  @media (max-width: 980px) {
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 0;
    margin: auto;
  }
`;

const ItemBox = styled.div`
  width: 100%;
  margin-right: 3%;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  box-shadow: 1px 1px 3px 0 rgba(70, 88, 106, 0.16),
    0 20px 80px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border: 0 solid #333;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1); /* Increase the size on hover */
    transition: transform 0.5s ease;
  }

  @media (max-width: 980px) {
    margin-bottom: 30px;
    margin-right: 0;
  }
  @media (max-width: 480px) {
    padding: 50px 10px;
  }
`;

const TextBox = styled.div`
  max-width: 550px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
`;

const Heading = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  line-height: 1.4em;
  padding-bottom: 10px;
`;

const Text = styled.div`
  line-height: 1.5em;
  text-align: center;
  word-wrap: break-word;
  font-size: 14px;
  color: #46586a;
  font-weight: 300;
`;
const ImgBox = styled.div`
  margin-left: 0px !important;
  margin-right: auto !important;
  padding-top: 20px;
  width: 100%;
  text-align: center;
  max-width: 100%;
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
export default DiscoverMoreSection;
