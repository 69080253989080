/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";

const HomeSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div
      ref={inViewRef}
      style={{ boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.3)" }}
    >
      <HomeFirstBg>
        <div>
          <ItemText style={props} ref={ref}>
            <Heading>Get Valuable Insights with Surveys</Heading>
            <Text>
              Unlock actionable insights and gain fresh perspectives by
              conducting surveys. Be the innovator with brilliant ideas.
            </Text>
          </ItemText>
          <HomeBtn style={props} ref={ref}>
            <Link
              to={`${process.env.REACT_APP_ADMIN_URL}/register`}
              style={{ textDecoration: "none" }}
            >
              <HomeButton variant="main">Try ReviewGenie for Free</HomeButton>
            </Link>
          </HomeBtn>
        </div>
      </HomeFirstBg>
    </div>
  );
};

export default HomeSection;

const HomeFirstBg = styled.div`
  background-image: url("./image/home/HomeSection/Bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  min-height: 80vh;
`;
const ItemText = styled(animated.div)`
  max-width: 1100px;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
`;

const Heading = styled.h1`
  font-size: 5.3rem;
  width: 100%;
  word-wrap: break-word;
  padding-top: 5.3rem;
  @media (max-width: 980px) {
    font-size: 8.5rem;
    padding-top: 4.3rem;
  }
  @media (max-width: 720px) {
    font-size: 7.5rem;
  }
  @media (max-width: 620px) {
    font-size: 6.5rem;
  }
  @media (max-width: 400px) {
    font-size: 5.5rem;
    padding: 0 5px;
  }
`;

const Text = styled.p`
  font-size: 1.8rem;
  font-weight: 100;
  padding: 2.5rem 20rem;

  @media (max-width: 980px) {
    padding: 2.5rem 5rem;
    font-size: 2.8rem;
  }
`;
const HomeBtn = styled(animated.div)`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  padding-top: 1.65rem;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
`;
const HomeButton = styled(Button)`
  margin: 0 auto;
  font-size: 1.8rem;
  padding: 1.4rem 5.4rem;
  border-radius: 7rem;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 2.2rem 4rem;
  }
`;
