import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const AboutUs = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div
      ref={inViewRef}
      style={{
        backgroundImage: `url(/image/product/Product_Bg.jpg)`,
        boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.3)",
        backgroundSize: "cover",
        // backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container style={props} ref={ref}>
        <Box>
          <LeftBox>
            <Text
              style={{
                fontSize: "4rem",
                fontWeight: 400,
                color: "black",
              }}
            >
              ABOUT US
            </Text>
            <Text
              style={{
                marginTop: "0rem",
                fontSize: "1.8rem",
                color: "black",
                opacity: "0.8",
              }}
            >
              We are an expert offshore software development partner with over
              two decades of experience in transforming business processes
              digitally.
            </Text>
          </LeftBox>
          {/* <RightBox>
            <Img src="./image/about/1.png" />
          </RightBox> */}
        </Box>
      </Container>
    </div>
  );
};

export default AboutUs;

const Container = styled(animated.div)`
  padding-top: 7rem;
  max-width: 1080px;
  margin: 0 auto;
`;

const Box = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 80px 0;
  @media (max-width: 990px) {
    width: 80%;
  }
`;
const Text = styled.h1`
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
  line-height: 1.5;
  margin: 0;
`;

// const RightBox = styled.div`
//   text-align: center;
// `;

// const Img = styled.img`
//   max-width: min(1968px, 100%);
//   max-height: 1440px;
//   aspect-ratio: 1968 / 1440;
//   margin: 0px auto;
//   width: 100%;

//   @media (max-width: 990px) {
//     width: 50%;
//   }
//   @media (max-width: 600px) {
//     width: 80%;
//   }
// `;
