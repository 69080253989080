import React from "react";
import Header from "../common/header/Header";
import OpinionSurveysSection from "./OpinionSurveysSection";
import WorkplaceSection from "./WorkplaceSection";
import ValuableInsightsSection from "./ValuableInsightsSection";
import FeaturesSection from "./FeaturesSection";
// import PrimalogikForFreeSection from "../common/PrimalogikForFreeSection";
import DiscoverMoreSection from "../performanceReviews/DiscoverMoreSection";
import FooterSection from "../common/FooterSection";
import PrimalogikSection from "../home/Primalogiksection";
import GetStartedSection from "../product/GetStartedSection";
import OrganizationSection from "../product/OrganizationSection";
import Faqssection from "../home/Faqssection";

const OpinionSurveys = () => {
  return (
    <>
      <Header />
      <OpinionSurveysSection />
      <OrganizationSection />
      <WorkplaceSection />
      <GetStartedSection />
      <ValuableInsightsSection />
      <FeaturesSection />
      {/* <PrimalogikForFreeSection /> */}
      <DiscoverMoreSection />
      <PrimalogikSection />
      <Faqssection />
      <FooterSection />
    </>
  );
};

export default OpinionSurveys;
