/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const SupportsProfessionalSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container>
        <Box>
          <LeftItem style={props} ref={ref}>
            <Heading>360 Degree Feedback Supports Professional Growth</Heading>
            <Text>
              A comprehensive 360 degree feedback review offers a complete
              perspective on an employee’s performance and behavior in the
              workplace. It provides clear insights into which skills need
              strengthening and highlights areas where the employee is most
              valued by peers, direct reports, managers, and other colleagues.
            </Text>
            <MoreButton>
              <Linked to="/opinion-surveys">LEARN MORE</Linked>
            </MoreButton>
          </LeftItem>
          <RightItem>
            <BgImage>
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                }}
              >
                <Img src="./image/degreeFeedback/360.png"></Img>
              </span>
            </BgImage>
          </RightItem>
        </Box>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding-bottom: 8rem;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const RightItem = styled.div`
  width: 100%;
  background-color: white;
  padding-left: 10rem;
  position: relative;
  z-index: 2;
  @media (max-width: 980px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 30px;
    padding-right: 0px;
    padding-bottom: 50px;
    padding-left: 20px;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
  @media (max-width: 370px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;
const BgImage = styled.div`
  width: 100%;
  text-align: right;
  display: block;
  position: relative;
  margin-left: auto;
`;
const Img = styled.img`
  width: 100%;
  height: auto;
  padding-right: 0px;
  position: relative;
  padding-bottom: 15px;
  @media (max-width: 370px) {
    padding-left: 0;
  }
`;

const LeftItem = styled(animated.div)`
  width: 100%;
  @media (max-width: 980px) {
    padding: 7rem 15.6rem 7rem 15.6rem;
  }
  @media (max-width: 768px) {
    padding: 7rem 5.6rem 7rem 5.6rem;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Heading = styled.h2`
  padding: 2rem 0;
  font-size: 34px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
  word-wrap: break-word;
`;

const MoreButton = styled.div`
  width: 38%;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1130px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
    margin: 15px 0 25px 0;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export default SupportsProfessionalSection;
