/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const ProvideMFSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container>
        <BG></BG>
        <Box>
          <LeftItem>
            <Img src="./image/product/Feedback.jpg"></Img>
          </LeftItem>
          <RightItem style={props} ref={ref}>
            <Heading>Provide meaningful feedback</Heading>
            <Text>
              A 360 degree feedback review helps paint the most complete picture
              of an employee’s overall performance and behavior at work. It
              provides clarity on what skills they need to strengthen and what
              they are most valued for by their peers, direct reports, managers,
              and other colleagues.
            </Text>
            <MoreButton>
              <Linked to="/opinion-surveys">LEARN MORE</Linked>
            </MoreButton>
          </RightItem>
        </Box>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding-top: 2rem;
  padding-bottom: 3rem;
  background-color: #fafdff;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;
const BG = styled.div`
  // background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 100px;
  top: 0;
  height: 100px;
  z-index: 1;
  transform: scale(1, 1);
  display: block;
  background-repeat-y: no-repeat;
  height: 100%;
  position: absolute;
  pointer-events: none;
  width: 100%;
  left: 0;
  right: 0;
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  @media (max-width: 480px) {
    padding: 20px;
  }
`;
const LeftItem = styled.div`
  width: 100%;
  background-color: #fafdff;
  z-index: 1;
  @media (max-width: 980px) {
    width: 100%;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
const RightItem = styled(animated.div)`
  width: 100%;
  padding: 3.6rem 0 3.6rem 10rem;
  @media (max-width: 980px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Heading = styled.h2`
  padding: 2rem 0;
  font-size: 34px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
  word-wrap: break-word;
`;

const MoreButton = styled.div`
  width: 38%;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1130px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
    margin: 15px 0 25px 0;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export default ProvideMFSection;
