import React from "react";
import PerformanceReviews from "../../view/performanceReviews";
import { APP_NAME } from "../../constants";

const PerformanceReviewsPage = () => {
  document.title = `Performance Reviews -  ${APP_NAME}`;
  return <PerformanceReviews />;
};

export default PerformanceReviewsPage;
