import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Heading = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <Container ref={inViewRef}>
      <Box style={props} ref={ref}>
        <Title>
          <Text style={{ fontSize: "30px", fontWeight: 400 }}>
            Customer Stories
          </Text>
          <Text style={{ fontSize: "18px", fontWeight: 300 }}>
            Learn how customers are using ReviewGenie to keep their workforce
            motivated and improve organizational success.
          </Text>
        </Title>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  background-image: url("./image/customer-stories/1.jpeg");
  background-color: #148f93;
  background-blend-mode: overlay;
`;

const Box = styled(animated.div)`
  padding-top: 10rem;
  max-width: 1080px;
  margin: 0 auto;
  @media (max-width: 1090px) {
    width: 80%;
    margin: 0 auto;
    padding-top: 5rem;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  margin: 0;
  text-align: left;
  color: white;
  font-weight: 300;
`;

const Title = styled.div`
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  min-height: 30vh;
  @media (max-width: 768px) {
    padding: 60px 10px;
  }
`;

export default Heading;
