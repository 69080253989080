import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const ContentItem = ({ link, heading, text }) => {
  return (
    <div
      style={{
        position: "relative",
        marginBottom: "3rem",
      }}
    >
      <ContentImg src={link} />
      <Text>
        <ContentHeading>{heading}</ContentHeading>
        <ContentText>{text}</ContentText>
      </Text>
    </div>
  );
};
const FeaturesSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Features style={props} ref={ref}>
        <FeaturesHeading>
          <Heading>Discover our Form Maker Capabilities</Heading>
          <SubHeading>
            Increase productivity and accelerate growth by streamlining your
            external and internal processes. Get the right data in the right
            ways with the help of digital forms & surveys.
          </SubHeading>
        </FeaturesHeading>
        <Content>
          <ContentItem
            link="./image/product/17.svg"
            heading="Custom-built Questionnaires"
            text="Create an unlimited number of questionnaires to match your needs."
          />
          <ContentItem
            link="./image/product/18.svg"
            heading="Automated Reminders"
            text="Receive automatic email reminders for pending tasks."
          />
          <ContentItem
            link="./image/product/19.svg"
            heading="Dynamic Reports"
            text="See results instantly and analyze them from different viewpoints."
          />
          <ContentItem
            link="./image/product/20.svg"
            heading="Manager Journal"
            text="Keep private notes on your subordinates to track important events."
          />
          <ContentItem
            link="./image/product/21.svg"
            heading="Company Directory"
            text="Find up-to-date contact information on everyone in the organization."
          />
          <ContentItem
            link="./image/product/22.svg"
            heading="Easy Administration"
            text="View participation levels and track survey progress for the entire organization."
          />
          <ContentItem
            link="./image/product/25.svg"
            heading="Single Sign-On"
            text="Log in using a single ID and password with Microsoft and Google Apps."
          />
        </Content>
      </Features>
    </div>
  );
};

const Features = styled(animated.div)`
  background-color: #ffffff !important;
  padding: 5.4rem 0;
`;
const FeaturesHeading = styled.div`
  width: 68%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 980px) {
    width: 80%;
  }
`;
const Heading = styled.h2`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 3.4rem;
  color: #000000;
  padding: 2rem 2rem 2rem 0;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1.4em;
  margin: 0;
  @media (max-width: 980px) {
    padding: 2rem 2rem 2rem 0;
    text-align: center;
    font-size: 5.6rem;
  }
  @media (max-width: 767px) {
    padding: 2rem 2rem 2rem 0;
    text-align: center;
    font-size: 5rem;
  }
`;

const SubHeading = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #253f6f !important;
  text-align: center;
  line-height: 1.8em;
  padding-bottom: 2rem;
  margin: 0;
  @media (max-width: 980px) {
    font-size: 3.2rem;
  }
  @media (max-width: 767px) {
    font-size: 3rem;
  }
`;

const Content = styled.div`
  width: 80%;
  max-width: 1080px;
  margin: auto;
  //   position: relative;
  //   display: flex;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5.6rem 1rem;
  justify-content: space-between;
  @media (max-width: 980px) {
    width: 60%;
    display: block;
    align-items: left;
  }
  @media (max-width: 767px) {
    width: 70%;
    display: block;
    align-items: center;
    text-align: center;
  }
`;

const ContentImg = styled.img`
  width: 10.1rem;
  height: 11rem;
  padding-bottom: 3rem;
  @media (max-width: 980px) {
    width: 24%;
    height: 24%;
    padding-bottom: 6rem;
  }
  @media (max-width: 767px) {
    width: 25%;
    height: 25%;
  }
`;
const Text = styled.div`
  text-align: left;
  @media (max-width: 980px) {
    text-align: left;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
`;
const ContentHeading = styled.h3`
  margin: 0;
  font-size: 2.6rem;
  padding: 1.5rem 0;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  @media (max-width: 980px) {
    font-size: 4rem;
    line-height: 1.8em;
  }
`;

const ContentText = styled.p`
  line-height: 1.7em;
  -webkit-font-smoothing: antialiased;
  display: contents;
  color: var(--color-subtext);
  margin: 0;
  font-weight: 400;
  font-size: 1.7rem;
  @media (max-width: 980px) {
    font-size: 2.6rem;
  }
`;
export default FeaturesSection;
