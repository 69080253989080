import Address from "./block/Address";
import Audio from "./block/Audio";
import BasicAndPersonal from "./block/BasicAndPersonal";
import DropDown from "./block/DropDown";
import Embed from "./block/Embed";
import FileUpload from "./block/FileUpload";
import ImageBlock from "./block/ImageBlock";
import LongText from "./block/LongText";
import Matrix from "./block/Matrix";
import MultiChoice from "./block/MultiChoice";
import Name from "./block/Name";
import PictureChoice from "./block/PictureChoice";
import Rating from "./block/Rating";
import Signature from "./block/Signature";
import TextBlock from "./block/TextBlock";
import Video from "./block/Video";

export const renderBody = (block, blockIndex, sectionIndex) => {
  switch (block.type) {
    case "TEXT":
      return (
        <TextBlock
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "SHORT_TEXT":
    case "LOCATION":
    case "NUMBER":
    case "EMAIL":
    case "PHONE":
    case "LINK":
    case "DATE":
    case "TIME":
      return (
        <BasicAndPersonal
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "LONG_TEXT":
      return (
        <LongText
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "NAME":
      return (
        <Name
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "ADDRESS":
      return (
        <Address
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "SCALE_RATING":
      return (
        <Rating
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "MULTI_CHOICE":
      return (
        <MultiChoice
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "PICTURE_CHOICE":
      return (
        <PictureChoice
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "DROP_DOWN":
      return (
        <DropDown
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "MATRIX":
      return (
        <Matrix
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "IMAGE":
      return <ImageBlock block={block} blockIndex={blockIndex} />;
    case "VIDEO":
      return <Video block={block} blockIndex={blockIndex} />;
    case "AUDIO":
      return <Audio block={block} blockIndex={blockIndex} />;
    case "GENERIC_EMBED":
      return <Embed block={block} blockIndex={blockIndex} />;
    case "SIGNATURE":
      return (
        <Signature
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    case "FILE_UPLOAD":
      return (
        <FileUpload
          block={block}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
        />
      );
    default:
      return null;
  }
};
