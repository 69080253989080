import React from "react";
import Title from "./components/Title";

const Video = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      {block.settings.videoUrl
        .split("?")[0]
        .split("/")
        .slice(0, 3)
        .join("/") === "https://youtu.be" ? (
        <>
          <iframe
            src={`https://www.youtube.com/embed/${
              block.settings.videoUrl.split("/").pop().split("?")[0]
            }`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              height: "400px",
              width: "100%",
            }}
          ></iframe>
        </>
      ) : (
        <video autoPlay loop muted>
          <source src={block.settings.videoUrl} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default Video;
