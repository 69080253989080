import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const ContentItem = ({ link, heading, text, gutterRight }) => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(60px)"
      : "translateY(0)",
    config: { duration: 500 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div
      ref={inViewRef}
      style={{
        position: "relative",
        marginBottom: "3rem",
      }}
    >
      <ContentImg style={props} ref={ref} src={link} />
      <Text>
        <ContentHeading>{heading}</ContentHeading>
        <ContentText>{text}</ContentText>
      </Text>
      {gutterRight && <GutterRight></GutterRight>}
    </div>
  );
};
const ContentSection = () => {
  return (
    <div>
      <Container>
        <Title>Simplifying Online Surveys</Title>
      </Container>
      <Content>
        <ContentItem
          link="./image/home/ContentSection/t1.svg"
          heading="Multi-Lingual Surveys"
          text="Expand your reach effortlessly. Translate surveys with a single click to connect with global audiences."
          gutterRight
        />
        <ContentItem
          link="./image/home/ContentSection/t2.svg"
          heading="Themeable"
          text="Maintain brand consistency. Design personalized themes and enhance your survey's appearance with custom CSS."
          gutterRight
        />
        <ContentItem
          link="./image/home/ContentSection/t3.svg"
          heading="Smart Dashboards"
          text="Gain actionable insights. Utilize powerful visualizations to create a detailed picture of your survey data."
          gutterRight
        />
        <ContentItem
          link="./image/home/ContentSection/t4.svg"
          heading="Real-time analytics"
          text="ReviewGenie actively tracks participation metrics, allowing you to monitor how many surveys each individual has completed."
        />
      </Content>
    </div>
  );
};

const Container = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 2rem auto 0 auto;
  padding: 6rem 0;
  justify-content: space-between;
  @media (max-width: 980px) {
    // width: 80%;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 4rem;
  line-height: 1.4em;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  @media (max-width: 980px) {
    font-size: 4rem;
  }
`;

const Content = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 2rem auto 0 auto;
  padding: 2rem 0;
  display: flex;
  gap: 6.6rem;
  justify-content: space-between;
  @media (max-width: 980px) {
    width: 80%;
    display: block;
    align-items: left;
  }
`;
const ContentImg = styled(animated.img)`
  width: 9.1rem;
  height: 11rem;
  padding-bottom: 3rem;
  @media (max-width: 980px) {
    width: 19%;
    height: 19%;
  }
`;

const Text = styled.div`
  text-align: left;
  @media (max-width: 980px) {
    text-align: left;
  }
`;
const ContentHeading = styled.h3`
  margin: 0;
  font-size: 2.3rem;
  padding: 1.5rem 0;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  @media (max-width: 980px) {
    font-size: 4rem;
  }
`;

const ContentText = styled.p`
  line-height: 1.7em;
  -webkit-font-smoothing: antialiased;
  display: contents;
  color: var(--color-subtext);
  margin: 0;
  font-weight: 400;
  font-size: 1.5rem;
  @media (max-width: 980px) {
    font-size: 2.6rem;
  }
`;

const GutterRight = styled.div`
  position: absolute;
  top: 75%;
  left: 0;
  transform: translateY(-50%) translateX(3rem);
  height: 35%;
  width: 100%;
  border-right: 1px solid #e5e5e5;

  @media (max-width: 980px) {
    display: none;
  }
`;
export default ContentSection;
