import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import api from "../../utils/api";

const RelatedTemplates = ({ slug, categoryId }) => {
  const [templates, setTemplates] = useState({
    count: 0,
    data: [],
  });

  const [categories, setCategories] = useState(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await api.get("/v1/templates");

        setTemplates({
          count: response.data.pagination.count,
          data: response.data.data,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCategory = async () => {
      try {
        const response = await api.get(`/v1/category`);

        setCategories(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategory();
    fetchTemplates();
  }, []);

  const currentCategory = categories?.find((item) => item.id === categoryId);

  return (
    <Container>
      <h1 style={{ fontWeight: 600 }}>
        Additional resources to help you launch successful survey projects
      </h1>
      <Box>
        {templates.data.slice(0, 8).map((item, index) =>
          item.slug !== slug && item.categoryId === categoryId ? (
            <Item to={`/template#${item.slug}`} key={index}>
              <Logo src="./logo/reaction.png"></Logo>
              <h1 style={{ textAlign: "left", marginBottom: "5rem" }}>
                {item.name}
              </h1>
              <Text style={{ textAlign: "left" }}>{item.description}</Text>
            </Item>
          ) : null
        )}
        <Item to="/all-templates" style={{ justifyContent: "center" }}>
          <h1 style={{ textAlign: "left", marginBottom: "5rem" }}>
            View all {currentCategory && currentCategory.name} survey templates
          </h1>
        </Item>
      </Box>
    </Container>
  );
};

export default RelatedTemplates;

const Container = styled.div`
  max-width: 1180px;
  text-align: center;
  margin: 10rem auto 10rem auto;
  width: 90%;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  min-height: 200px;
  margin-top: 5rem;
  @media (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 790px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Text = styled.p`
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border:1px solid #edeeee;
  border-radius: 25px;
  overflow: hidden;
  text-decoration: none;
  color:black;

  padding: 20px 20px;
  
  cursor: pointer;
  
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 1px 1px 3px 0 rgba(70, 88, 106, 0.16),0 20px 80px 0 rgba(0, 0, 0, 0.05);
  }

  &:hover ${Text} {
    text-decoration: underline;
  }
  &:hover h1 {
    text-decoration: underline;
`;

const Logo = styled.img`
  width: 50px;
  margin-bottom: 20px;
`;

