import React, { useRef, useState } from "react";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import { animated, useInView, useSpring } from "react-spring";

const CustomCarousel = styled(Carousel)`
  .carousel-control-prev,
  .carousel-control-next {
    width: 20px;
  }

  @media (max-width: 990px) {
    .carousel-control-prev,
    .carousel-control-next {
      width: 50px;
    }
  }
`;

const FeaturedCaseStudies = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <Box ref={inViewRef}>
      <Title style={props} ref={ref}>
        <Text style={{ fontSize: "30px", fontWeight: 600 }}>
          Featured Case Studies
        </Text>
        <Text style={{ fontSize: "18px", fontWeight: 300, color: "#46586a" }}>
          See how others are improving their performance management strategies
          and learn how you could follow in their footsteps.
        </Text>
      </Title>

      <CustomCarousel data-bs-theme="dark">
        <Carousel.Item>
          <ItemBox>
            <LeftBox>
              <Img src="./image/customer-stories/2.jpeg" />
            </LeftBox>
            <RightBox>
              <Text style={{ fontSize: "25px", color: "black" }}>
                How Ducker Seamlessly Conducts Reviews on More Than 300 Projects
                Each Year
              </Text>
              <Text style={{ fontSize: "20px", color: "black" }}>
                The ease of launching project-based reviews has contributed to
                creating a wonderful user experience for the entire team at
                Ducker.
              </Text>
              {/* <Button style={{ width: "50%" }} backColor="var(--color-primary)">
                Read Success Story
              </Button> */}
            </RightBox>
          </ItemBox>
        </Carousel.Item>
        <Carousel.Item>
          <ItemBox>
            <LeftBox>
              <Img src="./image/customer-stories/3.jpeg" />
            </LeftBox>
            <RightBox>
              <Text style={{ fontSize: "25px", color: "black" }}>
                How ReviewGenie helped Istation conduct 100% digital reviews
              </Text>
              <Text style={{ fontSize: "20px", color: "black" }}>
                See how ReviewGenie helped Istation implement a fully automated
                process for performance reviews and build awareness amongst
                leaders with 360° feedback.
              </Text>
              {/* <Button style={{ width: "50%" }} backColor="var(--color-primary)">
                Read Success Story
              </Button> */}
            </RightBox>
          </ItemBox>
        </Carousel.Item>
      </CustomCarousel>
    </Box>
  );
};

export default FeaturedCaseStudies;

const Box = styled.div`
  padding-top: 10rem;
  max-width: 1180px;
  margin: 0 auto;
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: var(--color-primary);
  font-weight: 300;
  @media (max-width: 1180px) {
    text-align: center;
  }
`;

const Title = styled(animated.div)`
  padding: 60px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  min-height: 30vh;
  @media (max-width: 1180px) {
    width: 80%;
    margin: 0 auto;
    align-items: center;
  }
  @media (max-width: 768px) {
    padding: 60px 0;
  }
`;

const ItemBox = styled.div`
  width: 100%;
  display: flex;
  padding: 5rem 3rem;
  justify-content: space-between;
  @media (max-width: 990px) {
    justify-content: center;
  }
`;

const LeftBox = styled.div`
  width: 45%;
  @media (max-width: 990px) {
    display: none;
  }
`;
const RightBox = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 990px) {
    width: 80%;
    align-items: center;
  }
`;

const Img = styled.img`
  width: 100%;
  border-radius: 10px;
`;

// const Button = styled.button`
//   padding: 1rem 0;
//   background-color: ${(props) => props.backColor};
//   color: white;
//   border-radius: 5px;
//   line-height: 1.5;
//   font-size: 15px;
//   text-transform: uppercase;
//   border: none;
//   cursor: pointer;
//   margin: 0 0.5rem;

//   &:hover {
//     background-color: ${(props) =>
//       props.hoverColor ? props.hoverColor : "var(--color-primary-dark)"};
//   }
// `;
