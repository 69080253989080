/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components";

const Teams = () => {
  return (
    <div>
      <Container>
        <Heading>
          <h2 style={{ fontSize: "30px", fontWeight: 900 }}>
            Great teams love ReviewGenie
          </h2>
          <HeadingText
            style={{ fontSize: "20px", fontWeight: 300, color: "#4a4a4a" }}
          >
            Join these organizations in making performance management a top
            priority.
          </HeadingText>
        </Heading>
        <Box>
          <Item>
            <img src="./image/customer-stories/14.png" />
            <Text style={{ color: "#aaaaaa" }}>E-COMMERCE</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/15.png" />
            <Text style={{ color: "#aaaaaa" }}>RETAIL</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/16.png" />
            <Text style={{ color: "#aaaaaa" }}>HEALTHCARE</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/17.webp" />
            <Text style={{ color: "#aaaaaa" }}>BANKING</Text>
          </Item>
        </Box>
        <Box>
          <Item>
            <img src="./image/customer-stories/18.webp" />
            <Text style={{ color: "#aaaaaa" }}>FOOD</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/19.webp" />
            <Text style={{ color: "#aaaaaa" }}>CONSULTING</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/20.png" />
            <Text style={{ color: "#aaaaaa" }}>TECHNOLOGY</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/21.webp" />
            <Text style={{ color: "#aaaaaa" }}>EDUCATION</Text>
          </Item>
        </Box>
        <Box>
          <Item>
            <img src="./image/customer-stories/22.webp" />
            <Text style={{ color: "#aaaaaa" }}>ADVERTISING</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/23.webp" />
            <Text style={{ color: "#aaaaaa" }}>ENGINEERING</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/24.webp" />
            <Text style={{ color: "#aaaaaa" }}>NONPROFIT</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/25.webp" />
            <Text style={{ color: "#aaaaaa" }}>ARCHITECTURE</Text>
          </Item>
        </Box>
        <Box>
          <Item>
            <img src="./image/customer-stories/26.webp" />
            <Text style={{ color: "#aaaaaa" }}>FINANCE</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/27.webp" />
            <Text style={{ color: "#aaaaaa" }}>PUBLIC SAFETY</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/28.webp" />
            <Text style={{ color: "#aaaaaa" }}>AEROSPACE</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/29.webp" />
            <Text style={{ color: "#aaaaaa" }}>ENTERTAINMENT</Text>
          </Item>
        </Box>
        <Box>
          <Item>
            <img src="./image/customer-stories/30.webp" />
            <Text style={{ color: "#aaaaaa" }}>NONPROFIT</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/31.webp" />
            <Text style={{ color: "#aaaaaa" }}>ADVERTISING</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/32.webp" />
            <Text style={{ color: "#aaaaaa" }}>AGRI-BUSINESS</Text>
          </Item>
          <Item>
            <img src="./image/customer-stories/33.webp" />
            <Text style={{ color: "#aaaaaa" }}>ENERGY</Text>
          </Item>
        </Box>
      </Container>
    </div>
  );
};

export default Teams;

const Container = styled.div`
  max-width: 1080px;
  margin: 10rem auto;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  @media (max-width: 980px) {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  font-weight: 300;
  @media (max-width: 980px) {
    margin: 0 1rem;
  }
`;

const HeadingText = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  font-weight: 300;
  @media (max-width: 980px) {
    margin: 0 1rem;
    text-align: center;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  @media (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
`;

const Item = styled.div`
  width: 21%;
  box-shadow: 0px 2px 6px 0px rgba(67, 77, 91, 0.22);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  @media (max-width: 980px) {
    width: 80%;
    margin-bottom: 2rem;
  }
`;
