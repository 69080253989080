import React, { useState } from "react";
import Title from "./components/Title";
import StarIcon from "@mui/icons-material/Star";
import styled from "styled-components";

const Rating = ({ block, blockIndex }) => {
  const [selectedRatings, setSelectedRating] = useState([]);

  const handleRatingClick = (id) => {
    setSelectedRating(id);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.text}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          margin: "15px 0 15px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            flexDirection:
              block.settings.direction === "HORIZONTAL" ? "row" : "column",
          }}
        >
          {block.settings.ratings.map((rating, index) => (
            <>
              <Ratings
                key={rating.id}
                isSelected={rating.id === selectedRatings}
                onClick={() => handleRatingClick(rating.id)}
              >
                {block.settings.numberedOptions ? (
                  index + 1
                ) : (
                  <StarIcon fontSize="10px" />
                )}
              </Ratings>
            </>
          ))}

          {block.settings.includeNonApplicable && (
            <Ratings
              isSelected={`${block.id}-NA` === selectedRatings}
              onClick={() => handleRatingClick(`${block.id}-NA`)}
            >
              NA
            </Ratings>
          )}
        </div>
      </div>
    </div>
  );
};

export default Rating;

const Ratings = styled.div`
  border: 1px solid black;
  padding: 6px;
  width: 28px;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? "white" : "black")};
  background-color: ${(props) =>
    props.isSelected ? "var(--color-primary)" : "white"};
`;
