import React from "react";
import Header from "../common/header/Header";
import InstantFeedbackSection from "./InstantFeedbackSection";
import ShareFeedbackSection from "./ShareFeedbackSection";
import ForgeStrongerSection from "./ForgeStrongerSection";
import KeepTrackSection from "./KeepTrackSection";
// import PrimalogikForFreeSection from "../common/PrimalogikForFreeSection";
// import DiscoverMoreSection from "./DiscoverMoreSection";
import FooterSection from "../common/FooterSection";
import PrimalogikSection from "../home/Primalogiksection";
import GetStartedSection from "../product/GetStartedSection";
import DiscoverMoreSection from "../performanceReviews/DiscoverMoreSection";
import Faqssection from "../home/Faqssection";
import OrganizationSection from "../product/OrganizationSection";

const InstantFeedback = () => {
  return (
    <>
      <Header />
      <InstantFeedbackSection />
      <OrganizationSection />
      <ShareFeedbackSection />
      <GetStartedSection />
      <ForgeStrongerSection />
      <KeepTrackSection />
      {/* <PrimalogikForFreeSection /> */}
      <DiscoverMoreSection />
      <PrimalogikSection />
      <Faqssection />
      <FooterSection />
    </>
  );
};

export default InstantFeedback;
