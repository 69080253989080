import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Heading = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <Box ref={inViewRef}>
      <Title style={props} ref={ref}>
        <Text style={{ fontSize: "4rem", fontWeight: 400 }}>
          Looking to get in touch?
        </Text>
        <Text style={{ fontSize: "1.8rem", opacity: 0.8 }}>
          We’d love to hear from you. Here’s how you can reach us.
        </Text>
      </Title>
    </Box>
  );
};

const Box = styled.div`
  padding-top: 7rem;
  background-image: url("/image/product/Product_Bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);
`;

const Text = styled.p`
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
  color: black;
  font-weight: 300;
`;

const Title = styled(animated.div)`
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export default Heading;
