import React from "react";
import TermsOfService from "../../view/terms-of-service";

const TermsOfServicePage = () => {
  document.title = "Terms of Service - ReviewGenie";

  return <TermsOfService />;
};

export default TermsOfServicePage;
