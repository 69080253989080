import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const GetStartedSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Content>
          <Heading>Ready to get started?</Heading>
          <Text>
            Schedule an online demo to learn how ReviewGenie can help your
            company. One of our solutions specialists will show you our platform
            and answer any questions you may have.
          </Text>
        </Content>
        <Btn>
          <Link to="/demo-request">
            <ReqButton variant="tertiary">GET A DEMO</ReqButton>
          </Link>
        </Btn>
      </Container>
    </div>
  );
};

const Container = styled(animated.div)`
  padding-top: 7rem;
  padding-bottom: 10rem;
  background-size: contain;
  background-image: url(./image/goalsandOKRs/4.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;

  only screen and (min-width: 1350px) {
    padding: 27px 0;
  }
  @media (max-width: 980px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const Content = styled.div`
  color: #46586a;
  text-align: center;
  padding: 0 14rem;
  word-wrap: break-word;
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-bottom: 2.75%;
  @media (max-width: 980px) {
    padding: 0 40px;
  }
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 30px;
    width: 100%;
  }
`;

const Heading = styled.h2`
  padding: 2rem 0;
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  color: #161a24;
`;

const Text = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 300;
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  text-align: center;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ReqButton = styled(Button)`
  font-size: 1.5rem;
  padding: 0.8rem;
  font-weight: 100;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.2rem 1rem;
  }
`;

export default GetStartedSection;
