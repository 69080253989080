import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import { APP_NAME } from "../../constants";

const Enterprise = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Box>
          <Contact>
            <Heading>Contact us today</Heading>
            <Text>
              Learn more about {APP_NAME} Enterprise and schedule a demo.
            </Text>
            <ul>
              <li style={{ fontSize: "large", margin: "1rem 0" }}>
                <Text>
                  Manage multiple users and gain visibility into all survey data
                  collected across your organization with admin controls and
                  dashboards.
                </Text>
              </li>
              <li style={{ fontSize: "large", margin: "1rem 0" }}>
                <Text>
                  Ensure confidential data is protected with enhanced security
                  including encryption, SSO, and features that help you remain
                  compliant with HIPAA and GDPR.
                </Text>
              </li>
              <li style={{ fontSize: "large", margin: "1rem 0" }}>
                <Text>
                  Make feedback automated and actionable by connecting to key
                  business systems using APIs and powerful integrations,
                  including Salesforce, Marketo, Tableau, and more.
                </Text>
              </li>
            </ul>
          </Contact>
          <Form>
            <InputBox>
              <Label for="first-name">First Name</Label>
              <Input type="text" placeholder="Enter your first name" />
            </InputBox>
            <InputBox>
              <Label for="first-name">Last name</Label>
              <Input type="text" placeholder="Enter your first name" />
            </InputBox>
            <InputBox>
              <Label for="first-name">Business email</Label>
              <Input type="text" placeholder="Enter your first name" />
            </InputBox>
            <InputBox>
              <Label for="first-name">Job title</Label>
              <Input type="text" placeholder="Enter your first name" />
            </InputBox>
            <InputBox>
              <Label for="first-name">Company</Label>
              <Input type="text" placeholder="Enter your first name" />
            </InputBox>
            <InputBox>
              <Label for="first-name">Phone number</Label>
              <Input type="text" placeholder="Enter your first name" />
            </InputBox>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "flex-start",
                marginBottom: "2rem",
              }}
            >
              <InputCheck type="checkbox" />
              <div>
                <Label>
                  I wish to receive other information about {APP_NAME}, like the
                  news and updates<Link to="/">described here</Link>. You can
                  <Link to="/"> at any time.</Link>
                </Label>
                <Label style={{ marginTop: "1rem" }}>
                  By clicking
                  <span style={{ fontWeight: 400 }}>'Contact sales'</span>, I am
                  requesting that someone contact me about my inquiry and
                  confirming I have read the {APP_NAME}
                  <Link to="/">Privacy Notice</Link>
                </Label>
              </div>
            </div>

            <Button backColor={"var(--color-primary)"}>Contact Sales</Button>
          </Form>
        </Box>
      </Container>
    </div>
  );
};

export default Enterprise;

const Container = styled(animated.div)`
  // width: 1040px;
  width: 80%;
  margin: 0 auto 4rem auto;
`;

const Box = styled.div`
  display: flex;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const Contact = styled.div`
  // width: 50%;
  padding: 3rem;
  @media (max-width: 980px) {
    padding: 1rem;
  }
`;

const Heading = styled.h2`
  font-size: 4rem;
  margin-bottom: 3rem;
`;

const Text = styled.p`
  font-size: 18px;
  margin: 0;
  text-align: left;
  color: black;
  font-weight: 300;
  @media (max-width: 980px) {
    font-size: 12px;
  }
`;

const Form = styled.div`
  // width: 50%;
  padding: 3rem;
  background-color: #f7f8fa;
  @media (max-width: 980px) {
    padding: 1rem;
  }
`;

const InputBox = styled.div`
  margin-bottom: 2rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
`;

const Label = styled.label`
  font-size: 15px;

  @media (max-width: 980px) {
    font-size: 12px;
  }
`;

const Button = styled.button`
  width: 35%;
  padding: 1rem 0;
  background-color: ${(props) => props.backColor};
  color: white;
  line-height: 1.5;
  font-size: 15px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--color-primary-dark);
  }
`;

const InputCheck = styled.input`
  width: 10rem;
  height: 3rem;
  border: 0;
  padding: 0;
`;
