import axios from "axios";
import { BACKEND_API_URL } from "../constants";

const baseURL = BACKEND_API_URL + "/api";

// Create an instance of axios
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
