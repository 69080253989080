/* eslint-disable jsx-a11y/anchor-is-valid */
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  CircularProgress,
  FormControl,
  FormHelperText,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Button from "../../components/button";
import authConfig from "../../configs/auth";
import api from "../../utils/api";

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: "1.2rem",
  textDecoration: "none",
}));

const CustomTextField = styled(TextField)`
  input {
    font-size: 1.6rem;,
  }
`;

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const defaultValues = {
  email: "",
};

const Password = () => {
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const res = await api.post(
          authConfig.sendResetPasswordLinkEndpoint,
          data
        );
        setOpen(true);
        setSuccessText(res.data.data.message);
        // setAuthToken(res.data.data.authToken);
        // const redirectURL = `http://localhost:3000?access_token=${res.data.data.authToken}`;
        // window.location.href = redirectURL;
      } catch (err) {
        const error = err?.response?.data?.message || "";
        setOpen(true);
        setErrorText(error);
      }
      reset();
      setLoading(false);
    },
    [reset]
  );

  return (
    <Container>
      <ContainerBox>
        <ContainerMainBox>
          <ContainerHeading>
            <HeadingText>
              <span>Forgot Password🔒</span>
            </HeadingText>
          </ContainerHeading>
          <ContainerBoxItem>
            <ContainerLeftBoxItem>
              <div>
                <p style={{ fontSize: "15px", color: "GrayText" }}>
                  Enter your email and we′ll send you instructions to reset your
                  password
                </p>
              </div>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Title>Email</Title>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <CustomTextField
                        size="small"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        placeholder="admin@reviewgenie.com"
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText
                      sx={{ color: "error.main", fontSize: "10px" }}
                    >
                      {errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <LoginBtn>
                  <LoginButton type="submit">
                    {loading ? (
                      <CircularProgress
                        sx={{
                          color: "common.white",
                          width: "20px !important",
                          height: "20px !important",
                          marginRight: "10px",
                        }}
                      />
                    ) : null}
                    Send reset link
                  </LoginButton>
                </LoginBtn>
                <div style={{ marginTop: "2rem", textAlign: "center" }}>
                  <LinkStyled to="/login" style={{ fontSize: "15px" }}>
                    <ArrowBackIcon />
                    <span>Back to login</span>
                  </LinkStyled>
                </div>
              </form>
            </ContainerLeftBoxItem>
          </ContainerBoxItem>
        </ContainerMainBox>
      </ContainerBox>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
      >
        <Alert
          variant="filled"
          severity={errorText ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "15px",
          }}
        >
          {errorText ? errorText : successText}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const Container = styled.div`
  font-family: "Inter", Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  max-width: 500px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  margin: 7rem auto;
  padding: 1.5rem 2rem 0 2rem;
  @media (max-width: 800px) {
    padding: 0;
    margin: 2rem auto;
    max-width: 100%;
  }
`;
const ContainerBox = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #f9fafc;
  @media (max-width: 800px) {
    background-color: #fff;
    flex-direction: column; // Change to a column layout on smaller screens
    box-shadow: none; // Remove box-shadow on smaller screens
  }
`;

const ContainerMainBox = styled.div`
  width: 100%;
`;
const ContainerHeading = styled.div`
  padding: 3rem 3rem 3rem 4rem;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 2.5rem;
  @media (max-width: 800px) {
    padding: 5rem;
    margin-bottom: 4rem;
  }
`;
const HeadingText = styled.div`
  text-align: left;
  font-size: 28px;
  font-weight: 300;
`;
const ContainerBoxItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 4rem 5rem 4rem;
  @media (max-width: 800px) {
    padding: 0 6rem 5.5rem 6rem;
    display: block;
  }
`;
const ContainerLeftBoxItem = styled.div`
  text-align: left;
  // width: 42%;
  width: 100%;
  // display: inline-block;
  // margin-right: 2rem;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
const Title = styled.div`
  font-family: "Inter", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  color: #333;
  line-height: 2rem;
  margin-top: 1.2rem;
  margin-bottom: 0.2rem;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 2.2rem;
    margin-top: 3rem;
    margin-bottom: 0.7rem;
  }
`;

const LoginBtn = styled.div`
  margin-top: 1.5rem;
  // width: 100%;
  text-align: center;
  @media (max-width: 800px) {
    // font-size: 1.5rem;
    margin-top: 4rem;
  }
`;
const LoginButton = styled(Button)`
  font-size: 1.5rem;
  font-weight: 100;
  padding: 1rem 5.2rem;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-primary);
  @media (max-width: 800px) {
    width: 100%;
    font-size: 2.5rem;
    font-weight: 400;
    padding: 2.5rem 5.5rem;
  }
`;

export default Password;
