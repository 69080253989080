import React from "react";
import Title from "./components/Title";
import { Box } from "@mui/material";

const Embed = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <Box
        dangerouslySetInnerHTML={{
          __html: block.settings.embedCode,
        }}
      />
    </div>
  );
};

export default Embed;
