/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const GoalsAndOKRsSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Feedback style={props} ref={ref}>
        <WorkForce>
          <Box>
            <BoxLeftItem>
              <Title>Goals and OKRs</Title>
              <Heading>
                Boost engagement and maximize results with goal setting
              </Heading>
              <Text>
                Increase employee motivation and productivity by setting clear
                goals and OKRs.
              </Text>
              <Btn>
                <Link to="/demo-request">
                  <ReqButton variant="tertiary">REQUEST A DEMO</ReqButton>
                </Link>
              </Btn>
            </BoxLeftItem>
            <BoxRightItem>
              <img
                src="./image/goalsandOKRs/1.webp"
                style={{ maxWidth: "100%", height: "auto" }}
              ></img>
            </BoxRightItem>
          </Box>
        </WorkForce>
      </Feedback>
    </div>
  );
};

const Feedback = styled(animated.div)`
  background-image: url("./image/goalsandOKRs/2.svg");
  background-size: contain;
  background-position: right 0px top;
  background-repeat: no-repeat;
`;

const WorkForce = styled.div`
  padding-top: 14rem;
  padding-bottom: 9rem;
  margin-bottom: 1px;
  top: -4rem;
  bottom: auto;
  @media (max-width: 980px) {
    padding: 7rem 1rem 0.5rem 1rem;
    margin-bottom: 4px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  padding: 7rem 0 0.5rem 0;
  margin: 2rem auto 0.4rem auto;
  max-width: 1180px;
  width: 90%;
  @media (max-width: 980px) {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    width: 80%;
  }
  @media (max-width: 480px) {
    width: 80%;
  }
`;
const BoxLeftItem = styled.div`
  width: 48.5%;
  //   padding-right: 8.4rem;
  margin: 0 3.5rem 1.6rem 0;
  @media (max-width: 980px) {
    width: 100%;
    padding-right: 0;
    margin: 0 0 30px 0;
  }
  @media (max-width: 480px) {
    margin-bottom: 30px;
  }
`;
const Title = styled.h4`
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 900;
  // font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  min-height: 17px;
  padding: 1rem 0 0 0;
  margin: 3rem 0 0.4rem 0;
  @media (max-width: 980px) {
    margin: 8rem 0 0.4rem 0;
    padding-bottom: 1rem;
  }
`;
const Heading = styled.h1`
  // font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 32px;
  color: #000000;
  text-align: left;
  line-height: 1.4em;
  font-weight: 900;
  padding-bottom: 10px;
  margin: 0;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #23282d;
  line-height: 1.8em;
  text-align: left;
  padding-bottom: 2.1rem;
  margin: 0;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 3.2rem;
    padding-bottom: 2.5rem;
  }
`;
const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
  }
`;
const ReqButton = styled(Button)`
  font-size: 1.5rem;
  padding: 0.8rem;
  font-weight: 100;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.5rem 1rem;
  }
`;

const BoxRightItem = styled.div`
  width: 48.5%;
  @media (max-width: 980px) {
    width: 100%;
  }
`;

export default GoalsAndOKRsSection;
