import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AppsIcon from "@mui/icons-material/Apps";
import { CircularProgress } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NavbarItem = ({
  title,
  children,
  menuPosition,
  link,
  data,
  loading,
  left,
}) => {
  const location = useLocation();

  if (data) {
    // Template
    return (
      <MenuItem
        subMenu={data}
        isCurrent={
          data && data.some((child) => child.link === location.pathname)
        }
        notRelative={!loading}
      >
        {data ? (
          <MenuLink
            isCurrent={data.some((child) => child.link === location.pathname)}
          >
            {title}
            <ExpandMoreIcon />
          </MenuLink>
        ) : (
          <CustomLink isCurrent={location.pathname === link} to={link}>
            {title}
          </CustomLink>
        )}
        {data && (
          <SubMenuWrapper left={left} menuPosition={menuPosition}>
            {loading ? (
              <div
                style={{
                  marginTop: "3rem",
                  padding: "2rem 0",
                  borderTop: "3px solid var(--color-primary)",
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <SubMenuTemplate>
                {data.map((child) => (
                  <SubMenuCustomLinkTemplate
                    hoverBgColor="var(--color-primary)"
                    hoverColor="white"
                    to={child.link}
                    isCurrent={location.hash.replace("#", "") === child.slug}
                    key={child.slug}
                  >
                    <TemplateBox>
                      <TemplateTitle>
                        <AccountBalanceIcon />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <h4
                            style={{
                              margin: 0,
                              fontWeight: 600,
                            }}
                          >
                            {child.title}
                          </h4>
                          <TemplateDes style={{ lineHeight: "1.5em" }}>
                            {child.des}
                          </TemplateDes>
                        </div>
                      </TemplateTitle>
                    </TemplateBox>
                  </SubMenuCustomLinkTemplate>
                ))}
                <SubMenuCustomLinkTemplate
                  hoverBgColor="var(--color-primary)"
                  hoverColor="white"
                  to="/all-templates"
                  isCurrent={location.pathname === "/all-templates"}
                >
                  <TemplateBox>
                    <TemplateTitle style={{ alignItems: "center" }}>
                      <AppsIcon sx={{ fontSize: "2rem" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <h4
                          style={{
                            margin: 0,
                            fontWeight: 600,
                          }}
                        >
                          See all Template
                        </h4>
                      </div>
                    </TemplateTitle>
                  </TemplateBox>
                </SubMenuCustomLinkTemplate>
              </SubMenuTemplate>
            )}
          </SubMenuWrapper>
        )}
      </MenuItem>
    );
  } else {
    return (
      <MenuItem
        subMenu={children}
        isCurrent={
          children && children.some((child) => child.link === location.pathname)
        }
      >
        {children ? (
          <MenuLink
            isCurrent={children.some(
              (child) => child.link === location.pathname
            )}
          >
            {title}
            <ExpandMoreIcon />
          </MenuLink>
        ) : (
          <CustomLink isCurrent={location.pathname === link} to={link}>
            {title}
          </CustomLink>
        )}
        {children && (
          <SubMenuWrapper left={left} menuPosition={menuPosition}>
            <SubMenu>
              {children.map((child, index) => (
                <SubMenuCustomLink
                  to={child.link}
                  key={index}
                  isCurrent={location.pathname === child.link}
                >
                  {child.title}
                </SubMenuCustomLink>
              ))}
            </SubMenu>
          </SubMenuWrapper>
        )}
      </MenuItem>
    );
  }
};

export default NavbarItem;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.isCurrent ? "var(--color-primary)" : "black")};
`;

const SubMenuCustomLink = styled(Link)`
  text-wrap: nowrap;
  align-items: center;
  height: 3rem;
  margin: 1rem 2rem 0 2rem;
  padding: 0 2rem;
  display: flex;
  text-decoration: none;
  color: ${(props) => (props.isCurrent ? "var(--color-primary)" : "black")};
  background-color: ${(props) => (props.isCurrent ? "#fafafa" : "transparent")};
  &&:hover {
    background-color: #fafafa;
    border-radius: 3px;
  }
  &&:hover > a {
    opacity: 0.7;
  }
`;

const SubMenuCustomLinkTemplate = styled(Link)`
  align-items: center;
  max-width: 300px;
  margin: 1rem;
  padding: 1rem;
  text-decoration: none;
  color: ${(props) => (props.isCurrent ? "var(--color-primary)" : "black")};
  background-color: ${(props) => (props.isCurrent ? "#fafafa" : "transparent")};
  &&:hover {
    background-color: ${(props) => props.hoverBgColor};
    color: ${(props) => props.hoverColor};
    border-radius: 3px;
  }
  &&:hover > a {
    opacity: 0.7;
  }
`;

const MenuItem = styled.li`
  margin-right: 2.5rem;
  position: ${(props) => (props.notRelative ? "static" : "relative")};
  color: ${(props) => (props.isCurrent ? "var(--color-primary)" : "black")};
  font-weight: 400;
  line-height: 1rem;

  &&:hover > a {
    opacity: 0.7;
  }

  &&:hover > div {
    display: block;
  }
`;

const MenuLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => (props.isCurrent ? "var(--color-primary)" : "black")};
`;

const SubMenuWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  display: none;
  background-color: transparent;
  transform: translateX(${(props) => props.left});
  min-width: 230px;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding: 2rem 0;
  border-top: 3px solid var(--color-primary);
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  &:before {
    content: "";
    position: absolute;
    top: 24px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-bottom: 6px solid var(--color-primary);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;

const SubMenuTemplate = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
  padding: 2rem 0;
  border-top: 3px solid var(--color-primary);
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    &:before {
    content: "";
    position: absolute;
    top: 24px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-bottom: 6px solid var(--color-primary);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    }
  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
`;

const TemplateBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
  min-width: 280px;
`;

const TemplateTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
`;

const TemplateDes = styled.p``;
