import React from "react";
import Title from "./components/Title";

const PictureChoice = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.text}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 10,
        }}
      >
        {block.settings.answerType === "RADIO"
          ? block.settings.pictureChoiceOptions.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  border: "1px solid #e0e0e0",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={item.imageUrl} alt="123" width="150px" />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <input name="choice" type="radio" value={item.label} />
                  <label style={{ fontSize: "14px" }} for={item.label}>
                    {item.label}
                  </label>
                </div>
              </div>
            ))
          : block.settings.pictureChoiceOptions.map((item, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  border: "1px solid #e0e0e0",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={item.imageUrl} alt="123" width="150px" />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <input name={item.label} type="checkbox" value={item.label} />
                  <label style={{ fontSize: "14px" }} for={item.label}>
                    {item.label}
                  </label>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default PictureChoice;
