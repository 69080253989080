import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import api from "../../utils/api";
import FooterSection from "../common/FooterSection";
import Header from "../common/header/Header";

const Blog = ({ slug }) => {
  const [blog, setBlog] = useState(null);
  const [blogLoading, setBlogLoading] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setBlogLoading(true);

        const response = await api.get(`/v1/blog/slug/${slug}`);

        setBlog(response.data.data);

        setBlogLoading(false);
      } catch (error) {
        setBlogLoading(false);
      }
    };

    fetchTemplates();
  }, [slug]);

  return (
    <>
      <Header />
      {blogLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={20}
            sx={{
              mr: 2,
            }}
          />
        </div>
      ) : (
        <div
          style={{
            maxWidth: "980px",
            margin: "0 auto",
            paddingTop: "14rem",
          }}
        >
          <Box
            dangerouslySetInnerHTML={{
              __html: blog?.content,
            }}
            sx={{
              "& p": {
                fontSize: "1.5rem",
                fontWeight: 300,
              },
              "& h1": {
                fontSize: "4rem",
                fontWeight: 600,
              },
              "& h2": {
                fontSize: "3.5rem",
                fontWeight: 600,
              },
              "& h3": {
                fontSize: "3rem",
                fontWeight: 600,
              },
              "& h4": {
                fontSize: "2.5rem",
                fontWeight: 600,
              },
              "& h5": {
                fontSize: "2rem",
                fontWeight: 600,
              },
              "& a": {
                color: "var(--color-primary)",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
            }}
          />
          <Link to="/demo-request">
            <Button>Request Demo</Button>
          </Link>
        </div>
      )}
      <FooterSection />
    </>
  );
};

export default Blog;

const Button = styled.button`
  width: 25%;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  background-color: white;
  color: var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: var(--color-primary);
    color: white;
  }
  @media (max-width: 1130px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
    margin: 15px 0 25px 0;
  }
`;
