/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const Item = ({ heading, text }) => {
  return (
    <ItemBox>
      <ItemHeading>
        <img
          style={{
            maxWidth: "100%",
            height: "auto",
            width: "20px",
            marginRight: "10px",
          }}
          src="./image/performanceReviews/11.svg"
        />
        {heading}
      </ItemHeading>
      <ItemText>{text}</ItemText>
    </ItemBox>
  );
};

const PerformanceSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div
      style={{
        backgroundImage: "linear-gradient(180deg,#f6fcff 0%,#ffffff 12%)",
        marginBottom: "8rem",
      }}
      ref={inViewRef}
    >
      <Container style={props} ref={ref}>
        <Content>
          <Heading>
            Better Understand People’s Performance Metrics with ReviewGenie
          </Heading>
          <Text>
            Create an environment where everyone benefits. Get useful insights
            on how each employee performs, how team members distinguish
            themselves, and keep track of managers' rating styles.
          </Text>
        </Content>
      </Container>

      <Box style={props} ref={ref}>
        <LeftItem>
          <Img src="./image/degreeFeedback/Report.png"></Img>
        </LeftItem>
        <RightItem>
          <Item
            heading="Score History"
            text="Track key performance metrics from one review cycle to another. Effortlessly view employees’ progress over time by generating individual score history reports. This helps in monitoring improvements and identifying areas that need attention."
          />
          <Item
            heading="Results Comparison"
            text="Compare team members' evaluations for a clearer understanding of each employee's performance. Easily identify unique skill sets to enhance team strength. This comparison aids in recognizing top performers and those who might need additional support."
          />
          <Item
            heading="Assessment Bias"
            text="Analyze different managers’ rating styles to ensure fairness across the organization. Review each manager’s lowest, highest, and median scores for all appraisals completed. This analysis helps in identifying any biases and ensuring consistent evaluation standards."
          />
          <Btn>
            <Link to="/demo-request">
              <ReqButton variant="tertiary">REQUEST A DEMO</ReqButton>
            </Link>
          </Btn>
        </RightItem>
      </Box>
    </div>
  );
};

const Container = styled(animated.div)`
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
  only screen and (min-width: 1350px) {
    padding: 27px 0;
    width: 80%;
  }
  @media (max-width: 980px) {
    padding: 30px 0;
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Content = styled.div`
  font-size: 18px;

  margin-bottom: 49px !important;
  text-align: center;
  word-wrap: break-word;
  @media (max-width: 980px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left;
  }
`;

const Heading = styled.h1`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-weight: 300;
  color: #46586a;
`;

const Box = styled(animated.div)`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const LeftItem = styled.div`
  width: 100%;
  margin-right: 6rem;

  @media (max-width: 980px) {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
const RightItem = styled.div`
  width: 100%;
  //   padding: 3.6rem 0 3.6rem 10rem;
  @media (max-width: 980px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const ItemBox = styled.div`
  margin-bottom: 3rem;
  text-align: left;
  word-wrap: break-word;
`;
const ItemHeading = styled.h3`
  font-size: 20px;
  line-height: 1.2em;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
  color: #161a24;
  font-size: 20px;
`;

const ItemText = styled.p`
  color: #46586a;
  font-size: 16px;
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ReqButton = styled(Button)`
  font-size: 1.5rem;
  padding: 1.3rem 2rem;
  border-radius: 28px;
  font-weight: 100;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.2rem 2rem;
  }
`;

export default PerformanceSection;
