import React from "react";
import Blog from "../../view/blog";
import { APP_NAME } from "../../constants";

const BlogView = () => {
  document.title = `Blog - ${APP_NAME}`;
  return <Blog />;
};

export default BlogView;
