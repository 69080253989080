import React from "react";
// import SubscribeSection from "../view/common/SubscribeSection";
import { APP_NAME } from "../../constants";
import DegreeFeedback from "../../view/degreeFeedback";

const DegreeFeedbackPage = () => {
  document.title = `360 Degree Feedback - ${APP_NAME}`;
  return <DegreeFeedback />;
};

export default DegreeFeedbackPage;
