import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Content = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div>
      <Box ref={inViewRef}>
        <Item style={props} ref={ref}>
          <Title>Privacy Policy</Title>
        </Item>
      </Box>
      <Container>
        <Text>Last updated: December 20, 2023</Text>
        <Text>
          <strong>
            Your privacy is very important to us. Accordingly, we have developed
            this policy in order for you to understand what information is
            collected and how it is used.
          </strong>
        </Text>
        <SubTitle>1. Personal Information Collected</SubTitle>
        <Text>
          When you register for the Primalogik service (the Service) we ask for
          information such as your name, company/organization name, and email
          address. Only if you register for (or upgrade to) a paid account you
          will be required to enter a credit card number and your billing
          address.
        </Text>
        <Text>
          Where we provide the Service under contract with an organization (for
          example your employer, or a consulting firm providing you services)
          that organization controls the information processed by the services.
          For more information, please see the "Notice to End Users" section
          below.
        </Text>
        <SubTitle>2. Use of Personal Information Collected</SubTitle>
        <Text>
          Primalogik uses your collected information to improve the Primalogik
          Service and to respond to your customer service requests and support
          needs. We may occasionally contact you with product updates that we
          feel will improve your experience with Primalogik.
        </Text>
        <SubTitle>3. Web Site Navigational Information</SubTitle>
        <Text>
          The Primalogik application and website uses Google Analytics to
          collect information about its visitors, but it does not track any user
          identifiable information through Google Analytics in accordance with
          Google Analytics terms and conditions.
        </Text>
        <SubTitle>4. Information Storage and Security</SubTitle>
        <Text>
          We will protect personal information by reasonable security safeguards
          against loss or theft, as well as unauthorized access, disclosure,
          copying, use or modification. Primalogik uses secure third party
          vendors and hosting partners to provide the necessary technology to
          run our services.
        </Text>
        <Text>
          While we implement safeguards designed to protect your information, no
          security system is impenetrable and we cannot guarantee that data,
          during transmission through the Internet or while stored on our
          systems or otherwise in our care, is absolutely safe from intrusion by
          others.
        </Text>
        <SubTitle>How long we keep information</SubTitle>
        <Text>
          How long we keep information we collect about you depends on the type
          of information, as described below. After such time, we will either
          delete or anonymize your information or, if this is not possible (for
          example, because the information has been stored in backup archives),
          then we will securely store your information and isolate it from any
          further use until deletion is possible.
        </Text>
        <Text>
          <strong>Account information:</strong> We retain your account
          information for as long as your account is active and a reasonable
          period thereafter in case you decide to re-activate the Service. We
          also retain some of your information as necessary to comply with our
          legal obligations, to support business operations, to enforce our
          agreements, to resolve disputes, and to continue to develop and
          improve our Service. Where we retain information for Service
          improvement, we take steps to eliminate information that directly
          identifies you, and we only use the information to uncover collective
          insights about the use of our Service, not to specifically analyze
          personal characteristics about specific individuals.
        </Text>
        <Text>
          <strong>Information you share on the Service:</strong> If your account
          is deactivated or disabled, some of your information and the content
          you have provided will remain available to allow other users to make
          full use of the Service. For example, we continue to display messages
          you sent to the users that received them and continue to display
          feedback you provided.
        </Text>
        <Text>
          <strong>Managed accounts:</strong> If the Services are made available
          to you through an organization (e.g., your employer), we retain your
          information as long as required by the administrator of your account.
          For more information, see "Managed accounts and administrators" below.
        </Text>
        <Text>
          <strong>Marketing information:</strong> If you have elected to receive
          marketing emails from us, we retain information about your marketing
          preferences for a reasonable period of time from the date you last
          expressed interest in our Services, such as when you last opened an
          email from us. We retain information derived from cookies and other
          tracking technologies for a reasonable period of time from the date
          such information was created.
        </Text>
        <SubTitle>5. Sharing of Personal Information Collected</SubTitle>
        <Text>
          Primalogik does not sell, trade, or otherwise transfer to outside
          parties your personally identifiable information other than for the
          purpose of providing the service, with the exception of when we
          believe the release is necessary to comply with the law.
        </Text>
        <SubTitle>
          Sharing with other Service users within your organization
        </SubTitle>
        <Text>
          When you use the Service, we share certain information about you with
          other Service users.
        </Text>
        <Text>
          <strong>For collaboration:</strong> You can create content, which may
          contain information about you, and grant permission to others to see,
          share, edit, copy and download that content based on settings you or
          your administrator (if applicable) select. Some of the collaboration
          features of the Service display some or all of your profile
          information to other Service users when you share or interact with
          specific content. For example, when you send instant feedback to a
          coworker, we display your profile picture and name next to your
          comments so that other users with access to the feedback message
          understand who made the comment.
        </Text>
        <Text>
          <strong>Managed accounts and administrators:</strong> If you register
          or access the Service using an account that is owned by your employer
          or organization, certain information about you including your name,
          profile picture, contact info, content and past use of your account
          may become accessible to that organization’s administrator and other
          Service users in the same organization. If you are an administrator
          for a particular organization within the Service, we may share your
          contact information with current or past Service users, for the
          purpose of facilitating Service-related requests.
        </Text>
        <SubTitle>Sharing with third parties</SubTitle>
        <Text>
          We share information with third parties that help us operate, provide,
          improve, integrate, support and market our Service.
        </Text>
        <Text>
          <strong>Service Providers:</strong> We work with third-party service
          providers who provide website and application development, hosting,
          maintenance, backup, storage, virtual infrastructure, payment
          processing, analysis and other services for us, which may require them
          to access or use information about you. If a service provider needs to
          access information about you to perform services on our behalf, they
          do so under close instruction from us, including policies and
          procedures designed to protect your information.
        </Text>
        <Text>
          <strong>Primalogik Partners:</strong> We work with third parties who
          provide consulting, sales, and technical services to deliver and
          implement solutions around the Service. We may share your information
          with these third parties in connection with their services, such as to
          assist with billing and collections. We may also share information
          with these third parties where you have agreed to that sharing.
        </Text>
        <Text>
          <strong>With your consent:</strong> We share information about you
          with third parties when you give us consent to do so. For example, we
          often display personal testimonials of satisfied customers on our
          public websites. With your consent, we may post your name alongside
          the testimonial.
        </Text>
        <Text>
          <strong>
            Compliance with Enforcement Requests and Applicable Laws;
            Enforcement of Our Rights:
          </strong>
          In exceptional circumstances, we may share information about you with
          a third party if we believe that sharing is reasonably necessary to
          (a) comply with any applicable law, regulation, legal process or
          governmental request, including to meet national security
          requirements, (b) enforce our agreements, policies and terms of
          service, (c) protect the security or integrity of our products and
          services, (d) protect Primalogik, our customers or the public from
          harm or illegal activities, or (e) respond to an emergency which we
          believe in good faith requires us to disclose information to assist in
          preventing the death or serious bodily injury of any person.
        </Text>
        <Text>
          <strong>Business Transfers:</strong> We may share or transfer
          information we collect under this privacy policy in connection with
          any merger, sale of company assets, financing, or acquisition of all
          or a portion of our business to another company. You will be notified
          via email and/or a prominent notice on the Service if a transaction
          takes place, as well as any choices you may have regarding your
          information.
        </Text>
        <SubTitle>6. Cookies</SubTitle>
        <Text>
          Primalogik uses cookies (small files sent to your browser from a
          website and stored on your computer) to enable you to sign in and
          store session information. Cookies are required to use the Service.
        </Text>
        <SubTitle>7. Changes to this Privacy Policy</SubTitle>
        <Text>
          We may change this privacy policy from time to time. We will post any
          privacy policy changes on this page and, if the changes are
          significant, we will provide a more prominent notice by adding a
          notice on the Announcements section of our Service, or by sending you
          an email notification. We encourage you to review our privacy policy
          whenever you use the Service to stay informed about our information
          privacy practices.
        </Text>
        <Text>
          If you disagree with any changes to this privacy policy, you will need
          to stop using the Service and deactivate your account(s), as outlined
          below.
        </Text>
        <SubTitle>8. How to access and control your information</SubTitle>
        <Text>
          You have certain choices available to you when it comes to your
          information. Below is a summary of those choices, how to exercise them
          and any limitations.
        </Text>
        <SubTitle>Your Choices:</SubTitle>
        <Text>
          You have the right to request a copy of your information, to object to
          our use of your information (including for marketing purposes), to
          request the deletion or restriction of your information, or to request
          your information in a structured, electronic format. Below, we
          describe the tools and processes for making these requests. You can
          exercise some of the choices by logging into the Service and using
          options available within the Service. Where the Service is
          administered for you by an administrator (see "Notice to End Users"
          below), you may need to contact your administrator to assist with your
          requests first. For all other requests, you may contact us as provided
          in the "Contact Us" section below to request assistance.
        </Text>
        <Text>
          Your request and choices may be limited in certain cases: for example,
          if fulfilling your request would reveal information about another
          person, or if you ask to delete information which we or your
          administrator are permitted by law or have compelling legitimate
          interests to keep. Where you have asked us to share data with third
          parties, for example, by asking to be contacted by one of our referral
          partners, you will need to contact those third-party service providers
          directly to have your information removed.
        </Text>
        <Text>
          <strong>Access and update your information:</strong> Our Service and
          related documentation give you the ability to access and update
          certain information about you from within the Service. For example,
          you can access your profile information from your account and search
          for content containing information about you using search tools in the
          Service. You can update your profile information within your profile
          page and modify information about you using the editing tools
          associated with your profile, provided you have the privileges
          necessary to perform those updates.
        </Text>
        <Text>
          <strong>Deactivate your account:</strong> If you no longer wish to use
          our Service, your administrator may be able to deactivate your
          account. Please contact your administrator for assistance. If you are
          an administrator and are unable to deactivate an account through your
          administrator settings, please contact Primalogik support. Please be
          aware that deactivating your account does not delete your information;
          your information remains visible to other Service users based on your
          past participation within the Service.
        </Text>
        <Text>
          <strong>Delete your information:</strong> Our Service and related
          documentation give you the ability to delete certain information about
          you from within the Service. For example, you can remove instant
          feedback messages you have entered in the system. Please note,
          however, that we may need to retain certain information for record
          keeping purposes, to make sure 360 feedback survey reports remain
          accurate or to comply with our legal obligations.
        </Text>
        <Text>
          <strong>Request that we stop using your information:</strong> In some
          cases, you may ask us to stop accessing, storing, using and otherwise
          processing your information where you believe we don't have the
          appropriate rights to do so. For example, if you believe a Service
          account was created for you without your permission or you are no
          longer an active user, you can request that we delete your account as
          provided in this policy. Where you gave us consent to use your
          information for a limited purpose, you can contact us to withdraw that
          consent, but this will not affect any processing that has already
          taken place at the time. You can also opt-out of our use of your
          information for marketing purposes by contacting us, as provided
          below. When you make such requests, we may need time to investigate
          and facilitate your request. If there is delay or dispute as to
          whether we have the right to continue using your information, we will
          restrict any further use of your information until the request is
          honored or the dispute is resolved, provided your administrator does
          not object (if applicable).
        </Text>
        <Text>
          <strong>Opt out of communications:</strong> You may opt out of
          receiving promotional communications from us by using the unsubscribe
          link within each email, updating your email preferences within your
          Service account settings menu, or by contacting us as provided below
          to have your contact information removed from our promotional email
          list or registration database. Even after you opt out from receiving
          promotional messages from us, you will continue to receive
          transactional messages from us regarding our Service.
        </Text>
        <Text>
          <strong>Data portability:</strong> Data portability is the ability to
          obtain some of your information in a format you can move from one
          service provider to another (for instance, when you transfer your
          mobile phone number to another carrier). Depending on the context,
          this applies to some of your information, but not to all of your
          information. Should you request it, we will provide you with an
          electronic file of your basic account information and the information
          you create on the spaces you under your sole control, like your
          personal performance appraisal results.
        </Text>
        <SubTitle>
          9. How we transfer information we collect internationally
        </SubTitle>
        <SubTitle>International transfers of information we collect</SubTitle>
        <Text>
          We collect information globally and store that information in Canada.
          We transfer, process and store your information outside of your
          country of residence, to wherever we or our third-party service
          providers operate for the purpose of providing you the Service.
          Whenever we transfer your information, we take steps to protect it.
        </Text>
        <Text>
          <strong>International transfers within Primalogik:</strong> To
          facilitate our global operations, we transfer information to Canada
          and allow access to that information from countries in which the
          Primalogik owned or operated companies have operations for the
          purposes described in this policy. These countries may not have
          equivalent privacy and data protection laws to the laws of many of the
          countries where our customers and users are based. When we share
          information about you within and among Primalogik corporate
          affiliates, we make use of standard contractual data protection
          clauses, which have been approved by the European Commission. Note:
          This provision will be retired on November 30th 2019. As of that date
          Primalogik data will be stored exclusively in Canada.
        </Text>
        <Text>
          <strong>International transfers to third parties:</strong> Some of the
          third parties described in this privacy policy, which provide services
          to us under contract, are based in other countries that may not have
          equivalent privacy and data protection laws to the country in which
          you reside. When we share information of customers in the European
          Economic Area, we make use of European Commission-approved standard
          contractual data processing clauses, or other appropriate legal
          mechanisms to safeguard the transfer.
        </Text>
        <SubTitle>10. Notice to End Users</SubTitle>
        <Text>
          Our products are intended for use by organizations. Where the Service
          is made available to you through an organization (e.g. your employer),
          that organization is the administrator of the Service and is
          responsible for the accounts and/or organization over which it has
          control. If this is the case, please direct your data privacy
          questions to your administrator, as your use of the Service is subject
          to that organization's policies. We are not responsible for the
          privacy or security practices of an administrator's organization,
          which may be different than this policy.
        </Text>
        <Text>
          <strong>Administrators are able to:</strong>
        </Text>
        <div style={{ paddingLeft: "2rem" }}>
          <Ul>
            <Li>
              <Text style={{ margin: 0 }}>
                require you to reset your account password;
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                restrict, suspend or terminate your access to the Service;
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                access or retain information stored as part of your account;
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                restrict, suspend or terminate your account access;
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                change the email address associated with your account;
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                change your information, including profile information;
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                restrict your ability to edit, restrict, modify or delete
                information
              </Text>
            </Li>
          </Ul>
        </div>
        <Text>
          <strong>In some cases, administrators can also:</strong>
        </Text>
        <div style={{ paddingLeft: "2rem" }}>
          <Ul>
            <Li>
              <Text>access information in and about your account.</Text>
            </Li>
          </Ul>
        </div>
        <Text>
          Please contact your organization or refer to your administrator’s
          organizational policies for more information.
        </Text>
        <SubTitle>11. Contact Us</SubTitle>
        <Text>
          If you have questions or concerns about this Privacy Policy or how
          your information is handled, please direct your inquiry to the person
          responsible for privacy and data protection at Primalogik Software
          Solutions Inc.:
        </Text>
        <Text style={{ margin: 0 }}>
          Gabriel Dominguez Martin - Co-founder & CEO
        </Text>
        <Text style={{ margin: 0 }}>
          2001 Boul. Robert-Bourassa, Suite 1700
        </Text>
        <Text style={{ margin: 0 }}>Montreal (Quebec) Canada H3A 2A6</Text>
        <A style={{ margin: 0 }} to="mailto:privacy@primalogik.com">
          privacy@primalogik.com
        </A>
      </Container>
    </div>
  );
};

export default Content;

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto 8rem auto;
  width: 80%;
  padding-top: 10rem;
`;

const Text = styled.p`
  font-size: 16px;
  margin: 2rem 0;
  color: #46586a;
  line-height: 1.7em;
  word-wrap: break-word;
`;

const SubTitle = styled.h2`
  margin-top: 2rem;
  font-size: 23px;
  font-weight: 600;
  color: black;
`;

const Li = styled.li`
  margin: 0;
`;
const Ul = styled.ul`
  font-size: 16px;
`;

const A = styled(Link)`
  color: var(--color-primary);
  text-decoration: none;
  font-size: 16px;
  margin: 0 5px;
`;

const Box = styled.div`
  padding-top: 10rem;
  background-image: url("/image/home/HomeSection/Bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
`;

const Item = styled(animated.div)`
  max-width: 1080px;
  margin: 0 auto;
  width: 80%;
`;

const Title = styled.h1`
  padding: 10rem 0;
  font-size: 30px;
  font-weight: 600;
  color: white;
`;
