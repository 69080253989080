import React from "react";
import Header from "../common/header/Header";
import WorkforceSection from "./WorkforceSection";
import OrganizationSection from "./OrganizationSection";
// import ManagementSystemSection from "./ManagementSystemSection";
import FeedbackSection from "./FeedbackSection";
import PerformanceSection from "./PerformanceSection";
// import GoalsandObjectivesSection from "./GoalsandObjectivesSection";
import InstantFeedbackSection from "./InstantFeedbackSection";
import OpinionSurveysSection from "./OpinionSurveysSection";
import GetStartedSection from "./GetStartedSection";
import FeaturesSection from "./FeaturesSection";
// import SubscribeSection from "../common/SubscribeSection";
import FooterSection from "../common/FooterSection";
import Faqssection from "../home/Faqssection";
import PrimalogikSection from "../home/Primalogiksection";
import DiscoverMoreSection from "../performanceReviews/DiscoverMoreSection";

const Product = () => {
  return (
    <>
      <Header />
      <WorkforceSection />
      <OrganizationSection />
      <FeedbackSection />
      <PerformanceSection />
      {/* <GoalsandObjectivesSection /> */}
      {/* <PrimalogikSection /> */}
      <DiscoverMoreSection />
      {/* <ManagementSystemSection /> */}
      <InstantFeedbackSection />
      <OpinionSurveysSection />
      <FeaturesSection />
      <GetStartedSection />
      {/* <ReviewSection /> */}
      {/* <IntegrationSection /> */}
      <PrimalogikSection />
      <Faqssection />
      {/* <SubscribeSection /> */}
      <FooterSection />
    </>
  );
};

export default Product;
