import React from "react";
import Text from "./components/Text";

const TextBlock = ({ block, blockIndex }) => {
  return (
    <Text>
      {blockIndex + 1} . {block.settings.title}
    </Text>
  );
};

export default TextBlock;
