import React from "react";
import styled from "styled-components";
// import { APP_NAME } from "../../constants";
import { Link } from "react-router-dom";
import Button from "../../components/button";

const Description = () => {
  // const data = templates.templates;

  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Star>
            {[1, 2, 3, 4, 5].map((index) => (
              <StarImage
                key={index}
                src="./image/home/PrimalogikSection/Icons_star-filled-color.svg"
                alt={`Star ${index}`}
              />
            ))}
          </Star> */}
          <Count>188,236,428</Count>
          {/* <Text style={{ marginBottom: "4rem" }}>
            {APP_NAME} is rated
            <span style={{ fontWeight: "600" }}> 5 out of 5 </span> from
            <span style={{ color: "var(--color-primary)" }}>
              18,000+ reviews
            </span>
            on G2.com
          </Text> */}
          <Text>Delivered form submissions. Ready to get yours?</Text>

          <Btn>
            <Link to="/demo-request">
              <ReqButton variant="primary">GET STARTED – IT’S FREE</ReqButton>
            </Link>
          </Btn>
          {/* <h2 style={{ fontWeight: "600", fontSize: "44px" }}>
            {data?.question}
          </h2>
          <Text>{data?.answer}</Text> */}
        </div>
      </Container>
    </div>
  );
};

export default Description;

const Container = styled.div`
  width: 80%;
  margin: 10rem auto 0 auto;
  background: url(./image/template/Review_Templete.png) center / cover;
`;

// const Star = styled.p`
//   padding-bottom: 0;
//   font-weight: 400;
//   margin: 0;
// `;

// const StarImage = styled.img`
//   height: 4rem;
//   width: 4rem;
//   @media (max-width: 980px) {
//     height: 3rem;
//     width: 3rem;
//   }
// `;

const Count = styled.p`
  font-size: 50px;
  font-weight: 700;
  line-height: 1.5em;
  margin: 1rem 0;
  @media (max-width: 760px) {
    font-size: 40px;
  }
  @media (max-width: 570px) {
    font-size: 35px;
  }
`;

const Text = styled.p`
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  line-height: 5rem;
  padding: 5rem 0;
  @media (max-width: 760px) {
    font-size: 25px;
  }
  @media (max-width: 570px) {
    font-size: 20x;
  }
`;

const ReqButton = styled(Button)`
  font-size: 2rem;
  padding: 1.3rem 3rem;
  font-weight: 100;
  border-radius: 28px;
  margin: 2rem;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.3rem 3rem;
  }
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
  }
`;
