import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const ContentItem = ({ heading, text }) => {
  return (
    <ItemBox>
      <ContentImg src="./image/opinion-survey/10.svg" />
      <Text>
        <ContentHeading>{heading}</ContentHeading>
        <ContentText>{text}</ContentText>
      </Text>
    </ItemBox>
  );
};

const FeaturesSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Features style={props} ref={ref}>
        <Content>
          <ContentItem
            heading="Design Your Surveys"
            text="Create custom surveys using a variety of rating scales, multiple-choice, and open-ended questions to gain insights into employee satisfaction and workplace culture. Understand how your employees feel about their roles, the company, and its policies."
          />
          <ContentItem
            heading="Collect Anonymous Feedback"
            text="Enable ReviewGenie administrators to collect anonymous feedback from employees, ensuring honest responses about job satisfaction and workplace issues. Gather valuable insights to improve employee engagement and company policies."
          />
          <ContentItem
            heading="Select Participants"
            text="Target your surveys to the entire organization or specific groups to obtain precise insights on projects, team dynamics, and overall employee morale. Enhance the quality of your data collection by carefully selecting participants."
          />
          <ContentItem
            heading="View Results"
            text="Access comprehensive reports to view survey results in a single location. Analyze response rates and average ratings to better understand employee feedback and identify areas for improvement."
          />
          <ContentItem
            heading="Take Action"
            text="Use survey data to pinpoint factors affecting employee satisfaction. Implement actionable steps to address concerns and improve workplace conditions, showing employees that their feedback is valued and impactful."
          />
          <ContentItem
            heading="Select Teams"
            text="Easily manage and organize feedback for specific groups within your organization. With ReviewGenie's select teams feature, you can: Create customized teams based on department, project, or location, Streamline your feedback process and gain valuable insights into team performance."
          />
        </Content>
      </Features>
    </div>
  );
};

const Features = styled(animated.div)`
  background-color: #ffffff !important;
  padding: 4rem 0;
`;

const Content = styled.div`
  width: 80%;
  max-width: 1180px;
  margin: auto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  @media (max-width: 980px) {
    width: 80%;
    display: block;
    align-items: left;
  }
  @media (max-width: 767px) {
    width: 80%;
    display: block;
  }
`;

const ItemBox = styled.div`
  position: relative;
  margin-bottom: 6rem;
  margin-right: 48px;
  @media (max-width: 980px) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
`;

const ContentImg = styled.img`
  width: 25px;
  @media (max-width: 980px) {
    width: 25px;
  }
  @media (max-width: 767px) {
  }
`;
const Text = styled.div`
  text-align: left;
  @media (max-width: 980px) {
    text-align: left;
  }
  @media (max-width: 767px) {
  }
`;
const ContentHeading = styled.h3`
  margin: 0;
  font-size: 30px;
  padding: 2rem 0;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  word-break: break-word;
  @media (max-width: 980px) {
    line-height: 1.8em;
  }
`;

const ContentText = styled.p`
  line-height: 1.7em;
  -webkit-font-smoothing: antialiased;
  display: contents;
  color: var(--color-subtext);
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  word-break: break-word;
  @media (max-width: 980px) {
  }
`;

export default FeaturesSection;
