import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import { SUPPORT_EMAIL, TOLL_FREE_NUMBER } from "../../constants";
import ContactNo from "./ContactNo";
import { Dialog } from "@mui/material";

const Content = () => {
  const ref = useRef();
  const [open, setOpen] = React.useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <>
      <div ref={inViewRef}>
        <Heading style={props} ref={ref}>
          <Text
            style={{
              fontSize: "30px",
              fontWeight: 600,
            }}
          >
            Contact our support team
          </Text>
          <Text style={{ color: "#666" }}>
            We are here to help! Here’s how you can reach us.
          </Text>
        </Heading>
        <Container style={props} ref={ref}>
          <Box>
            <Item>
              <Img src="./logo/email.png" />
              <Text
                style={{ marginTop: "2rem", fontSize: "18px", fontWeight: 600 }}
              >
                Have any questions or need assistance? Send us an email at
              </Text>
              <Text
                style={{ fontSize: "15px", color: "#666", marginTop: "1rem" }}
              >
                <a
                  style={{
                    color: "var(--color-primary)",
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                  href={"mailto:" + SUPPORT_EMAIL}
                >
                  {SUPPORT_EMAIL}
                </a>
              </Text>
            </Item>
            <Item>
              <Img src="./logo/phone.png" />
              <Text
                style={{
                  marginTop: "2rem",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Speak with our support team directly. Call us toll-free at
              </Text>
              <Text
                style={{ fontSize: "15px", color: "#666", marginTop: "1rem" }}
              >
                Toll Free:
                <Link
                  style={{
                    color: "var(--color-primary)",
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                  // to={TOLL_FREE_NUMBER}
                  onClick={handleClickOpen}
                >
                  {TOLL_FREE_NUMBER}
                </Link>
              </Text>
            </Item>
            <Item>
              <Img src="./logo/chat.png" />
              <Text
                style={{
                  marginTop: "2rem",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                For instant support, use our live chat feature:
              </Text>
              <Text
                style={{ fontSize: "15px", color: "#666", marginTop: "1rem" }}
              >
                <Link
                  style={{
                    color: "var(--color-primary)",
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                  to="/demo-request"
                >
                  chat
                </Link>
              </Text>
            </Item>
          </Box>
        </Container>
        <Heading>
          <Text
            style={{
              fontSize: "15px",
              color: "#666",
            }}
          >
            <span style={{ fontWeight: 600 }}>Support Hours:</span> 9am to 7pm
            (IST), Monday to Friday
          </Text>
        </Heading>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        // sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 1000 } }}
        // fullWidth
      >
        <ContactNo handleClose={handleClose} />
      </Dialog>
    </>
  );
};

export default Content;

const Heading = styled(animated.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin: 7rem 0;
`;

const Text = styled.p`
  font-size: 1.5rem;
  margin: 0;
  color: black;
  text-align: center;
  color: black;
`;

const Container = styled(animated.div)`
  max-width: 1080px;
  margin: 0 auto;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5rem;
  @media (max-width: 1080px) {
    padding: 0 5rem;
  }
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 5rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  @media (max-width: 960px) {
    width: 100%;
  }
`;
const Img = styled.img`
  width: 70px;
`;
