/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";

const ProductivitySection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Productivity>
        <Productivitycontent>
          <ProductivityImg>
            <img
              src="./image/home/ProductivitySection/2.jpeg"
              style={{ height: "auto", width: "88%" }}
            ></img>
          </ProductivityImg>
          <Text style={props} ref={ref}>
            <PText>
              <ProductivityHeading>
                All the tools you need to craft exceptional surveys
              </ProductivityHeading>
              <ProductivityText>
                Unlock a variety of survey templates tailored for customers,
                employees, or any target audience. Select from expertly crafted
                sample questions to enhance your surveys. Utilize ReviewGenie to
                score your surveys and predict their success rates. Discover our
                best practices to design even the most complex and sophisticated
                surveys.
              </ProductivityText>
            </PText>
            <ProductivityBtn>
              <Link
                to="/product"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                <ProductivityButton variant="primary">
                  Learn More
                </ProductivityButton>
              </Link>
            </ProductivityBtn>
          </Text>
        </Productivitycontent>
      </Productivity>
    </div>
  );
};

const Productivity = styled(animated.div)`
  padding: 6rem 0;
  text-align: center;
  @media (max-width: 980px) {
    padding: 3rem 0;
  }
`;
const Productivitycontent = styled.div`
  width: 95%;
  max-width: 1100px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  @media (max-width: 980px) {
    width: 80%;
    display: block;
  }
`;

const ProductivityImg = styled.div`
  width: 50%;
  margin-right: 6rem;
  background-color: white;
  z-index: 1;
  @media (max-width: 980px) {
    width: 100%;
  }
`;
const Text = styled(animated.div)`
  text-align: left;
  width: 50%;
  @media (max-width: 980px) {
    text-align: left;
    width: 100%;
    padding-top: 6rem;
  }
`;

const PText = styled.div`
  padding-top: 4.5rem;
  @media (max-width: 980px) {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
`;

const ProductivityHeading = styled.div`
  margin: 0;
  font-size: 3rem;
  padding: 1.5rem 0;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  word-wrap: break-word;
  @media (max-width: 980px) {
    width: 70%;
    font-size: 5rem;
    padding: 1rem 0;
    padding-bottom: 2rem;
  }
`;

const ProductivityText = styled.div`
  // display: block;
  margin: 0;
  word-wrap: break-word;
  color: var(--color-subtext);
  font-size: 1.79rem;
  font-weight: 300;
  // opacity: 0.7;
  @media (max-width: 980px) {
    font-size: 2.7rem;
  }
}
`;

const ProductivityBtn = styled.div`
  padding-top: 2.5rem;
  cursor: pointer;
  width: 60%;
  @media (max-width: 980px) {
    width: 70%;
    font-size: 3rem;
  }
  @media (max-width: 768px) {
    width: 80%;
    font-size: 3rem;
  }
`;

const ProductivityButton = styled(Button)`
  width: 100%;
  font-size: 1.8rem;
  padding: 1rem;
  border-radius: 2.5rem;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 2rem 4rem;
    margin: 0 5rem;
  }
  @media (max-width: 768px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 2rem 4rem;
    margin: 0 4rem;
  }
  @media (max-width: 627px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 2rem 4rem;
    margin: 0 3rem;
  }
`;
export default ProductivitySection;
