import React from "react";
import Header from "../common/header/Header";
import Content from "./Content";
import FooterSection from "../common/FooterSection";
import PrimalogikSection from "../home/Primalogiksection";
import Faqssection from "../home/Faqssection";
import Welcome from "./Welcome";

const Blog = () => {
  return (
    <>
      <Header />
      <Welcome />
      <Content />
      <PrimalogikSection />
      <Faqssection />
      <FooterSection />
    </>
  );
};

export default Blog;
