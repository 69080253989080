import React from "react";
import Title from "./components/Title";
import UploadIcon from "@mui/icons-material/Upload";
import Text from "./components/Text";
import styled from "styled-components";

const FileUpload = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Upload style={{}}>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <UploadIcon sx={{ mr: 1 }} fontSize="large" />
            <Text fontSize="10px">Drop your File here (or) Choose</Text>
          </div>
        </Upload>
      </div>
    </div>
  );
};

export default FileUpload;

const Upload = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ededed;

  &:hover {
    cursor: pointer;
    background-color: #dcdcdc;
  }
`;
