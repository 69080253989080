/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const InstantFeedbackSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef} id="4">
      <FeedBack>
        <FeedBackBox>
          <FeedBackLeftItem style={props} ref={ref}>
            <FeedBackLogo>
              <Logo src="./image/product/10.svg"></Logo>
            </FeedBackLogo>
            <Heading>Instant Feedback for a Positive Workplace Culture</Heading>
            <Text>
              Boost Engagement with Real-Time Recognition: Develop a thriving
              feedback culture in your workplace. Recognize outstanding work
              daily to keep your team motivated and engaged. By encouraging
              constructive criticism and real-time recognition of contributions,
              you foster a collaborative and supportive environment.
            </Text>
            <Text>Key Benefits:</Text>
            <ul>
              <Text>
                <li>
                  Enhance Employee Engagement: Immediate feedback keeps
                  employees motivated and focused.
                </li>
              </Text>
              <Text>
                <li>
                  Promote Constructive Communication: Encourage team members to
                  share valuable insights and constructive criticism.
                </li>
              </Text>
              <Text>
                <li>
                  Real-Time Recognition: Acknowledge and celebrate contributions
                  as they happen.
                </li>
              </Text>
            </ul>
            <MoreButton>
              <Linked to="/instant-feedback/">LEARN MORE</Linked>
            </MoreButton>
          </FeedBackLeftItem>
          <FeedBackRightItem>
            <FeedBackImg src="./image/product/Feedback.jpg"></FeedBackImg>
          </FeedBackRightItem>
        </FeedBackBox>
      </FeedBack>
    </div>
  );
};

const FeedBack = styled.div`
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;
const FeedBackBox = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1100px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    display: block;
  }
`;
const FeedBackRightItem = styled.div`
  width: 100%;
  background-color: white;
  z-index: 1;
`;
const FeedBackImg = styled.img`
  // height: 100%;
  // width: 100%;
  max-width: 100%;
  height: auto;
`;
const FeedBackLeftItem = styled(animated.div)`
  width: 100%;
  padding: 3rem 3.6rem 5rem 3.6rem;
  @media (max-width: 980px) {
    padding: 0;
  }
`;
const FeedBackLogo = styled.div`
  background-color: rgba(228, 239, 255, 0.61);
  width: 80px !important;
  height: 80px !important;
  padding: 23px;
  border-radius: 50%;
  margin-bottom: 30px;
  max-width: 100%;
`;
const Logo = styled.img`
  width: auto;
`;
const Heading = styled.h2`
  padding-bottom: 18px;
  font-size: 27px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
`;

const MoreButton = styled.div`
  width: 40%;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1150px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export default InstantFeedbackSection;
