import React from "react";
import PrivacyPolicy from "../../view/privacy-policy";

const PrivacyPolicyPage = () => {
  document.title = "Privacy Policy - ReviewGenie";

  return <PrivacyPolicy />;
};

export default PrivacyPolicyPage;
