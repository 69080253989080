import React, { useRef } from "react";
import Title from "./components/Title";
import SignatureCanvas from "react-signature-canvas";

const Signature = ({ block, blockIndex }) => {
  const sigCanvasRef = useRef(null);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <SignatureCanvas
        ref={sigCanvasRef}
        penColor="#6b49b3"
        backgroundColor="white"
        canvasProps={{ className: "sigCanvas" }}
      />
    </div>
  );
};

export default Signature;
