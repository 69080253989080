import React from "react";
import Header from "../common/header/Header";
import Heading from "./Heading";
import FeaturedCaseStudies from "./FeaturedCaseStudies";
import MoreCustomerStories from "./MoreCustomerStories";
import Award from "./Award";
import Intuitive from "./Intuitive";
import Teams from "./Teams";
import PrimalogikForFreeSection from "../common/PrimalogikForFreeSection";
import FooterSection from "../common/FooterSection";

const CustomerStories = () => {
  return (
    <>
      <Header />
      <Heading />
      <FeaturedCaseStudies />
      <MoreCustomerStories />
      <Award />
      <Intuitive />
      <Teams />
      <PrimalogikForFreeSection />
      <FooterSection />
    </>
  );
};

export default CustomerStories;
