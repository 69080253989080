import React from "react";
import { APP_NAME } from "../../constants";
import HelpCenter from "../../view/helpCenter";

const HelpCenterPage = () => {
  document.title = `Help Center -  ${APP_NAME}`;
  return <HelpCenter />;
};

export default HelpCenterPage;
