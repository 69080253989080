/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import {
  HEADQUARTERS_ADDRESS,
  NUMBER,
  OFFICE_DAYS,
  OFFICE_HOURS,
  SALES_EMAIL,
  SUPPORT_EMAIL,
} from "../../constants";

const Address = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Box>
          <LocationBox>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14678.131818616332!2d72.5409584!3d23.1141869!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e832045f78279%3A0xb8ea393f6d956dbf!2sInventionHill!5e0!3m2!1sen!2sin!4v1708670222292!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </LocationBox>
          <AddressBox>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Text
                style={{ fontSize: "20px", fontWeight: 400, color: "black" }}
              >
                Headquarters
              </Text>
              <Text>{HEADQUARTERS_ADDRESS}</Text>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Text
                style={{ fontSize: "20px", fontWeight: 400, color: "black" }}
              >
                Phone
              </Text>
              <Text>Phone : {NUMBER}</Text>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Text
                style={{ fontSize: "20px", fontWeight: 400, color: "black" }}
              >
                Office Hours
              </Text>
              <Text>{OFFICE_DAYS}</Text>
              <Text>{OFFICE_HOURS}</Text>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Text
                style={{ fontSize: "20px", fontWeight: 400, color: "black" }}
              >
                Sales & General Inquiries
              </Text>
              <Text>
                <Link
                  to={SALES_EMAIL}
                  style={{
                    color: "var(--color-primary)",
                    textDecoration: "none",
                  }}
                >
                  {SALES_EMAIL}
                </Link>
              </Text>
            </div>
          </AddressBox>
        </Box>
      </Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EndText>
          <Text>
            Already a customer? You can email your questions at
            <Link
              style={{
                color: "var(--color-primary)",
                textDecoration: "none",
                margin: "0 0.5rem",
              }}
              to={SUPPORT_EMAIL}
            >
              {SUPPORT_EMAIL}
            </Link>
            or check out the
            <Link
              style={{
                color: "var(--color-primary)",
                textDecoration: "none",
                margin: "0 0.5rem",
              }}
              to="/Help Center"
            >
              Help Center
            </Link>
            .
          </Text>
        </EndText>
      </div>
    </div>
  );
};

export default Address;

const Container = styled(animated.div)`
  max-width: 1080px;
  margin: 5rem auto;
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: #46586a;
  font-weight: 300;
`;

const Box = styled.div`
  display: flex;
  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const LocationBox = styled.div`
  padding: 1rem;
  margin-right: 10rem;
  @media (max-width: 980px) {
    margin-right: 0;
  }
  @media (max-width: 740px) {
    display: none;
  }
`;

const AddressBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-right: 10rem;
  gap: 2rem;
  @media (max-width: 980px) {
    padding-right: 0;
  }
  @media (max-width: 740px) {
    width: 90%;
  }
`;

const EndText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  @media (max-width: 740px) {
    width: 90%;
  }
`;
