import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const ContentItem = ({ link, heading, text }) => {
  return (
    <ItemBox>
      <ContentImg src={link} />
      <Text>
        <ContentHeading>{heading}</ContentHeading>
        <ContentText>{text}</ContentText>
      </Text>
    </ItemBox>
  );
};

const FeaturesSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Features style={props} ref={ref}>
        <Content>
          <ContentItem
            link="./image/performanceReviews/5.png"
            heading="Custom Questionnaires"
            text="Create tailored question forms for formal appraisals or check-ins. Empower managers with the flexibility to design their own questionnaires for productive 1-on-1 meetings. This helps in gathering insightful feedback and enhancing the appraisal process."
          />
          <ContentItem
            link="./image/performanceReviews/6.png"
            heading="Manager Journal"
            text="Maintain private notes on subordinates to track significant events throughout the year. Ensure fairness and completeness in your reviews by referencing these detailed notes. This journal aids in making informed decisions and fostering transparency."
          />
          <ContentItem
            link="./image/performanceReviews/7.png"
            heading="Check-Ins"
            text="Develop lightweight questionnaires for your 1-on-1 meetings. Discuss ongoing performance, provide constructive feedback, and keep everyone aligned on their goals. Regular check-ins help in maintaining a clear communication channel and boosting employee engagement."
          />
          <ContentItem
            link="./image/performanceReviews/8.png"
            heading="Appraisal Workflow"
            text="Define a clear assessment process. Initiate self-reviews, followed by manager reviews, to streamline and simplify performance evaluations. This workflow ensures a comprehensive evaluation system, making the appraisal process efficient and effective."
          />
          <ContentItem
            link="./image/performanceReviews/9.png"
            heading="Goals Evaluation"
            text="Assess an employee's goal progress by integrating them into the appraisal process. Rate existing goals directly on your questionnaires for precise evaluation. This method helps in tracking and achieving organizational and personal objectives."
          />
          <ContentItem
            link="./image/performanceReviews/10.png"
            heading="Signatures"
            text="Enable employees, managers, and ReviewGenie to sign off on performance reviews. Include additional comments discussed during the review and save them prior to signing. This feature ensures that all parties acknowledge and agree on the review outcomes, promoting accountability and clarity."
          />
        </Content>
      </Features>
    </div>
  );
};

const Features = styled(animated.div)`
  background-color: #ffffff !important;
  padding: 5.4rem 0;
`;

const Content = styled.div`
  width: 80%;
  max-width: 1080px;
  margin: auto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  @media (max-width: 980px) {
    width: 80%;
    display: block;
    align-items: left;
  }
  @media (max-width: 767px) {
    width: 80%;
    display: block;
  }
`;

const ItemBox = styled.div`
  position: relative;
  margin-bottom: 6rem;
  margin-right: 54px;
  @media (max-width: 980px) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 3rem;
  }
`;

const ContentImg = styled.img`
  width: 5rem;
  @media (max-width: 980px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 767px) {
  }
`;
const Text = styled.div`
  text-align: left;
  @media (max-width: 980px) {
    text-align: left;
  }
  @media (max-width: 767px) {
  }
`;
const ContentHeading = styled.h3`
  margin: 0;
  font-size: 30px;
  padding: 2rem 0;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  word-break: break-word;
  @media (max-width: 980px) {
    line-height: 1.8em;
  }
`;

const ContentText = styled.p`
  line-height: 1.7em;
  -webkit-font-smoothing: antialiased;
  display: contents;
  color: var(--color-subtext);
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  word-break: break-word;
  @media (max-width: 980px) {
  }
`;

export default FeaturesSection;
