import React from "react";

import AboutUs from "./AboutUs";
import ReviewGenie from "./ReviewGenie";
import Beliefs from "./Beliefs";
import Culture from "./Culture";
import LeadershipAndSocial from "./LeadershipAndSocial";
import FooterSection from "../common/FooterSection";
import Faqssection from "../home/Faqssection";
import PrimalogikSection from "../home/Primalogiksection";
import Header from "../common/header/Header";

const About = () => {
  return (
    <>
      <Header />
      <AboutUs />
      <ReviewGenie />
      <Beliefs />
      <Culture />
      <LeadershipAndSocial />
      <PrimalogikSection />
      <Faqssection />
      <FooterSection />
    </>
  );
};

export default About;
