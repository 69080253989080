import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";

const KeyFeaturesItem = ({ link, heading, text, url }) => {
  return (
    <KeyFeaturesGrid>
      <KeyFeaturesBlock>
        <KeyFeaturesBlockImg>
          <img src={link} alt="logo" />
        </KeyFeaturesBlockImg>

        <KeyFeaturesBlockHeading>{heading}</KeyFeaturesBlockHeading>
        <KeyFeaturesBlockText>{text}</KeyFeaturesBlockText>
        <KeyFeaturesLink>
          <Linked to={url}>Learn More</Linked>
        </KeyFeaturesLink>
      </KeyFeaturesBlock>
    </KeyFeaturesGrid>
  );
};
const KeyFeaturesSection = () => {
  return (
    <div>
      <KeyFeatures>
        <KeyFeaturesContent>
          <Text>
            <KeyFeaturesHeading>
              Uncover the Essential Features of Our ReviewGenie Software
            </KeyFeaturesHeading>
            <KeyFeaturesText>
              Enhance your workforce management with our ReviewGenie software.
              These vital features are designed to improve employee performance
              and streamline your ReviewGenie processes.
            </KeyFeaturesText>
          </Text>
        </KeyFeaturesContent>
        <KeyFeaturesBlockItem>
          <Carousel data-bs-theme="dark">
            <Carousel.Item>
              <KeyFeaturesItem
                link="./image/product/7.svg"
                heading="360 Degree Feedback"
                url="/360-degree-feedback"
                text="Achieve a comprehensive understanding of your employees' strengths and identify areas for growth by collecting feedback from all levels within your organization. Our 360-degree feedback surveys gather insights from peers, managers, direct reports, and subordinates, providing a well-rounded perspective."
              />
            </Carousel.Item>
            <Carousel.Item>
              <KeyFeaturesItem
                link="./image/product/8.svg"
                heading="Performance Reviews"
                url="/performance-reviews"
                text="Create and implement performance reviews that include both self-assessments and manager evaluations. Reflect on individual performance, recognize achievements, and identify opportunities for growth. Our tools help you build structured reviews that drive development and foster a culture of continuous improvement."
                backgroundImgLink="./image/home/KeyFeaturesSection/14-Performance-Review-300x190.png"
              />
            </Carousel.Item>
            <Carousel.Item>
              <KeyFeaturesItem
                link="./image/product/9.svg"
                heading="Instant Feedback"
                url="/instant-feedback"
                text="Encourage continuous engagement by promoting regular feedback within your organization. Recognize and praise individuals for their excellent work and provide constructive criticism in real-time. Our instant feedback tools help maintain a motivated and high-performing team, fostering a culture of continuous improvemen"
                backgroundImgLink="./image/home/KeyFeaturesSection/16-Instant-Feedback-300x190.png"
              />
            </Carousel.Item>
            <Carousel.Item>
              <KeyFeaturesItem
                link="./image/product/11.svg"
                heading="Engagement Surveys"
                url="/opinion-surveys"
                text="Before implementing new initiatives, understand your employees' experience to foster a positive organizational culture. Launch anonymous surveys to gather candid feedback and make data-driven decisions. Engage your workforce and drive meaningful change with our user-friendly survey tools."
                backgroundImgLink="./image/home/KeyFeaturesSection/17-Engagement-Survey-300x190.png"
              />
            </Carousel.Item>
            <Carousel.Item>
              <KeyFeaturesItem
                link="./image/product/10.svg"
                heading="Analytics & Reporting"
                url="/analytics-reporting-performance-management-software-review-genie"
                text="Unlock dynamic and easy-to-understand reports that provide a clear view of employee performance data. Filter through detailed metrics to gain actionable insights, and compare results over time to track individual growth effectively. Develop relevant action plans based on comprehensive data analysis to dri"
                backgroundImgLink="./image/home/KeyFeaturesSection/18-Analytic-Reporting-300x190.webp"
              />
            </Carousel.Item>
          </Carousel>
        </KeyFeaturesBlockItem>
      </KeyFeatures>
    </div>
  );
};

const KeyFeatures = styled.div`
  padding: 5.4rem 0 5rem 0;
  text-align: center;
  @media (max-width: 400px) {
    display: none;
  }
`;

const KeyFeaturesContent = styled.div`
  display: flex;
  width: 90%;
  max-width: 1100px;
  padding: 2rem 0;
  margin: 0 auto 1rem auto;
  justify-content: center;
  @media (max-width: 980px) {
    width: 80%;
    // flex-direction: column;
    display: block;
    align-items: center;
  }
`;
const Text = styled.div`
  text-align: center;
`;
const KeyFeaturesHeading = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 3.6rem;
  padding: 2rem 20rem;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;

  @media (max-width: 980px) {
    font-size: 5rem;
    padding: 2rem 9rem;
  }
  @media (max-width: 666px) {
    font-size: 4rem;
    padding: 2rem 2rem;
  }
`;

const KeyFeaturesText = styled.p`
  text-align: center;
  margin: 0;
  padding: 0 13rem;
  color: var(--color-subtext);
  font-size: 1.79rem;
  font-weight: 300;
  @media (max-width: 980px) {
    font-size: 2.8rem;
    padding: 0 9rem;
  }
  @media (max-width: 666px) {
    font-size: 3rem;
    padding: 0 5rem;
  }
`;
const KeyFeaturesBlockItem = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (max-width: 980px) {
    width: 80%;
    display: block;
  }
`;
const KeyFeaturesGrid = styled.div`
  text-align: center;
  padding: 3rem 8rem;
  width: 100%;
  display: flex;
  color: var(--color-primary-text);
  position: relative;
`;
const KeyFeaturesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3.6rem 5rem 3.6rem;
  text-align: left;
  @media (max-width: 980px) {
    padding: 5rem 10rem 8rem 10rem;
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 5rem 7rem 8rem 7rem;
    width: 100%;
  }
  @media (max-width: 530px) {
    padding: 5rem 5rem 8rem 5rem;
    width: 100%;
  }
  @media (max-width: 440px) {
    padding: 5rem 3rem 8rem 3rem;
    width: 100%;
  }
`;

const KeyFeaturesBlockImg = styled.div`
  width: 10%;
  background-color: var(--keyFeatures-logo-bg-color);
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 3rem;
  @media (max-width: 980px) {
    width: 15%;
    padding: 1rem;
    margin-bottom: 4rem;
  }
  @media (max-width: 750px) {
    width: 20%;
    padding: 1rem;
    margin-bottom: 4rem;
  }
  @media (max-width: 650px) {
    width: 30%;
    padding: 1rem;
    margin-bottom: 4rem;
  }
  @media (max-width: 550px) {
    width: 40%;
    padding: 1rem;
    margin-bottom: 4rem;
  }
`;

const KeyFeaturesBlockHeading = styled.div`
  margin: 0;
  font-size: 2.6rem;
  padding-bottom: 3rem;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  text-align: center;

  @media (max-width: 980px) {
    font-size: 3.5rem;
    padding-bottom: 2rem;
  }
`;

const KeyFeaturesBlockText = styled.div`
  text-align: center;
  padding-bottom: 1.5rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1.8em;

  @media (max-width: 980px) {
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }
`;

const KeyFeaturesLink = styled.div`
  // width: 35%;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  margin-top: 2rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 980px) {
    font-size: 1rem;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export default KeyFeaturesSection;
