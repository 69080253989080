import React from "react";
import Header from "../common/header/Header";
import FooterSection from "../common/FooterSection";
import Content from "./Content";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Content />
      <FooterSection />
    </>
  );
};

export default PrivacyPolicy;
