/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const BoxItem = ({ heading, text }) => {
  return (
    <Item>
      <ItemImg>
        <span style={{ display: "block", margin: "auto" }}>
          <ItemDot>
            <input type="checkbox" checked />
          </ItemDot>
        </span>
      </ItemImg>
      <ItemText>
        <Title>{heading}</Title>
        <SubText>{text}</SubText>
      </ItemText>
    </Item>
  );
};

const EfficientlySection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Content>
          <Heading>
            Efficiently Manage Your 360 Degree Feedback Process with Review
            Genie
          </Heading>
          <Text>
            Monitor the progress of all ongoing 360 feedback surveys directly
            from Invention Hill. Track participant rates and automate email
            reminders to ensure timely responses. Streamline your feedback
            management with ReviewGenie's comprehensive tools.
          </Text>
        </Content>
      </Container>
      <ForImage style={props} ref={ref}>
        <ImageContainer>
          <Img src="./image/degreeFeedback/360_Degree.jpg" />
        </ImageContainer>
      </ForImage>
      <Box style={props} ref={ref}>
        <BoxContent>
          <BoxItem
            heading="Launching Surveys"
            text="Launch surveys individually or use our batch launch option to notify participants in a single email of the surveys they need to complete."
          />
          <BoxItem
            heading="Custom Emails"
            text="You can easily customize email content to include details about your company’s 360 degree feedback process, tips or clarifications about how the feedback will be used."
          />
          <BoxItem
            heading="Automatic Reminders"
            text="As a survey deadline approaches, participants who have not yet submitted their feedback will receive automatic email reminders from the App."
          />
        </BoxContent>
        <BoxContent>
          <BoxItem
            heading="Suggesting Participants"
            text="Give employees the opportunity to request 360 degree feedback from additional coworkers. Activate an approval workflow if needed."
          />
          <BoxItem
            heading="Real-Time Tracking"
            text="ReviewGenie keeps track of participation rates and lets you see the number of surveys completed by each individual."
          />
          <BoxItem
            heading="Nudge Reminders"
            text="At any given time, ReviewGenie admin scan send additional reminders to users who haven’t submitted their responses yet."
          />
        </BoxContent>
      </Box>
    </div>
  );
};

const Container = styled(animated.div)`
  // width: 80%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  only screen and (min-width: 1350px) {
    padding: 27px 0;
  }
  @media (max-width: 980px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Content = styled.div`
  font-size: 18px;
  padding: 0 128px !important;
  text-align: center;
  word-wrap: break-word;
  @media (max-width: 980px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left;
  }
`;

const Heading = styled.h1`
  padding: 20px 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-weight: 300;
  color: #46586a;
`;

const ForImage = styled(animated.div)`
  width: 30%;
  margin: auto;
  position: relative;
  padding-bottom: 10px;
  @media (max-width: 980px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
  @media (max-width: 980px) {
    width: 80%;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
`;

const Box = styled(animated.div)`
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
  padding: 27px 0;

  @media (max-width: 980px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const BoxContent = styled.div`
  display: flex;
  @media (max-width: 980px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Item = styled.div`
  padding-right: 4rem;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  position: relative;
  word-wrap: break-word;
  width: 100%;
  margin-bottom: 30px;
`;

const ItemImg = styled.div`
  width: 32px;
  line-height: 0;
`;
const ItemDot = styled.span`
  font-size: 15px;
  color: var(--color-primary);
  font-weight: 400;
  line-height: 1;
`;
const ItemText = styled.div`
  text-align: left;
  padding-left: 15px;
  vertical-align: top;
  word-wrap: break-word;
`;
const Title = styled.h3`
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4em;
  color: #161a24;
`;
const SubText = styled.p`
  font-size: 16px;
  font-weight: 300;
`;
export default EfficientlySection;
