import React from "react";
import styled from "styled-components";
import { TOLL_FREE_NUMBER } from "../../constants";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ContactNo = ({ handleClose }) => {
  return (
    <div style={{ maxWidth: "500px", margin: "0 auto" }}>
      <Container>
        <Detail>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px 10px 0 0",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ fontSize: "20px" }} />
            </IconButton>
          </div>
          <TitleBox>
            <Title>Contact Us:</Title>
          </TitleBox>
          <Box>
            <P>
              <span style={{ opacity: 0.7 }}>Phone: </span>
              <span style={{ color: "var(--color-primary)" }}>
                {TOLL_FREE_NUMBER}
              </span>
            </P>
          </Box>
          {/* <Box>
            <P>
              <span style={{ opacity: 0.7 }}>Email: </span>
              <span style={{ color: "var(--color-primary)" }}>
                {SUPPORT_EMAIL}
              </span>
            </P>
          </Box> */}
          <SupportHour>
            <P>
              <span style={{ opacity: 0.7 }}>Support Hours:</span>{" "}
              <span style={{ color: "var(--color-primary)" }}>
                9am to 7pm (IST), Monday to Friday
              </span>
            </P>
          </SupportHour>
        </Detail>
      </Container>
    </div>
  );
};

export default ContactNo;

const Container = styled.div`
  position: relative;
`;

const Detail = styled.div`
  background-color: #f8f8f8;
  backdrop-filter: blur(10px);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
`;

const TitleBox = styled.div`
  padding: 20px 40px 20px 40px;
`;

const Title = styled.h2`
  color: black;
  font-weight: 600;
`;

const Box = styled.div`
  display: flex;
  padding: 0 40px 15px 40px;
`;

const P = styled.p`
  color: black;
  font-size: 1.5rem;
  font-weight: 600;
`;

const SupportHour = styled.div`
  padding: 0 40px 40px 40px;
`;
