import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top/index.js";
import Admin from "./pages/Admin";
import AboutPage from "./pages/about/About";
import AllTemplatePage from "./pages/all-template/index.js";
import AnalyticsReportingPage from "./pages/analytics-reporting";
import BlogView from "./pages/blog/BlogView";
import ContactPage from "./pages/contact";
import CustomerStoriesPage from "./pages/customer-stories";
import DegreeFeedbackPage from "./pages/degree-feedback.js";
import DemoRequest from "./pages/demo-request.js/index.js";
import ForgotPasswordPage from "./pages/forgot-password/index.js";
import GoalsAndOKRs from "./pages/goals-and-okrs/index.js";
import HelpCenter from "./pages/help-center/index.js";
import InstantFeedback from "./pages/instant-feedback/index.js";
import OpinionSurveys from "./pages/opinion-surveys/index.js";
import PerformanceReviews from "./pages/performance-reviews/index.js";
import Pricing from "./pages/pricing/index.js";
import PrivacyPolicyPage from "./pages/privacy-policy/index.js";
import Product from "./pages/product/index.js";
import TemplatePage from "./pages/template/index.js";
import TermsOfServicePage from "./pages/terms-of-service/index.js";
import Home from "./view/home/index.js";
import BlogPage from "./pages/blog-view/BlogPage.js";
import Error404 from "./pages/error/404.js";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/contact-us" Component={ContactPage} />
        <Route path="/about" Component={AboutPage} />
        <Route path="/blog" Component={BlogView} />
        <Route path="/blog-read" Component={BlogPage} />
        <Route path="/customers" Component={CustomerStoriesPage} />
        <Route path="/help-center" Component={HelpCenter} />
        <Route path="/forgot-password" Component={ForgotPasswordPage} />
        <Route path="/product" Component={Product} />
        <Route path="/360-degree-feedback" Component={DegreeFeedbackPage} />
        <Route path="/performance-reviews" Component={PerformanceReviews} />
        <Route
          path="/analytics-reporting-performance-management-software-review-genie"
          Component={AnalyticsReportingPage}
        />
        <Route path="/goals" Component={GoalsAndOKRs} />
        <Route path="/instant-feedback" Component={InstantFeedback} />
        <Route path="/opinion-surveys" Component={OpinionSurveys} />
        <Route path="/pricing" Component={Pricing} />
        <Route path="/demo-request" Component={DemoRequest} />
        <Route path="/admin" Component={Admin} />
        <Route path="/terms-of-service" Component={TermsOfServicePage} />
        <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
        <Route path="/template" Component={TemplatePage} />
        <Route path="/all-templates" Component={AllTemplatePage} />
        <Route path="*" Component={Error404} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
