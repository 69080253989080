import React from "react";
import Header from "../common/header/Header";
import Heading from "./Heading";
import Content from "./Content";
import FooterSection from "../common/FooterSection";

const HelpCenter = () => {
  return (
    <>
      <Header />
      <Heading />
      <Content />
      <FooterSection />
    </>
  );
};

export default HelpCenter;
