import React, { useRef, useState } from "react";
import styled from "styled-components";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const Blog = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Heading>Stay Updated with the ReviewGenie Blog</Heading>
        <SubHeading>
          Dive into the latest trends and best practices in surveys, feedback
          systems, and workplace insights directly from ReviewGenie. Stay
          informed with expert insights to optimize your feedback strategies and
          drive meaningful improvements.
        </SubHeading>
      </Container>
      <Contain style={props} ref={ref}>
        <Blogs>
          <BlogBox>
            <ImgBox>
              <Link to="/blog/neurodiversity-in-the-workplace-embracing-diversity-enhancing-performance">
                <Image
                  src="/image/home/BlogSection/blog3.jpg"
                  alt="Blog1"
                ></Image>
              </Link>
            </ImgBox>
            <Title>Performance Review Phrases: 100 Helpful Examples</Title>
            <Text>
              Strong performance review phrases are an integral part of
              performance reviews. These types of reviews provide a chance for
              an in-depth conversation about …
            </Text>
            <MoreButton>
              <Linked to="/blog">Read more</Linked>
            </MoreButton>
          </BlogBox>
          <BlogBox>
            <ImgBox>
              <Link to="/blog/360-reviews-ultimate-guide-free-template">
                <Image
                  src="/image/home/BlogSection/blog2.jpg"
                  alt="Blog1"
                ></Image>
              </Link>
            </ImgBox>
            <Title>360 Reviews: The Ultimate Guide (Free Template)</Title>
            <Text>
              Considering launching a 360 review process—or upgrading your
              current one? This can feel daunting, but it doesn’t have to. We’re
              about to take an in-depth look at the 360 review…
            </Text>
            <MoreButton>
              <Linked to="/blog">Read more</Linked>
            </MoreButton>
          </BlogBox>
          <BlogBox>
            <ImgBox>
              <Link to="/blog/performance-review-phrases-100-examples">
                <Image
                  src="/image/home/BlogSection/blog1.jpg"
                  alt="Blog1"
                ></Image>
              </Link>
            </ImgBox>
            <Title>Performance Review Phrases: 100 Helpful Examples</Title>
            <Text>
              Strong performance review phrases are an integral part of
              performance reviews. These types of reviews provide a chance for
              an in-depth conversation about …
            </Text>
            <MoreButton>
              <Linked to="/blog">Read more</Linked>
            </MoreButton>
          </BlogBox>
        </Blogs>
      </Contain>
    </div>
  );
};

export default Blog;

const Container = styled(animated.div)`
  width: 70%;
  margin: 10rem auto 7rem auto;
  @media (max-width: 980px) {
    margin-top: 0;
  }
`;

const Heading = styled.div`
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 2rem;
`;

const SubHeading = styled.div`
  font-size: 2rem;
  font-weight: 300;
  opacity: 0.8;
  text-align: center;
  @media (max-width: 980px) {
    font-size: 2.5rem;
  }
`;

const Contain = styled(animated.div)`
  width: 80%;
  margin: 0 auto 18rem auto;
  @media (max-width: 980px) {
    width: 70%;
  }
`;

const Blogs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  @media (max-width: 890px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const BlogBox = styled.div`
  width: 90%;
  @media (max-width: 980px) {
    width: 90%;
  }
`;

const ImgBox = styled.div`
  width: 100%;
`;

const Title = styled.div`
  padding: 2.5rem 0 1rem 0;
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 980px) {
    font-size: 3rem;
  }
`;

const Text = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  opacity: 0.9;
  @media (max-width: 980px) {
    font-size: 2.5rem;
  }
`;

const MoreButton = styled.div`
  width: 38%;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  margin-top: 2rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1345px) {
    width: 50%;
  }
  @media (max-width: 1030px) {
    width: 70%;
    padding: 0.6rem 1rem;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
    margin: 15px 0 25px 0;
    width: 50%;
    padding: 0.8rem 1.5rem;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

const Image = styled.img`
  border-radius: 15px;
  width: 100%;
`;
