import React from "react";
import styled from "styled-components";

const Text = (props) => {
  return <StyledText {...props}>{props.children}</StyledText>;
};

const StyledText = styled.p((props) => ({
  fontFamily: "Poppins, Helvetica, Arial, Lucida, sans-serif",
  margin: props.margin || "0",
  padding: props.padding,
  fontSize: props.fontSize || "15px",
  fontWeight: props.fontWeight,
  color: props.color,
  textAlign: props.textAlign || "left",
  lineHeight: props.lineHeight,
  wordBreak: "break-word",
  marginTop: props.marginTop,
  marginBottom: props.marginBottom,
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  paddingTop: props.paddingTop,
  paddingBottom: props.paddingBottom,
  paddingLeft: props.paddingLeft,
  paddingRight: props.paddingRight,
}));

export default Text;
