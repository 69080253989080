import React from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, IconButton } from "@mui/material";
import styled from "styled-components";

const developmentFaq = [
  {
    question: "Who should use ReviewGenie?",
    answer:
      "ReviewGenie is perfect for freelancers, agencies, business owners, bloggers, designers, developers, photographers, and basically everyone else. If you want to create a custom WordPress form, then you need to use ReviewGenie. Our mission is to empower freelancers and agencies to create advanced, solution-focused forms with confidence.",
  },
  {
    question: "What's required to use ReviewGenie?",
    answer:
      "ReviewGenie is a WordPress Plugin. In order to use ReviewGenie, you must have a WordPress site. That's all",
  },
  {
    question: "Do I need coding skills to use ReviewGenie?",
    answer:
      "Absolutely not. You can create and manage forms without any coding knowledge. ReviewGenie is the most advanced WordPress form solution in the market, so if you want to change your form HTML, you have access to it. ReviewGenie Views allow you to use your form data without any PHP, but HTML skills are helpful. With Formidable Views, you get a low-code platform for creating applications.",
  },
  {
    question: "Will ReviewGenie slow down my website?",
    answer:
      "Absolutely not. ReviewGenie is carefully built with performance in mind. We have developed everything with best practices and modern standards to ensure things run smooth and fast.",
  },
  {
    question: "Which Software Development Methodology you follow?",
    answer:
      "Being a software development firm that works with thousands of global businesses integrating modern technology, our primary preference is working with the Agile Methodology – because it helps integrating wondrous possibilities of new technologies. However, our engineers are proficient in waterfall technologies too and can develop your software on any methodology as requested.",
  },
  {
    question: "Can I use ReviewGenie on client sites?",
    answer:
      "Yes, you can use ReviewGenie on client sites in two ways. Purchase the Business/Elite license to use Formidable on unlimited sites. Or purchase a license for each client site. (Yes, you can refer them and earn 20% commission through our affiliate program).    ",
  },
];
const DevelopmentFaq = () => {
  const [open, setOpen] = React.useState(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {developmentFaq.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            active={open === index}
            onClick={() => setOpen(open === index ? null : index)}
          >
            <p
              style={{
                marginLeft: "15px",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {item.question}
            </p>
            <IconButton>
              {open === index ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse
            in={open === index}
            timeout="auto"
            unmountOnExit
            style={{
              maxWidth: "75%",
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                padding: "10px 30px 20px 10px",
              }}
            >
              {item.answer}
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};
const Faqssection = () => {
  return (
    <>
      <Container>
        <TitleBox>
          <Title>Frequently Asked Questions & Resource Services</Title>
          <Text>
            Have a question about ReviewGenie? Check out our comprehensive FAQ
            list below for answers to the most common questions. If your
            question isn't listed, feel free to contact us for more information.
          </Text>
        </TitleBox>
      </Container>
      <div style={{ maxWidth: "80%", margin: "0 auto " }}>
        <DevelopmentFaq />
      </div>
    </>
  );
};

export default Faqssection;

const Container = styled.div`
  max-width: 55%;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.p`
  color: Black;
  border-bottom: 3px solid var(--color--primary-hover);
  font-weight: 700;
  font-size: 28px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
  @media (max-width: 350px) {
    font-size: 20x;
  }
`;

const Text = styled.p`
  color: Black;
  font-size: 20px;
  padding: 5px 0 60px 0;
  opacity: 0.8;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 15px;
  }
  @media (max-width: 350px) {
    font-size: 15px;
  }
`;

const Box = styled.div`
  width: 80%;
  border: 1px solid ${({ active }) => (active ? "transparent" : "#dddddd")};
  background-color: ${({ active }) => (active ? "#f1f1f1" : "white")};
  color: black;
  padding: 12px;
  border-radius: ${({ active }) => (active ? "5px" : "5px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin-bottom: 0;
  }
`;
