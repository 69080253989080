export const APP_NAME = "ReviewGenie";
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const BACKEND_API = BACKEND_API_URL + "/api";
export const EMAIL = "info@reviewgenie.com";
export const NUMBER = "+91 99095 95299";
export const SUPPORT_EMAIL = "support@reviewgenie.com";
export const TOLL_FREE_NUMBER = "+91 99095 95299";
export const HEADQUARTERS_ADDRESS =
  "B-1203, Ganesh Glory 11, Jagatpur road, Sarkhej - Gandhinagar Hwy, Ahmedabad, Gujarat 382481";
export const OFFICE_DAYS = "Monday to Friday";
export const OFFICE_HOURS = "10am to 7pm";
export const SALES_EMAIL = "info@reviewgenie.com";
