import React from "react";
import Text from "./Text";

const Title = ({ label, description, required, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <Text>
          {blockIndex + 1} . {label}
        </Text>
        {required && <Text color="red">*</Text>}
      </div>
      {description && <Text fontSize="1rem">{description}</Text>}
    </div>
  );
};

export default Title;
