import React from "react";
import Title from "./components/Title";
import Text from "./components/Text";

const Name = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
            }}
          >
            <Text fontSize="13px">{block.settings.subLabels.firstName}</Text>
            {block.settings.required.firstName && <Text color="red">*</Text>}
          </div>
          <input
            style={{
              height: " 30px",
              fontSize: "15px",
              width: "100%",
            }}
            type="text"
            placeholder={block.settings.placeholders.firstName}
          ></input>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
            }}
          >
            <Text fontSize="13px">{block.settings.subLabels.firstName}</Text>
            {block.settings.required.firstName && <Text color="red">*</Text>}
          </div>
          <input
            style={{
              height: " 30px",
              fontSize: "15px",
              width: "100%",
            }}
            type="text"
            placeholder={block.settings.placeholders.lastName}
          ></input>
        </div>
      </div>
      {/* <input
        style={{
          height: " 30px",
          fontSize: "15px",
          width: "100%",
        }}
        type="text"
        placeholder={block.settings.placeholders}
      ></input> */}
    </div>
  );
};

export default Name;
