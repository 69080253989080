import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Card = ({ data }) => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Box style={props} ref={ref}>
        {data?.map((item, index) => (
          <Item key={index}>
            <Link to={`/blog-read#${item.slug}`}>
              <Img src={item.imageUrl} />
            </Link>
            <Text>
              <path
                style={{
                  fontWeight: 900,
                  fontSize: "18px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                {item.title}
              </path>
            </Text>
            {/* <Text style={{ color: "var(--color-primary" }}>
              {item?.categories?.map((category, index) => (
                <Link
                  style={{
                    color: "var(--color-primary",
                    textDecoration: "none",
                  }}
                  to={category.link}
                >
                  {category.name},
                </Link>
              ))}
            </Text> */}
            <Text style={{ color: "#434D5B", fontWeight: 300 }}>
              {item.description}
            </Text>
            <Text>
              <Linked to={`/blog-read#${item.slug}`}>
                <Button>read more</Button>
              </Linked>
            </Text>
          </Item>
        ))}
      </Box>
    </div>
  );
};

export default Card;

const Box = styled(animated.div)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

// const CenterBox = styled.div`
//   width: 30%;
//   display: flex;
//   gap: 15px;
//   flex-direction: column;
//   @media (max-width: 990px) {
//     width: 80%;
//   }
// `;

// const RightBox = styled.div`
//   width: 30%;
//   display: flex;
//   gap: 15px;
//   flex-direction: column;
//   @media (max-width: 990px) {
//     width: 80%;
//   }
// `;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  // word-wrap: break-word;
  &:hover {
    opacity: ${(props) => (props.hover ? "0.7" : "1")};
    cursor: ${(props) => (props.hover ? "pointer" : "default")};
  }
`;

const Img = styled.img`
  width: 250px;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
`;

const Button = styled.button`
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  background-color: white;
  color: var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1130px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
    margin: 15px 0 25px 0;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;
