import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const Beliefs = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <Container ref={inViewRef}>
      <Box>
        <RightBox>
          <Img src="./image/about/belief.png" />
        </RightBox>
        <LeftBox style={props} ref={ref}>
          <Text
            style={{ marginTop: "3rem", fontSize: "30px", fontWeight: 600 }}
          >
            Our Beliefs and Guiding Principles
          </Text>
          <Text
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              fontSize: "16px",
              fontWeight: 100,
            }}
          >
            At Our Organization, our vision, mission, and values are designed to
            reflect the best interests of our customers, employees, and
            community. These core principles drive our growth, shape our
            business decisions, define our corporate culture, and measure our
            success. We are committed to living our values through our choices,
            words, and actions every day.
          </Text>
          <Link to="/demo-request">
            <Button>Learn more</Button>
          </Link>
        </LeftBox>
      </Box>
    </Container>
  );
};

export default Beliefs;

const Container = styled.div`
  padding-top: 10rem;
  max-width: 1080px;
  margin: 0 auto;
  @media (max-width: 990px) {
    padding: 0;
  }
`;

const Box = styled.div`
  display: flex;
  margin-top: 5rem;
  width: 100%;
  @media (max-width: 1230px) {
    padding: 0 6rem;
  }
  @media (max-width: 990px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const LeftBox = styled(animated.div)`
  margin-left: 1rem;
  width: 50%;
  @media (max-width: 990px) {
    width: 80%;
  }
`;
const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
  line-height: 1.5;
  margin: 0;
`;

const RightBox = styled.div`
  text-align: center;
  width: 50%;
  background-color: white;
  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
  }
`;

const Img = styled.img`
  width: 85%;
  @media (max-width: 990px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const Button = styled.button`
  padding: 0.8rem 1.5rem;
  background-color: ${(props) =>
    props.backColor ? props.backColor : "transparent"};
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  color: var(--color-primary);
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 990px) {
    font-size: 3rem;
    padding: 0.5rem 1.7rem;
  }
  @media (max-width: 540px) {
    font-size: 2.3rem;
    padding: 0.8rem 1.7rem;
  }
`;
