import {
  Box,
  Checkbox,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import Text from "./components/Text";
import Title from "./components/Title";

const Matrix = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {block.settings.columns.map(({ label, id }) => (
                <TableCell key={id}>
                  {block.settings.answerType === "RADIO" ||
                  block.settings.answerType === "CHECKBOX" ? (
                    <Text fontSize="12px">{label}</Text>
                  ) : (
                    <Text textAlign="center" fontSize="12px">
                      {label}
                    </Text>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {block.settings.rows.map(({ label, id: rowId }, rowIndex) => (
              <TableRow key={rowId}>
                <TableCell>{<Text fontSize="12px">{label}</Text>}</TableCell>
                {block.settings.columns.map(({ id: colId }, colIndex) => (
                  <TableCell key={colId}>
                    {block.settings.answerType === "RADIO" ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio />
                      </Box>
                    ) : block.settings.answerType === "CHECKBOX" ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox />
                      </Box>
                    ) : block.settings.answerType === "TEXT_BOX" ? (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Input type="text"></Input>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Input type="text"></Input>
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Matrix;

const Input = styled.input`
  height: 30px;
  font-size: 15px;
  width: 100%;
`;
