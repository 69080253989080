import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LogInHeader = () => {
  return (
    <NavContainer>
      <NavLogo>
        <Link to="/">
          <Logo src="./Review-Genie-Logo-Two.png"></Logo>
        </Link>
      </NavLogo>
      <NavMenu>
        {/* <NavItem>
          <i
            className="fa-solid fa-globe"
            style={{ color: "#626262", marginRight: "1rem" }}
          ></i>
          <Text>Language</Text>
        </NavItem> */}
        <NavItem>
          <Link to="/help-center">
            <i
              className="fa-solid fa-circle-question"
              style={{
                color: "#626262",
                marginRight: "1rem",
              }}
            ></i>
          </Link>
        </NavItem>
      </NavMenu>
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  position: sticky;
  top: 0;
  background-color: white; /* Add your desired background color */
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: black;
  @media screen and (max-width: 800px) {
    padding: 2rem;
  }
`;

const NavLogo = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
`;
const Logo = styled.img`
  padding: 0 1rem;
  width: 250px;
  // @media screen and (max-width: 800px) {
  //   padding: 0 2rem;
  // }
`;
// const Text = styled.span`
//   font-size: 1.4rem;
//   @media screen and (max-width: 800px) {
//     display: none;
//   }
// `;
const NavMenu = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
`;

const NavItem = styled.li`
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 2.2rem;
  taxt-align: center;
  align-items: center;
  color: #626262;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`;

export default LogInHeader;
