import React from "react";
import { APP_NAME } from "../../constants";
import Blog from "../../view/blog-view";
import { useLocation } from "react-router-dom";

const BlogPage = () => {
  document.title = `Blog - ${APP_NAME}`;
  const location = useLocation();
  return <Blog slug={location.hash.replace("#", "")} />;
};

export default BlogPage;
