import React from "react";
import Header from "../common/header/Header";
import GetStarted from "./GetStarted";
import Templates from "./Templates";
import FooterSection from "../common/FooterSection";
import PrimalogikSection from "../home/Primalogiksection";
import Faqssection from "../home/Faqssection";
// import SurveyProjects from "./SurveyProjects";

const AllTemplate = () => {
  return (
    <>
      <Header />
      <GetStarted />
      <Templates />
      {/* <SurveyProjects /> */}
      <PrimalogikSection />
      <Faqssection />
      <FooterSection />
    </>
  );
};

export default AllTemplate;
