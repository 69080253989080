/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const FeedbackSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Feedback style={props} ref={ref}>
        <WorkForce>
          <Box>
            <BoxLeftItem>
              <Title>360 Degree Feedback</Title>
              <Heading>Gain valuable insights with 360 degree feedback</Heading>
              <Text>
                Collect reliable data with 360 degree feedback from multiple
                sources. Easily identify key strengths, uncover blindspots, and
                find areas for potential growth.
              </Text>
              <Btn>
                <Link to="/demo-request">
                  <ReqButton variant="tertiary">REQUEST A DEMO</ReqButton>
                </Link>
                <Link to="/all-templates">
                  <ReqButton variant="secondary">Explore templates </ReqButton>
                </Link>
              </Btn>
            </BoxLeftItem>
          </Box>
        </WorkForce>
      </Feedback>
    </div>
  );
};

const Feedback = styled(animated.div)`
  background-image: url(./image/product/Product_Bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 16rem;
  @media (max-width: 1420px) {
    padding: 0 5rem;
  }
`;

const WorkForce = styled.div`
  padding-top: 14rem;
  padding-bottom: 9rem;
  margin-bottom: 1px;
  bottom: auto;
  @media (max-width: 980px) {
    padding: 0;
    margin-bottom: 4px;
  }
`;

const Box = styled.div`
  height: 40vh;
  padding: 10rem 0 10rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 1420px) {
    height: 50vh;
  }
  @media (max-width: 980px) {
    height: 60vh;
  }
  @media (max-width: 480px) {
    height: 70vh;
  }
  @media (max-width: 375px) {
    height: 80vh;
  }
`;
const BoxLeftItem = styled.div`
  @media (max-width: 980px) {
    padding-right: 0;
  }
  @media (max-width: 480px) {
    margin: 0 0 5.6rem 0;
  }
`;
const Title = styled.h4`
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  padding: 2rem;
  text-align: center;
  margin: 3rem 0 0.4rem 0;
  word-wrap: break-word;
  @media (max-width: 980px) {
    margin: 0;
    padding-bottom: 1rem;
  }
`;
const Heading = styled.h1`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 40px;
  color: #000000;
  text-align: center;
  line-height: 1.4em;
  font-weight: 600;
  padding-bottom: 10px;
  margin: 0;
  @media (max-width: 980px) {
    font-size: 25px;
  }
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 2.1rem;
  font-weight: 400;
  color: black;
  opacity: 0.8;
  line-height: 2em;
  text-align: center;
  padding: 2.3rem 0;
  margin: 0;
  @media (max-width: 980px) {
    padding: 2rem;
  }
`;
const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  text-align: center;
  width: 100%;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
  }
`;
const ReqButton = styled(Button)`
  font-size: 1.5rem;
  border-radius: 25px;
  padding: 1.5rem 2rem;
  margin: 5px;
  font-weight: 100;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.5rem 2rem;
  }
`;

export default FeedbackSection;
