/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
function LogInFooter() {
  return (
    <div>
      <Footer>
        <UlText>
          <LiText style={{ marginRight: "1rem" }}>
            © 2023 ReviewGenie Software Solutions Inc.
          </LiText>
          <LiText>
            <span style={{ marginRight: "1rem" }}>·</span>
            <Link
              to="/terms-of-service"
              style={{ marginRight: "1rem", color: "var(--primary-color)" }}
            >
              Terms of Service
            </Link>
          </LiText>
          <LiText>
            <span style={{ marginRight: "1rem" }}>·</span>
            <Link
              to="/privacy-policy"
              style={{ marginRight: "1rem", color: "var(--primary-color)" }}
            >
              Privacy Policy
            </Link>
          </LiText>
        </UlText>
      </Footer>
    </div>
  );
}
const Footer = styled.div`
  text-align: center;
  font-size: 12px;
  color: #707070;
  display: block;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  border-top: 1px solid #e5e5e5;
  padding-top: 12px;
`;
const UlText = styled.ul`
  margin: 0;
  display: block;
  font-size: 0;
  list-style: none;
  padding: 0;
`;
const LiText = styled.li`
  display: inline-block;
  font-size: 12px;
  padding: 0;
  white-space: nowrap;
  line-height: 25px;
  page-break-inside: avoid;
`;
export default LogInFooter;
