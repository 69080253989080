/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { animated, useInView, useSpring } from "react-spring";

const PrimalogikSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 500 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef} style={{ margin: "2rem 0 10rem 0" }}>
      <ClientsDetails style={props} ref={ref}>
        <ClientsItem>
          <ClientsHeading>
            Client Success Stories with ReviewGenie Surveys
          </ClientsHeading>
        </ClientsItem>
        <ClientsItem>
          <ClientsText>
            Our clients appreciate the seamless experience and powerful features
            that ReviewGenie offers. Here are a few reasons why organizations
            choose us
          </ClientsText>
        </ClientsItem>
      </ClientsDetails>
      <PrimalogikTeam style={props} ref={ref}>
        <PrimalogikTeamDetails>
          <PrimalogikTeamCard>
            <CardContent>
              <Star>
                {[1, 2, 3, 4, 5].map((index) => (
                  <StarImage
                    key={index}
                    src="./image/home/PrimalogikSection/Icons_star-filled-color.svg"
                    alt={`Star ${index}`}
                  />
                ))}
              </Star>
              <CardText>
                Our website stayed up. The form stayed up. And everything
                worked. It was absolutely dreamy.
              </CardText>
              <TeamMemberName>Diane Dickinson</TeamMemberName>
            </CardContent>
          </PrimalogikTeamCard>
          <PrimalogikTeamCard>
            <CardContent>
              <Star>
                {[1, 2, 3, 4, 5].map((index) => (
                  <StarImage
                    key={index}
                    src="./image/home/PrimalogikSection/Icons_star-filled-color.svg"
                    alt={`Star ${index}`}
                  />
                ))}
              </Star>
              <CardText>
                This is really great. The interface is very good, setting up a
                form takes seconds.
              </CardText>
              <TeamMemberName>Chad Bauer</TeamMemberName>
            </CardContent>
          </PrimalogikTeamCard>
          <PrimalogikTeamCard>
            <CardContent>
              <Star>
                {[1, 2, 3, 4, 5].map((index) => (
                  <StarImage
                    key={index}
                    src="./image/home/PrimalogikSection/Icons_star-filled-color.svg"
                    alt={`Star ${index}`}
                  />
                ))}
              </Star>
              <CardText>
                I don’t know of a company like this that is willing to spend
                over 30 minutes making a client happy!
              </CardText>
              <TeamMemberName>Ava Chan</TeamMemberName>
            </CardContent>
          </PrimalogikTeamCard>
        </PrimalogikTeamDetails>
      </PrimalogikTeam>
    </div>
  );
};

const ClientsDetails = styled(animated.div)`
  @media (max-width: 980px) {
    padding-top: 1rem;
  }
`;

const ClientsItem = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 980px) {
    width: 80%;
    padding: 1rem 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ClientsHeading = styled.h2`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.4em;
  font-size: 3.2rem;
  padding: 3rem 0;
  -webkit-font-smoothing: antialiased;
    font-weight: 600;
    margin: 0;
    @media (max-width: 980px) {
    font-size: 4rem;
    text-align: center;
  }
}
`;

const ClientsText = styled.p`
  font-size: 1.5rem;
  font-weight: 100;
  padding: 0 0 4rem 0;
  line-height: 1.8em;
  @media (max-width: 980px) {
    font-size: 2.5rem;
  }
`;

const PrimalogikTeam = styled(animated.div)`
  color: var(--color-text);
`;

const PrimalogikTeamDetails = styled.div`
  width: 90%;
  max-width: 1100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  padding: 2.7rem 0;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 980px) {
    width: 75%;
    display: block;
    align-items: left;
  }
`;

const PrimalogikTeamCard = styled.div`
  padding: 3rem 2.5rem;
  background-color: var(--primalogikteamcard-bg-color);
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0px 16px 48px 0px rgba(26, 39, 81, 0.1);
  @media (max-width: 980px) {
    margin: 9rem 0;
    padding: 5rem 4.5rem 7rem 4.5rem;
  }
`;

const CardContent = styled.div`
  color: black;
`;

const TeamMemberName = styled.h3`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  padding-top: 1.5rem;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  @media (max-width: 980px) {
    padding-top: 2.5rem;
  }
`;

const CardText = styled.p`
  font-family: Poppins, sans-serif;
  color: var(--color-subtext);
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.35px;
  font-weight: 300;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 16px;
    width: 82%;
    text-align: center;
    margin: 0 auto;
  }
`;

const Star = styled.p`
  padding-bottom: 0;
  font-weight: 400;
  margin: 0;
`;

const StarImage = styled.img`
  height: 2rem;
  width: 2rem;
  @media (max-width: 980px) {
    height: 3rem;
    width: 3rem;
  }
`;

export default PrimalogikSection;
