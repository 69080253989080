import React from "react";
import CustomerStories from "../../view/customerStories";
import { APP_NAME } from "../../constants";

const CustomerStoriesPage = () => {
  document.title = `Customer Stories - ${APP_NAME}`;
  return <CustomerStories />;
};

export default CustomerStoriesPage;
