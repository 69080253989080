/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const TrackGoalsSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container>
        <Box>
          <LeftItem>
            <BG>
              <BGImg src="./image/goalsandOKRs/6.webp"></BGImg>
            </BG>
            <FrontImg>
              <Img>
                <Image src="./image/goalsandOKRs/7.webp"></Image>
              </Img>
            </FrontImg>
          </LeftItem>
          <RightItem style={props} ref={ref}>
            <Heading>Track goals for individual employees and teams</Heading>
            <Text>
              Goals allow people to measure their progress, and measuring
              progress greatly boosts morale. With ReviewGenie, you can view the
              status of an employee’s goals and monitor the progress of entire
              teams. You can then use these insights to let people know if they
              are on the right track and provide vital guidance along the way.
            </Text>
            <Text>
              <Link
                to="/demo-request"
                style={{
                  color: "var(--color-link-text)",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                Learn More
              </Link>
            </Text>
          </RightItem>
        </Box>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding-top: 6rem;
  padding-bottom: 8rem;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 480px) {
    width: 80%;
  }
`;
const LeftItem = styled.div`
  width: 48.5%;
  margin-right: 32px;
  margin-bottom: 16px;
  z-index: 1;
  @media (max-width: 980px) {
    width: 100%;
    margin: 0 auto;
  }
`;
const BG = styled.div`
  display: inline-block;
  position: relative;
  max-width: 100%;
`;
const BGImg = styled.img`
  max-width: 100%;
  height: auto;
`;

const FrontImg = styled.div`
  margin-top: -326px !important;
  margin-left: 0px !important;
  width: 100%;
  transform: translateX(91px) translateY(63px);
  text-align: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Img = styled.span`
  display: inline-block;
  position: relative;
  max-width: 100%;
`;

const Image = styled.img`
  position: relative;
  max-width: 100%;
  height: auto;
`;
const RightItem = styled(animated.div)`
  width: 48.5%;
  margin: auto;
  padding-left: 100px;
  @media (max-width: 980px) {
    width: 100%;
    padding-left: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Heading = styled.h2`
  padding: 2rem 0;
  font-size: 34px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
  word-wrap: break-word;
`;

export default TrackGoalsSection;
