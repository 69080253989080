import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import { APP_NAME } from "../../constants";

const LeadershipAndSocial = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <Container ref={inViewRef}>
      <div>
        {/* <div style={{ maxWidth: "1080px", margin: "0 auto" }}> */}
        <Box style={props} ref={ref}>
          <LeftBox>
            <Text style={{ fontSize: "35px", textAlign: "center" }}>
              Leadership
            </Text>
            <Text
              style={{
                fontSize: "20px",
                marginTop: "1rem",
                marginBottom: "1rem",
                fontWeight: 100,
              }}
            >
              Meet the executive team driving {APP_NAME} forward. Our leaders
              are committed to innovation, excellence, and guiding our company
              towards success.
            </Text>
            <Link to="/demo-request">
              <Button backColor="var(--color-primary)">Take a tour</Button>
            </Link>
          </LeftBox>
          <RightBox>
            <Text style={{ fontSize: "35px", textAlign: "center" }}>
              Social Impact
            </Text>
            <Text
              style={{
                fontSize: "20px",
                marginTop: "1rem",
                marginBottom: "1rem",
                fontWeight: 100,
              }}
            >
              Explore how {APP_NAME} gets involved with our communities. Our
              social impact initiatives are designed to make a positive
              difference.
            </Text>
            <Link to="/demo-request">
              <Button backColor="var(--color-primary)">Learn More</Button>
            </Link>
          </RightBox>
        </Box>
      </div>
    </Container>
  );
};

export default LeadershipAndSocial;

const Container = styled.div``;

const Box = styled(animated.div)`
  display: flex;
  margin-top: 5rem;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 990px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const LeftBox = styled.div`
  padding: 8rem 10rem;
  text-align: center;
  width: 50%;
  background-color: rgb(255, 227, 219);
  @media (max-width: 990px) {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: black;
  line-height: 1.5;
  text-align: left;
  margin: 0;
`;

const RightBox = styled.div`
  padding: 8rem 10rem;
  text-align: center;
  width: 50%;
  background-color: rgb(188, 218, 255);
  @media (max-width: 990px) {
    width: 100%;
  }
`;

const Button = styled.button`
  padding: 1rem 2rem;
  background-color: ${(props) =>
    props.backColor ? props.backColor : "transparent"};
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  line-height: 1.5;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
