import React from "react";
import Header from "../common/header/Header";
import PerformanceReviewsSection from "./PerformanceReviewsSection";
import EmployeeRelationshipSection from "./EmployeeRelationshipSection";
import MFCSection from "./MFCSection";
import FeaturesSection from "./FeaturesSection";
import PerformanceSection from "./PerformanceSection";
// import PrimalogikForFreeSection from "../common/PrimalogikForFreeSection";
import DiscoverMoreSection from "./DiscoverMoreSection";
import FooterSection from "../common/FooterSection";
import PrimalogikSection from "../home/Primalogiksection";
import GetStartedSection from "../product/GetStartedSection";
import Faqssection from "../home/Faqssection";
import OrganizationSection from "../product/OrganizationSection";

const PerformanceReviews = () => {
  return (
    <div>
      <Header />
      <PerformanceReviewsSection />
      <OrganizationSection />
      <EmployeeRelationshipSection />
      <GetStartedSection />
      <MFCSection />
      <FeaturesSection />
      <DiscoverMoreSection />
      <PerformanceSection />
      {/* <PrimalogikForFreeSection /> */}
      <PrimalogikSection />
      <Faqssection />
      <FooterSection />
    </div>
  );
};

export default PerformanceReviews;
