import React from "react";
import Header from "../common/header/Header";
import FeedbackSection from "./FeedbackSection";
import ProvideMFSection from "./ProvideMFSection";
import SupportsProfessionalSection from "./SupportsProfessionalSection";
import TailoredSection from "./TailoredSection";
import EfficientlySection from "./EfficientlySection";
import GetClearSection from "./GetClearSection";
import DiscoverMoreSection from "../performanceReviews/DiscoverMoreSection";
import FooterSection from "../common/FooterSection";
import PrimalogikSection from "../home/Primalogiksection";
import GetStartedSection from "../product/GetStartedSection";
import Faqssection from "../home/Faqssection";
import OrganizationSection from "../product/OrganizationSection";

const DegreeFeedback = () => {
  return (
    <div>
      <Header />
      <FeedbackSection />
      <OrganizationSection />
      <ProvideMFSection />
      <SupportsProfessionalSection />
      <TailoredSection />
      <GetStartedSection />
      <EfficientlySection />
      <DiscoverMoreSection />
      <GetClearSection />
      {/* <ClientsLoveSection /> */}
      {/* <PrimalogikForFreeSection /> */}
      <PrimalogikSection />
      <Faqssection />
      {/* <SubscribeSection /> */}
      <FooterSection />
    </div>
  );
};

export default DegreeFeedback;
