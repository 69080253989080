import React from "react";
import ForgotPassword from "../../view/forgot-password";
import { APP_NAME } from "../../constants";

const ForgotPasswordPage = () => {
  document.title = `Forgot Password - ${APP_NAME}`;
  return <ForgotPassword />;
};

export default ForgotPasswordPage;
