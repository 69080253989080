/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring, config } from "react-spring";
import styled from "styled-components";

const FeedbackSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef} id="1">
      <FeedBack>
        <FeedBackBox>
          <FeedBackLeftItem>
            <FeedBackImg src="./image/degreeFeedback/Survey_Results.png"></FeedBackImg>
          </FeedBackLeftItem>
          <FeedBackRightItem style={props} ref={ref}>
            <FeedBackLogo>
              <Logo src="./image/product/7.svg"></Logo>
            </FeedBackLogo>
            <Heading>360° Feedback</Heading>
            <Text>
              Gain a comprehensive view of your employees’ overall contributions
              by gathering feedback from peers, managers, direct reports, and
              subordinates. Our 360-degree feedback tool helps you:
            </Text>
            <ul>
              <Text>
                <li>Identify key strengths</li>
              </Text>
              <Text>
                <li> Uncover blind spots within your workforce</li>
              </Text>
              <Text>
                <li>
                  {" "}
                  Provide individuals with a deeper understanding of their skill
                  sets and areas for improvement
                </li>
              </Text>
            </ul>
            <MoreButton>
              <Linked to="/360-degree-feedback">LEARN MORE</Linked>
            </MoreButton>
          </FeedBackRightItem>
        </FeedBackBox>
      </FeedBack>
    </div>
  );
};

const FeedBack = styled.div`
  padding-bottom: 4rem;
  @media (max-width: 980px) {
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;
const FeedBackBox = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  @media (max-width: 540px) {
    width: 100%;
  }
`;
const FeedBackLeftItem = styled.div`
  margin-right: 3.2rem;
  width: 100%;
  background-color: white;
  z-index: 1;
`;
const FeedBackImg = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 980px) {
    padding: 0 5rem;
  }
`;
const FeedBackRightItem = styled(animated.div)`
  width: 100%;
  padding: 3rem 3.6rem 5rem 3.6rem;
  @media (max-width: 980px) {
    padding: 5rem;
  }
`;

const FeedBackLogo = styled.div`
  background-color: rgba(228, 239, 255, 0.61);
  width: 80px !important;
  height: 80px !important;
  padding: 23px;
  border-radius: 50%;
  margin-bottom: 30px;
  max-width: 100%;
`;
const Logo = styled.img`
  width: auto;
`;
const Heading = styled.h2`
  padding-bottom: 18px;
  font-size: 27px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
`;

const MoreButton = styled.div`
  width: 38%;
  text-align: center;
  font-size: 1.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1130px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
    margin: 15px 0 25px 0;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export default FeedbackSection;
