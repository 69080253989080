/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
import Button from "../../components/button";
import { renderBody } from "./components/renderBody";

const SurveySection = (templates) => {
  const data = templates.templates;

  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Feedback style={props} ref={ref}>
        <WorkForce>
          <BoxLeftItem>
            <Title>survey</Title>
            <Heading>{data?.name}</Heading>
            <Text>{data?.description}</Text>
            <Div>
              <Btn>
                <ReqButton
                  variant="tertiary"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_ADMIN_URL}/template#${data?.slug}`)
                  }
                >
                  Try This Template
                </ReqButton>
              </Btn>
              <Btn>
                <Link to="/all-templates">
                  <ReqButton
                    style={{
                      background: "white",
                      color: "black",
                    }}
                    variant="secondary"
                  >
                    See all Template
                  </ReqButton>
                </Link>
              </Btn>
            </Div>
          </BoxLeftItem>
          <BoxRightItem>
            <div
              style={{
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              <SurveyBox>
                <div
                  style={{
                    margin: "10px",
                  }}
                >
                  {data?.body.sections.map((item, sectionIndex) => (
                    <form key={sectionIndex}>
                      <h1
                        style={{
                          margin: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.title}
                      </h1>
                      {item.blocks.map((block, blockIndex) => (
                        <div
                          style={{
                            margin: "2rem 4rem",
                          }}
                        >
                          {block && renderBody(block, blockIndex, sectionIndex)}
                        </div>
                      ))}
                    </form>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "30px",
                  }}
                >
                  <p> Powered by </p>
                  <Img src="Review-Genie-Logo-Two.png" />
                  <p style={{ marginTop: "25px" }}>Privacy & Cookie Notice</p>
                </div>
              </SurveyBox>
              <Btn style={{ width: "100%" }}>
                <ReqButton
                  style={{ width: "100%", borderRadius: "0px" }}
                  variant="tertiary"
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_ADMIN_URL}/template#${data?.slug}`)
                  }
                >
                  Try This Template
                </ReqButton>
              </Btn>
            </div>
          </BoxRightItem>
        </WorkForce>
      </Feedback>
    </div>
  );
};

export default SurveySection;

const Feedback = styled(animated.div)`
  background-color: rgb(247, 247, 247);
`;

const WorkForce = styled.div`
  padding-top: 7rem;
  padding-bottom: 9rem;
  margin-bottom: 1px;
  bottom: auto;
  @media (max-width: 980px) {
    padding: 0;
    margin-bottom: 4px;
  }
`;

const BoxLeftItem = styled.div`
  // padding-right: 3rem;
  margin: 0 0 1.6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./image/product/Product_Bg.jpg");
  background-size: cover;
  // @media (max-width: 980px) {
  //   padding-right: 0;
  //   width: 90%;
  // }
  // @media (max-width: 480px) {
  //   margin: 0 0 5.6rem 0;
  // }
`;

const Title = styled.h4`
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  min-height: 17px;
  padding: 2rem 0 2rem 0;
  margin: 7rem 0 0.4rem 0;
  word-wrap: break-word;
  @media (max-width: 980px) {
    margin: 0;
    padding-bottom: 1rem;
  }
`;

const Heading = styled.h1`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 32px;
  color: #000000;
  line-height: 1.4em;
  font-weight: 900;
  padding-bottom: 10px;
  word-wrap: break-word;
`;

const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #23282d;
  line-height: 1.8em;
  padding-bottom: 2.1rem;
  margin: 0;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 3.2rem;
    padding-bottom: 2.5rem;
  }
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
`;

const ReqButton = styled(Button)`
  font-size: 1.5rem;
  padding: 1.3rem 2rem;
  border-radius: 28px;
  font-weight: 100;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1.5rem 2rem;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const BoxRightItem = styled.div`
  width: 80%;
  max-width: 800px;
  margin: auto;
  @media (max-width: 980px) {
    width: 90%;
  }
`;

const Img = styled.img`
  width: 100px;
`;

const SurveyBox = styled.div`
  overflow-y: scroll;
  height: 500px;
  @media (max-width: 400px) {
    width: 300px;
  }
  @media (max-width: 325px) {
    width: 250px;
  }
`;

const Div = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 10rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

