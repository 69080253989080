import React from "react";
import Title from "./components/Title";
import Text from "./components/Text";
import styled from "styled-components";

const Address = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <FlexBox
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <Box>
          <FlexBox>
            <Text fontSize="13px">
              {block.settings.subLabels.streetAddress}
            </Text>
            {block.settings.required.streetAddress && (
              <Text color="red">*</Text>
            )}
          </FlexBox>
          <Input
            style={{
              height: " 30px",
              fontSize: "15px",
              width: "100%",
            }}
            type="text"
            placeholder={block.settings.placeholders.streetAddress}
          ></Input>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <FlexBox>
            <Text fontSize="13px">
              {block.settings.subLabels.streetAddress2}
            </Text>
            {block.settings.required.streetAddress2 && (
              <Text color="red">*</Text>
            )}
          </FlexBox>
          <Input
            style={{
              height: " 30px",
              fontSize: "15px",
              width: "100%",
            }}
            type="text"
            placeholder={block.settings.placeholders.streetAddress2}
          ></Input>
        </Box>
      </FlexBox>
      <FlexBox>
        <Box>
          <FlexBox>
            <Text fontSize="13px">{block.settings.subLabels.city}</Text>
            {block.settings.required.city && <Text color="red">*</Text>}
          </FlexBox>
          <Input
            style={{
              height: " 30px",
              fontSize: "15px",
              width: "100%",
            }}
            type="text"
            placeholder={block.settings.placeholders.city}
          ></Input>
        </Box>
        <Box>
          <FlexBox>
            <Text fontSize="13px">{block.settings.subLabels.state}</Text>
            {block.settings.required.state && <Text color="red">*</Text>}
          </FlexBox>
          <Input
            type="text"
            placeholder={block.settings.placeholders.state}
          ></Input>
        </Box>
      </FlexBox>
      <FlexBox>
        <Box>
          <FlexBox>
            <Text fontSize="13px">{block.settings.subLabels.postal}</Text>
            {block.settings.required.postal && <Text color="red">*</Text>}
          </FlexBox>
          <Input
            style={{
              height: " 30px",
              fontSize: "15px",
              width: "100%",
            }}
            type="text"
            placeholder={block.settings.placeholders.postal}
          ></Input>
        </Box>
        <Box>
          <FlexBox>
            <Text fontSize="13px">{block.settings.subLabels.country}</Text>
            {block.settings.required.country && <Text color="red">*</Text>}
          </FlexBox>
          <Input
            type="text"
            placeholder={block.settings.placeholders.country}
          ></Input>
        </Box>
      </FlexBox>
    </div>
  );
};

export default Address;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const Input = styled.input`
  height: 30px;
  font-size: 15px;
  width: 100%;
`;
