import React, { useState } from "react";
import styled from "styled-components";
import TeamPlans from "./TeamPlans";
import Individual from "./Individual";
import Enterprise from "./Enterprise";

const Tab = () => {
  const [activeTab, setActiveTab] = useState("Team-Plans");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Box>
        <Heading>Choose a plan that works for you</Heading>
        <FlexBox>
          <TabTitle
            onClick={() => handleTabClick("Team-Plans")}
            active={activeTab === "Team-Plans"}
          >
            <Text>Team Plans</Text>
          </TabTitle>
          <TabTitle
            onClick={() => handleTabClick("Individual-Plans")}
            active={activeTab === "Individual-Plans"}
          >
            <Text>Individual Plans</Text>
          </TabTitle>
          <TabTitle
            onClick={() => handleTabClick("Enterprise")}
            active={activeTab === "Enterprise"}
          >
            <Text>Enterprise</Text>
          </TabTitle>
        </FlexBox>
      </Box>
      {activeTab === "Team-Plans" && <TeamPlans />}
      {activeTab === "Individual-Plans" && <Individual />}
      {activeTab === "Enterprise" && <Enterprise />}
    </>
  );
};

export default Tab;

const Box = styled.div`
  padding-top: 10rem;
  padding-bottom: 4rem;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 980px) {
    width: 90%;
  }
`;

const Heading = styled.h2`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  font-size: 4rem;
  text-align: center;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

const TabTitle = styled.div`
  margin: 2rem;
  padding: 0.8rem;
  border-bottom: ${(props) =>
    props.active ? "2px solid var(--color-primary)" : "none"};
  color: ${(props) => (props.active ? "var(--color-primary)" : "black")};
  font-weight: 600;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  @media (max-width: 980px) {
    font-size: 12px;
  }
`;
