import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";

const Content = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div>
      <Box ref={inViewRef}>
        <Item ref={ref} style={props}>
          <Title>Terms of Service</Title>
        </Item>
      </Box>
      <Container>
        <Text>Last updated: October 15, 2019</Text>
        <Text>
          These terms of service (the “Terms of Service”, “Terms”) govern the
          use of, and the subscription to, the hosted software service and tools
          (the “Primalogik Service” or “Service”), as owned and operated by
          Primalogik Software Solutions Inc. (“Primalogik”), a corporation
          having its registered address at 2001 Boul. Robert-Bourassa, Suite
          1700 Montreal, Quebec, Canada, H3A 2A6 (referred to in these Terms as
          “we”, “us” or the “Company”). If you do not agree to all terms of this
          agreement, you may not use the Service.
        </Text>
        <Text>
          By accessing or using the Primalogik Service, You represent, warrant
          and signify that (a) You have read, understood and agree to be bound
          by these Terms as they may be amended from time to time; and (b) You
          have read and understand our Privacy Policy, which can be accessed at
          <A to="/privacy">https://primalogik.com/privacy</A> (the "Privacy
          Policy"), the terms of which are incorporated herein by reference, and
          agree to abide by the Privacy Policy.
        </Text>
        <Text>
          By signing up for the Service on behalf of a company, organization or
          entity (“Customer”), you confirm that (a) you are duly authorized to
          represent such entity, (b) you accept the terms of this agreement on
          behalf of such entity, and (c) any references to “you” in these Terms
          of Service refer to such company, organization or entity and all of
          its users, employees, consultants and agents.
        </Text>
        <SubTitle>1. DEFINITIONS</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol style={{ margin: "0px" }}>
            <Li>
              <Text style={{ margin: 0 }}>
                The following capitalized terms shall have the meaning ascribed
                to them below:
              </Text>
            </Li>
          </Ol>
          <div style={{ marginLeft: "4rem" }}>
            <Ol>
              <Li>
                <Text style={{ margin: 0 }}>
                  “Parties” means (i) Primalogik and Customer; or (ii)
                  Primalogik and a User, as the case may be;
                </Text>
              </Li>
              <Li>
                <Text style={{ margin: 0 }}>
                  “User” means, as the case may be, any user of the Service or
                  any individual to whom Customer provides access to the
                  Service, including any account administrators, managers,
                  employees, consultants and survey participants;
                </Text>
              </Li>
              <Li>
                <Text style={{ margin: 0 }}>
                  “Customer Data” means any content that Customer submits or
                  transfers to Primalogik in conjunction with the Services,
                  including customized questions, answers to surveys,
                  assessments, comments, notes, images and files uploaded;
                </Text>
              </Li>
              <Li>
                <Text style={{ margin: 0 }}>
                  “Website” means the public website available at primalogik.com
                  and related websites provided by Primalogik (for example,
                  help.primalogik.com).
                </Text>
              </Li>
            </Ol>
          </div>
        </div>

        <SubTitle>2. USE OF THE PRIMALOGIK SERVICE</SubTitle>
        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Services.</strong> During the Term (as defined in
                Section 7.1 (Term)), Primalogik grants to Customer and its users
                a limited, worldwide, non-exclusive, non-sublicensable,
                non-transferable right to access and use the Primalogik Service,
                and any related services, in accordance with the Terms of
                Service.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Service Modifications.</strong> Primalogik shall offer
                the Services materially in accordance with the description on
                its Website and related documentation. Primalogik may revise the
                content, features and functions of the Primalogik Service at any
                time without notice. Primalogik will provide Customer with prior
                notice if there is a change to the Primalogik Service resulting
                in overall material decrease in the purpose of the Service. If
                such material decrease occurs, and Customer is materially
                impacted in its use of the Service, Customer may terminate its
                subscription in accordance with Section 7.2.2.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Temporary Suspension.</strong> Primalogik may
                temporarily limit or suspend the Services from time to time,
                including to perform upgrades and/or maintenance of the Service.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Unacceptable Use.</strong> You agree to use our Website,
                Service, and products only for lawful purposes. You shall not,
                and shall not agree to, and shall not authorize, encourage or
                permit any third party to:
              </Text>
            </Li>

            <div style={{ marginLeft: "4rem" }}>
              <Ol>
                <Li>
                  <Text style={{ margin: 0 }}>
                    use the Service in any manner that breaches any applicable
                    local, national or international law or regulation;
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    use the Service to threaten, defame, bully, harass, or harm
                    another person or entity;
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    use the Service for any fraudulent or inappropriate purpose,
                    or in a manner for which it is not intended to be used (as
                    determined by Primalogik in its sole discretion);
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    in any way damage, disable, overburden, and/or impair the
                    Service server, or any network connected to the Service,
                    and/or interfere with any other party’s use or enjoyment of
                    the Service;
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    use the Service to upload, transmit or otherwise distribute
                    any content that may be abusive, defamatory, misleading,
                    fraudulent, pornographic, otherwise explicit in nature,
                    contains viruses, or is otherwise objectionable as
                    reasonably determined by Primalogik;
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    reproduce, duplicate, copy, sell, resell or exploit any
                    portion of the Service; or
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    rent, lease, distribute, or resell the Software, or access
                    or use the Software or Services for developing a competitive
                    solution (or contract with a third party to do so), or
                    remove or alter any of the logos, trademark, patent or
                    copyright notices, confidentiality or proprietary legends or
                    other notices or markings that are on or in the Software or
                    displayed in connection with the Service.
                  </Text>
                </Li>
              </Ol>
            </div>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Account Access.</strong> Customer shall take all
                reasonable steps to prevent unauthorized access to the Service,
                including by protecting their passwords and other user
                authentication information. Customer is responsible for any
                activity occurring in its account (other than activity that
                Primalogik is directly responsible for and is not performed in
                accordance with Customer’s instructions), whether or not
                Customer authorized that activity. Customer shall immediately
                notify Primalogik if it becomes aware of any unauthorized access
                to, or use of, its account.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Compliance with Laws.</strong> You agree to use the
                Service only in compliance with all applicable laws, rules and
                regulations, including Privacy Laws.
              </Text>
            </Li>
          </Ol>
        </div>
        <SubTitle>3. SERVICE FEES OF THE PRIMALOGIK SERVICE</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Service Fees.</strong>Service Fees. In consideration for
                the Services, Customer shall pay Primalogik, at the beginning of
                the subscription term, the fees specified on the “subscription”
                section of the Service, for the subscription term (whether
                monthly or annual) selected by Customer, unless other payment
                terms have been agreed to in writing, for instance in a quote,
                between Customer and Primalogik (the “Service Fees”). Customer’s
                subscription will be automatically renewed on the first day
                following the expiration of a subscription term (the “Renewal
                Date”) for the same subscription term as the then expiring
                subscription term, unless it cancels its subscription before the
                Renewal Date in accordance with Section 7.2.1. Only for an
                annual subscription term, Primalogik will send to Customer a
                notice of renewal approximately 30 days before the Renewal Date,
                and thus if Customer no longer wishes to renew the Services,
                Customer is responsible for timely cancelling its subscription
                in accordance with Section 7.2.1. Unless otherwise agreed in
                writing by the Parties, the Service Fees applicable to any such
                renewal shall be Primalogik’s then current standard Service
                Fees.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Change in the Subscription.</strong> The Service Fees
                are determined in accordance with the maximum number of Users
                authorized to access and use the Service. If Customer wishes to
                increase the number of such authorized Users, Customer may do so
                in the following manner:
              </Text>
            </Li>
            <div style={{ marginLeft: "4rem" }}>
              <Ol>
                <Li>
                  <Text style={{ margin: 0 }}>
                    Customer may at any time increase the number of authorized
                    Users through the “subscription” section of the Service or
                    with a Primalogik customer representative. Any incremental
                    Service Fees associated with such increase of authorised
                    paid Users shall be prorated over the remaining period of
                    Customer’s then current subscription term, charged to
                    Customer account and due and payable upon implementation of
                    such increase of authorised paid Users.
                  </Text>
                </Li>
              </Ol>
            </div>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>No Refunds.</strong> Except as expressly otherwise
                provided herein, payments are non-refundable and there are no
                refunds or credits for partially used periods or number of
                users.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Free trial.</strong> If Customer registers for a free
                trial for the Services, Primalogik will make such Services
                available to Customer on a trial basis, until the earlier of (i)
                the end of the free trial period applicable to Customer
                (generally 30 days after the start of the free trial); (ii) the
                start date of any subscription purchased by Customer for such
                Services; or (iii) termination of the trial at any time by
                Primalogik or Customer in its sole discretion.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Taxes.</strong> All fees are exclusive of all taxes or
                duties imposed by governing authorities. Other than sales taxes
                which Primalogik may be required to collect from you and remit
                to the appropriate taxing authorities, you are solely
                responsible for payment of all such taxes or duties.
              </Text>
            </Li>
          </Ol>
        </div>

        <SubTitle>4. DATA</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Customer Data.</strong> Customer represents and warrants
                that it owns or has obtained all necessary rights, title and
                interest, and obtained all necessary consents to transfer
                Customer Data in relation with the Terms of Service, and that
                Customer Data doesn’t infringe any third party intellectual
                property rights. Customer grants Primalogik a worldwide,
                non-exclusive, perpetual and royalty-free right to access, use,
                process, copy, distribute, perform, export and display Customer
                Data, only to the extent permitted by law and as reasonably
                necessary (i) to provide, maintain and improve the Services;
                (ii) to prevent or address service, security, support or
                technical issues; or (iii) to create anonymized data non-related
                to a specific Customer, which does not constitute Customer Data
                and is owned by Primalogik, including for use as benchmarking
                and marketing purposes in accordance with the Privacy Policy or
                as otherwise expressly permitted in writing by Customer or as
                required by law.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Protection of Customer Data.</strong> Primalogik shall
                store and process Customer Data in accordance with the Privacy
                Policy. Primalogik has implemented technical, organizational and
                administrative systems, policies and procedures as well as other
                measures detailed in the Privacy Policy to help ensure the
                security, integrity, availability and confidentiality of
                Customer Data, and to mitigate the risk of unauthorized access
                to or use of Customer Data. Nonetheless, hosting data online
                involves risks of unauthorized disclosure, loss or exposure and,
                in accessing and using the Service, Customer assumes such risks,
                except for gross negligence or unlawful conduct of Primalogik.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Confidential Information.</strong> “Confidential
                Information” means any non-public, confidential or sensitive
                information, including Customer Data, disclosed by a Party or on
                its behalf (the “Disclosing Party”) to the other Party (the
                “Receiving Party”), and excludes any information that is:
              </Text>
            </Li>
            <div style={{ marginLeft: "4rem" }}>
              <Ol>
                <Li>
                  <Text style={{ margin: 0 }}>
                    subject to applicable Privacy Laws, publicly available or
                    later becomes publicly available other than through a breach
                    of the Terms of Service;
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    as evidenced by documentary and competent evidence: (a)
                    known to the Receiving Party or its employees, agents or
                    representatives prior to such disclosure or (b) without
                    using the Confidential Information, is independently
                    developed by the Receiving Party or its employees, agents or
                    representatives subsequent to such disclosure; or
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    as evidenced by documentary and competent evidence,
                    subsequently lawfully obtained by the Receiving Party or its
                    employees, agents or representatives from a third party
                    without obligations of confidentiality, provided that such
                    source is not, to the knowledge of the Receiving Party, in
                    breach of its obligations of non-disclosure towards the
                    Disclosing Party.
                  </Text>
                </Li>
              </Ol>
            </div>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Use or Disclosure of Confidential Information.</strong>{" "}
                The Receiving Party shall only use or disclose Confidential
                Information to exercise its rights and fulfill its
                responsibilities under the Terms of Service. The Receiving Party
                shall exercise the same degree of care and protection with
                respect to the Confidential Information that it exercises with
                respect to its own confidential information and in any event, at
                least diligent and prudent care. The Receiving Party shall not
                directly or indirectly disclose, copy, distribute, republish, or
                allow any third party to have access to any Confidential
                Information, except that Primalogik may disclose Confidential
                Information to its third party service providers in connection
                with the performance or the improvement of the Services, in
                which case Primalogik will ensure that the third party maintains
                reasonable data practices for maintaining the confidentiality
                and security of the Confidential Information and preventing
                unauthorized access. Notwithstanding the foregoing, the
                Receiving Party may disclose Confidential Information as
                required by applicable law or by proper legal or governmental
                authority. The Receiving Party shall give the Disclosing Party
                prompt notice of any such legal or governmental demand and
                reasonably cooperate with the Disclosing Party in any effort to
                seek a protective order or otherwise to contest such required
                disclosure, at the Disclosing Party’s expense.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>GDPR.</strong> The Parties may be subject to additional
                terms and conditions under the General Data Protection
                Regulation (Regulation (EU) 2016/679). Such terms may be found
                in the Data Processing Addendum, which is hereby incorporated by
                reference and make part of the Terms of Service, to the extent
                applicable.
              </Text>
            </Li>
          </Ol>
        </div>

        <SubTitle>5. PRIMALOGIK’s WARRANTIES AND DISCLAIMERS</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Service Warranty.</strong> Primalogik represents,
                warrants, and covenants as follows: (a) the Service will perform
                substantially in accordance with the specifications generally
                provided by Primalogik in connection with the Service
                (“Documentation”); (b) the provision of the Service will comply
                with all privacy and data protection laws applicable to its
                business; (c) it will not sell Personal Information provided by
                you, and it will retain, disclose, or use Personal Information
                provided by you only for purposes of providing the Service; and
                (d) any professional services performed for you by Primalogik
                will be performed in a professional and workmanlike manner, with
                the degree of skill and care that is required by sound
                professional procedures and practices.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Warranty Disclaimers.</strong> To the maximum extent
                permitted by applicable law, except for the limited warranties
                provided above, Primalogik disclaims all other warranties,
                either express or implied, including implied warranties of
                merchantability or fitness for a particular purpose with respect
                to the Service, software, documentation, deliverables and other
                materials and/or services. Primalogik does not warrant that
                operation of the Service will be error-free or uninterrupted.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Liability Cap.</strong> Except for intentional or gross
                fault of Primalogik or for the indemnification for intellectual
                property, infringement referred to in section 8.2
                (indemnification), in no event shall Primalogik’s aggregate,
                cumulative liability of proven direct damages arising out of or
                related to the Terms of Service, exceed the total amount paid
                and payable, if any, by Customer hereunder in the 12 months
                preceding the last event giving rise to liability. The existence
                of more than one claim shall not enlarge or extend this limit.
                Parties understand that the essential purpose of this section is
                to allocate the risks under this agreement between the Parties
                and limit potential liability given the service fees, which
                would have been substantially higher if Primalogik were to
                assume any further liability other than as set forth herein.
                Primalogik has relied on these limitations in determining
                whether to provide the rights to access and use the Website or
                Services.
              </Text>
            </Li>
          </Ol>
        </div>

        <SubTitle>6. INTELLECTUAL PROPERTY</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>No Rights granted.</strong> Primalogik retains all
                right, title, and interest in and to the Website and the Service
                and the content Customer accesses through the Website and the
                Service, other than Customer Data. The Terms of Service do not
                grant Customer any intellectual property rights in or to the
                Website or the Service or in Primalogik’s logos and other
                trademarks. Customer or Users shall not remove, alter, or
                obscure any copyright, trademark or other proprietary notices
                appearing in or on the Website or the Service.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Feedback.</strong> You have no obligation to give
                Primalogik any suggestions, enhancement requests,
                recommendations, comments or other feedback (“Feedback”)
                relating to the Service. To the extent you provide any Feedback
                to Primalogik, Primalogik may use and implement any such
                Feedback in any manner, including but not limited to future
                enhancements and modifications to the Website or the Service.
                Accordingly, if you provide Feedback, you agree that Primalogik
                shall own all such Feedback and Primalogik and its affiliates,
                licensees, clients, partners, third-party providers and other
                authorized entities may freely use, reproduce, license,
                distribute, and otherwise commercialize the Feedback in the
                Service or other related technologies, and you hereby assign all
                rights in such Feedback to Primalogik. In addition, Primalogik
                shall be free to reuse all general knowledge, experience,
                know-how, works and technologies, including ideas, concepts,
                processes and techniques, related to or acquired during
                provision of the Services.
              </Text>
            </Li>
          </Ol>
        </div>

        <SubTitle>7. TERM AND TERMINATION</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Term.</strong> The Terms are effective on the date that
                You access the Primalogik Service and until all subscriptions
                terms to the Service have expired or until they are terminated
                by any or both Parties in accordance with Section 7.2
                (Termination);
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Termination.</strong> The Terms of Service may be
                terminated:
              </Text>
            </Li>
            <div style={{ marginLeft: "4rem" }}>
              <Ol>
                <Li>
                  <Text style={{ margin: 0 }}>
                    by Customer at any time (for monthly subscription you can do
                    so after 3 months of continuous subscription without
                    penalty) by sending an email to
                    <A to="mailto:primalogik">support@primalogik.com </A>and
                    completing the cancellation confirmation steps with a
                    Primalogik customer representative.
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    by the Parties at any time if the other Party materially
                    breaches any of its obligations under the Terms of Service.
                    If the material breach is curable, the Terms of Service may
                    only be terminated if such breach is not cured within 15
                    days after a Party provides notice of the breach to the
                    other Party.
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    by Primalogik if Customer fails to make any payment of the
                    Service Fees within 30 days after Primalogik provides notice
                    that such amounts are overdue to Customer;
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    You do not log in to or otherwise use the Service for a
                    period of 365 days or more if you have a free account; or
                  </Text>
                </Li>
                <Li>
                  <Text style={{ margin: 0 }}>
                    by Primalogik for convenience, with at least a 30 days prior
                    written notice for a monthly subscription, or with at least
                    a 90 days prior written notice for an annual subscription.
                  </Text>
                </Li>
              </Ol>
            </div>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>No Refund in the Event of Termination.</strong> Section
                3.3 (No Refunds) applies, regardless of the cause of
                termination. However, if Customer has terminated the Terms of
                Service pursuant to Section 7.2.2, Primalogik shall refund all
                prepaid fees for the remainder of the unused Term.
              </Text>
            </Li>
          </Ol>
        </div>

        <SubTitle>8. INDEMNIFICATION</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Indemnification by Customer.</strong> You agree to
                indemnify, defend and hold harmless Primalogik, and its
                subsidiaries, affiliates, officers, directors, agents, and
                employees from and against any and all damages, judgments,
                liability, costs and expenses (including without limitation any
                reasonable legal fees), judgments, losses and other liabilities
                (including amounts paid in settlement) (“Liabilities”) incurred
                as a result of any third-party action, claim, demand, proceeding
                or suit (“Claim”), arising from or in connection with (a) your
                use of the Service in violation of this Agreement, (b) any
                employment decision or action you take due to information
                available through your use of the Service.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Indemnification by Primalogik.</strong> Primalogik
                agrees to indemnify, defend and hold harmless you, and your
                affiliates, officers, directors, agents, and employees from and
                against any Liabilities incurred as a result of any third-party
                Claim to the extent arising from or in connection with an
                allegation that your use of the Service in accordance with this
                Agreement infringes the intellectual property rights of a third
                party. Notwithstanding the foregoing, in no event shall
                Primalogik have any obligations or liability arising from: (a)
                use of the Software and/or Service in a modified form or in
                combination with materials or software not furnished by
                Primalogik, and (b) any content, information or Data provided by
                you, your end users, or other third parties.
              </Text>
            </Li>
          </Ol>
        </div>

        <SubTitle>9. GENERAL</SubTitle>

        <div style={{ marginLeft: "4rem" }}>
          <Ol>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Publicity.</strong> Primalogik may use Customer’s
                company name and logo in Primalogik’s marketing materials and on
                Primalogiks’s public website, in each case in accordance with
                any branding guidelines you may provide to Primalogik. If you
                wish to limit such right at any time, you shall notify
                Primalogik at the following email: support@primalogik.com.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Governing Law.</strong> The Terms of Service shall be
                governed, construed and enforced solely in accordance with the
                laws applicable in the Canadian province of Quebec, without
                reference to: (i) any conflicts of law principle that would
                apply the substantive laws of another jurisdiction to the
                Parties’ rights or duties; (ii) the 1980 United Nations
                Convention on Contracts for the International Sale of Goods; or
                (iii) other international laws. Any litigation in any way
                relating to the Services or the Terms of Service shall be
                brought and venued exclusively in the judicial district of
                Montreal in the Canadian province of Quebec, and the Parties
                waive any objection that such venue is inconvenient or improper.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Force Majeure.</strong> Except as expressly provided
                otherwise in the Terms of Service, Primalogik shall not be
                liable by reason of any failure or delay in the performance of
                its obligations on account of unforeseen events beyond our
                reasonable control, including external causes with the same
                characteristics, which may include denial-of-service attacks, a
                failure by a third party hosting provider or utility provider,
                strikes, shortages, riots, fires, act of God, war, terrorism and
                governmental action.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Entire Agreement.</strong> The Agreement will constitute
                the entire agreement between us and You with respect to the
                subject matter hereof and all prior oral or written agreements,
                representations or statements with respect to such subject
                matter are superseded hereby. In the event of a conflict between
                these Terms and the Privacy Policy, the terms and conditions
                found herein shall prevail.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Assignment of Agreement.</strong> You may not, without
                our prior written consent, assign the Agreement, in whole or in
                part, either voluntarily or by operation of law, and any attempt
                to do so will be a material default of the Agreement and will be
                void. We may assign this Agreement to a third party at any time
                in our sole discretion. The Agreement will be binding upon and
                will inure to the benefit of the respective Parties hereto,
                their respective successors in interest, legal representatives,
                heirs and assigns.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>No Waiver.</strong> You agree that if we do not exercise
                or enforce any legal right or remedy which is contained in these
                Terms or which we have the benefit of under any applicable law,
                this will not be taken to be a formal waiver of our rights and
                that those rights or remedies will still be available to us.
                Waivers must be in written form and signed by an authorized
                representative of the Company.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Severability.</strong> If any portion of these Terms of
                Service is deemed unlawful, void or unenforceable by any
                arbitrator or court of competent jurisdiction, these Terms as a
                whole shall not be deemed unlawful, void or unenforceable, but
                only that portion of these Terms that is unlawful, void or
                unenforceable shall be stricken from these Terms.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Survival.</strong> The provisions of these Terms that
                should, by their nature survive termination and/or expiration,
                shall and do survive such termination and/or expiration.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Relationship of the Parties.</strong> The Parties are
                independent contractors. The Terms of Service do not create a
                partnership, franchise, joint venture, agency, fiduciary or
                employment relationship between the Parties.
              </Text>
            </Li>
            <Li>
              <Text style={{ margin: 0 }}>
                <strong>Amendments.</strong> Primalogik may modify or update the
                Terms of Service at any time. In the event we determine it is
                necessary to make a material modification to this Agreement, we
                will provide the administrators of the Customer account with
                thirty (30) days’ notice of such changes in the Terms, via
                e-mail to the e-mail address supplied to us by you. We will
                assume that you have accepted the change to the Terms, unless
                you notify us to the contrary, no later than thirty (30) days
                after the amendment comes into force, that you desire to cancel
                your subscription to the Service. Note, however, that your use
                of the Service after modifications to the Agreement become
                effective constitutes your binding acceptance of such changes.
                We will post the most current Agreement on our website and you
                may review it at:
                <A to="/terms-of-service">
                  https://primalogik.com/terms-of-service/.
                </A>
              </Text>
            </Li>
          </Ol>
        </div>
      </Container>
    </div>
  );
};

export default Content;

const Box = styled.div`
  padding-top: 10rem;
  background-image: url("/image/home/HomeSection/Bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
`;

const Ol = styled.ol`
  font-size: 16px;
  color: #47586a;
`;
const Item = styled(animated.div)`
  max-width: 1080px;
  margin: 0 auto;
  width: 80%;
`;

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto 8rem auto;
  width: 80%;
`;

const Title = styled.h1`
  padding: 10rem 0;
  font-size: 30px;
  font-weight: 600;
  color: white;
`;

const Text = styled.p`
  font-size: 16px;
  margin: 2rem 0;
  color: #47586a;
  font-weight: 100;
  line-height: 1.7em;
  word-wrap: break-word;
`;

const A = styled(Link)`
  color: var(--color-primary);
  text-decoration: none;
  margin: 0 5px;
`;

const SubTitle = styled.h2`
  margin-top: 2rem;
  font-size: 23px;
  font-weight: 600;
  color: black;
`;

const Li = styled.li`
  margin: 0;
`;
