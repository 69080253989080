import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../utils/api";
import Template from "../../view/template";

const TemplatePage = () => {
  document.title = "Template - ReviewGenie";
  const location = useLocation();
  const slug = location.hash.replace("#", "");

  const [template, setTemplate] = useState(null);
  const [templatesLoading, setTemplatesLoading] = useState(false);

  // Memoized and dependent on slug

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setTemplatesLoading(true);

        const response = await api.get(`/v1/templates/slug/${slug}`);
        if (response.data.data) {
          setTemplate(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      } finally {
        setTemplatesLoading(false);
      }
    };
    if (slug) {
      fetchTemplates();
    }
  }, [setTemplate, slug]);

  return (
    <>
      <Template template={template} templatesLoading={templatesLoading} />
    </>
  );
};

export default TemplatePage;
