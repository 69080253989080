/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const SuccessSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container>
        <BG></BG>
        <Box>
          <LeftItem>
            <Img src="./image/goalsandOKRs/3.webp"></Img>
          </LeftItem>
          <RightItem style={props} ref={ref}>
            <Heading>Create a clear path to success</Heading>
            <Text>
              Set clear and attainable goals for everyone in the organization.
              Define specific targets and deadlines foreach goal to let
              employees know what is expected of them. By doing so, employees
              will feel more motivated by their job. They will know exactly what
              they need to focus on and which tasks to prioritize.
            </Text>
            <Text>
              <Link
                to="/demo-request"
                style={{
                  color: "var(--color-link-text)",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                Learn More
              </Link>
            </Text>
          </RightItem>
        </Box>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding-top: 6rem;
  padding-bottom: 8rem;
  background-color: #fafdff;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;
const BG = styled.div`
  // background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 100px;
  top: 0;
  height: 100px;
  z-index: 1;
  transform: scale(1, 1);
  display: block;
  background-repeat-y: no-repeat;
  height: 100%;
  position: absolute;
  pointer-events: none;
  width: 100%;
  left: 0;
  right: 0;
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    padding-top: 10rem;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const LeftItem = styled.div`
  width: 48.5%;
  margin-right: 32px;
  z-index: 2;
  background-color: #fafdff;

  @media (max-width: 980px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
const RightItem = styled(animated.div)`
  width: 48.5%;
  padding: 0 0 0 10rem;
  @media (max-width: 980px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Heading = styled.h2`
  padding: 2rem 0;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
  word-wrap: break-word;
`;

export default SuccessSection;
