import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import api from "../../utils/api";

const Templates = ({ slug }) => {
  const [templates, setTemplates] = useState({
    count: 0,
    data: [],
  });
  const [categories, setCategories] = useState(null);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setTemplatesLoading(true);
        const response = await api.get("/v1/templates");

        setTemplates({
          count: response.data.pagination.count,
          data: response.data.data,
        });
        setTemplatesLoading(false);
      } catch (error) {
        setTemplatesLoading(false);
      }
    };

    const fetchCategory = async () => {
      try {
        setCategoriesLoading(true);
        const response = await api.get(`/v1/category`);

        setCategories(response.data.data);
        setCategoriesLoading(false);
      } catch (error) {
        setCategoriesLoading(false);
      }
    };

    fetchCategory();
    fetchTemplates();
  }, []);

  return (
    <Container>
      <p
        style={{
          fontWeight: 600,
          fontSize: "29px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        Our templates
      </p>
      {templatesLoading || categoriesLoading ? (
        <div
          style={{
            height: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={20}
            sx={{
              mr: 2,
            }}
          />
        </div>
      ) : (
        <TemplateBox>
          <LeftBox>
            <p style={{ fontWeight: 600, fontSize: "18px", margin: 0 }}>
              Template's categories
            </p>
            {categories?.map((category) => (
              <div
                style={{
                  marginTop: "2rem",
                  paddingTop: "2rem",
                  borderTop: "1px solid #d1d3d4",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    marginBottom: "20px",
                  }}
                >
                  {category.name}
                </p>
                <div
                  div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  {templates.data.map((item, index) =>
                    item.slug !== slug && item.categoryId === category.id ? (
                      <CustomLink to={`/template#${item.slug}`} key={index}>
                        {item.name}
                      </CustomLink>
                    ) : null
                  )}
                </div>
              </div>
            ))}
          </LeftBox>
          <RightBox>
            {categories?.map((category) => (
              <>
                <h2 style={{ fontWeight: 600, margin: 0 }}>{category.name}</h2>
                <Box>
                  {templates.data.map((item, index) =>
                    item.slug !== slug && item.categoryId === category.id ? (
                      <Item
                        to={`/template#${item.slug}`}
                        key={index}
                        bgImage={item.imageUrl}
                      >
                        <Logo src="./logo/reaction.png"></Logo>
                        <h1 style={{ textAlign: "left", marginBottom: "5rem" }}>
                          {item.name}
                        </h1>
                        <Text style={{ textAlign: "left" }}>
                          {item.description}
                        </Text>
                      </Item>
                    ) : null
                  )}
                </Box>
              </>
            ))}
          </RightBox>
        </TemplateBox>
      )}
    </Container>
  );
};

export default Templates;

const Container = styled.div`
  max-width: 1180px;
  margin: 10rem auto 10rem auto;
  width: 90%;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  min-height: 200px;
  margin: 2rem 0;
  @media (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 790px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Text = styled.p`
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; / number of lines to show /
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border:1px solid #edeeee;
  border-radius: 25px;
  overflow: hidden;
  text-decoration: none;
  color:black;
  padding: 20px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  }

  &:hover ${Text} {
    text-decoration: underline;
  }
  &:hover h1 {
    text-decoration: underline;
`;

const Logo = styled.img`
  width: 50px;
  margin-bottom: 20px;
`;

const TemplateBox = styled.div`
  display: flex;
  gap: 4rem;
  // @media (max-width: 500px) {
  //   gap: 0;
  // }
`;

const LeftBox = styled.div`
  width: 20%;
  border: 1px solid #d1d3d4;
  border-radius: 30px;
  padding: 20px;
  @media (max-width: 500px) {
    display: none;
  }
`;

const RightBox = styled.div`
  width: 80%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const CustomLink = styled(Link)`
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  color: var(--color-subtext);
  opacity: 0.7;
  &:hover {
    text-decoration: underline;
  }
`;
