/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { EMAIL, NUMBER } from "../../constants";

const FooterItem = ({ title, children }) => {
  return (
    <div subText={children} style={{ display: "block" }}>
      <FooterPrimalogik>{title}</FooterPrimalogik>
      {children && (
        <div>
          {children.map((child) => (
            <Link to={child.link} style={{ textDecoration: "none" }}>
              <FooterText style={{ cursor: "pointer" }}>
                {child.title}
              </FooterText>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
const FooterSection = () => {
  return (
    <div>
      <Footer>
        <FooterContent>
          <FooterLogo>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                gap: "4rem",
              }}
            >
              <div style={{ display: "block", width: "100%" }}>
                <FooterPrimalogik>CONTACT</FooterPrimalogik>
                <FooterText style={{ cursor: "pointer" }}>
                  <a
                    href={EMAIL}
                    style={{
                      textDecoration: "none",
                      color: "var(--color-footer-subtext)",
                    }}
                  >
                    {EMAIL}
                  </a>
                </FooterText>
                <FooterText style={{ cursor: "pointer" }}>
                  <a
                    href={NUMBER}
                    style={{
                      textDecoration: "none",
                      color: "var(--color-footer-subtext)",
                    }}
                  >
                    {NUMBER}
                  </a>
                </FooterText>
              </div>
              <div>
                <Logo src="./Review-Genie-Logo-Light.png" />
                <div style={{ display: "flex" }}>
                  <FooterLogoLink>
                    <a href="/" style={{ color: "white" }}>
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </FooterLogoLink>
                  <FooterLogoLink>
                    <a href="/" style={{ color: "white" }}>
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                  </FooterLogoLink>
                  <FooterLogoLink>
                    <a href="/" style={{ color: "white" }}>
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </FooterLogoLink>
                  <FooterLogoLink>
                    <Link to="/" style={{ color: "white" }}>
                      <i className="fa-solid fa-rss"></i>
                    </Link>
                  </FooterLogoLink>
                </div>
              </div>
            </div>
          </FooterLogo>
          <FooterItem
            title="REVIEWGENIE"
            children={[
              {
                title: "About",
                link: "/about",
              },
              {
                title: "Pricing",
                link: "/pricing",
              },
              {
                title: "Contact Us",
                link: "/contact-us",
              },
              {
                title: "Login",
                // link: "/login",
                link: `${process.env.REACT_APP_ADMIN_URL}/login`,
              },
            ]}
          />
          <FooterItem
            title="PRODUCT"
            children={[
              {
                title: "Overview",
                link: "/product",
              },
              {
                title: "360 Feedback",
                link: "/360-degree-feedback",
              },
              {
                title: "Reviews",
                link: "/performance-reviews",
              },
              {
                title: "Goals",
                link: "/goals",
              },
              {
                title: "Instant Feedback",
                link: "/instant-feedback",
              },
              {
                title: "Opinion Surveys",
                link: "/opinion-surveys",
              },
            ]}
          />
          <FooterItem
            title="RESOURCES"
            children={[
              {
                title: "Help Center",
                link: "/help-center",
              },
              {
                title: "Blog",
                link: "/blog",
              },
              {
                title: "Demo",
                link: "/demo-request",
              },
              {
                title: "Free Trial",
                // link: "/free-trial",
                link: `${process.env.REACT_APP_ADMIN_URL}/register`,
              },
            ]}
          />
        </FooterContent>

        <FooterCopyRight>
          <FooterCopyRightText>
            © 2023 ReviewGenie Software Solutions Inc. All rights reserved. ·
            <a
              href="/terms-of-service"
              style={{
                fontWeight: "500",
                color: "#f0f0f0",
                cursor: "pointer",
                textDecoration: "none",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            >
              Terms of service
            </a>
            ·
            <a
              href="/privacy-policy"
              style={{
                fontWeight: "500",
                color: "#f0f0f0",
                cursor: "pointer",
                textDecoration: "none",
                marginLeft: "0.5rem",
              }}
            >
              Privacy policy
            </a>
          </FooterCopyRightText>
        </FooterCopyRight>
      </Footer>
    </div>
  );
};

const Footer = styled.div`
  margin-top: 6rem;
  padding-top: 4rem;
  padding-bottom: 0.4rem;
  background-image: url("/image/home/HomeSection/Bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 980px) {
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
const FooterContent = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 2.7rem;
  margin-bottom: 2rem;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 760px) {
    width: 80%;
    justify-items: left;
    padding-top: 3rem;
    grid-template-columns: repeat(3, 1fr);
    // display: block;
    // align-items: left;
    gap: 8rem;
  }
  @media (max-width: 660px) {
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 400px) {
    width: 80%;
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }
`;
const FooterLogo = styled.div`
  padding-right: 5.1rem;
  @media (max-width: 980px) {
    padding-right: 0;
  }
`;
const Logo = styled.img`
  width: 16.5rem;
  margin-bottom: 2rem;
  @media (max-width: 980px) {
    // height: 5.4rem;
    width: 28rem;
  }
  // @media (max-width: 480px) {
  //   max-width: 25rem;
  //   width: 100%;
  // }
`;
const FooterLogoLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border: 1px solid #ffffff80;
  border-radius: 20%;
  background-color: transparent;
  color: var(--color-text);
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 1rem;
  @media (max-width: 980px) {
    height: 4.5rem;
    width: 4.5rem;
    font-size: 3rem;
  }
`;
const FooterPrimalogik = styled.h4`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  color: var(--color-footer-heading-text);
  line-height: 2em;
  padding-bottom: 1.5rem;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  padding-top: 0.4rem;
  font-size: 1.8rem;
  display: block;
  margin: 0;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.8rem;
  }
`;
const FooterText = styled.p`
  color: var(--color-footer-subtext);
  padding-bottom: 1em;
  line-height: 1.1em;
  margin: 0;
  font-size: 1.55rem;
  font-weight: 300;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.4rem;
  }
  &hover: {
    color: var(--color-primary);
  }
`;

const FooterCopyRight = styled.div`
  // width: 56.5%;
  width: 90%;
  max-width: 1100px;
  margin: 5rem auto 0 auto;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  @media (max-width: 980px) {
    width: 75%;
    margin-top: 6rem;
  }
`;
const FooterCopyRightText = styled.div`
  color: #a9b0be !important;
  padding: 2.7rem 0;
  font-size: 1.3rem;
  font-weight: 300;
  @media (max-width: 980px) {
    padding: 3rem 0;
    font-size: 1.8rem;
  }
`;
export default FooterSection;
