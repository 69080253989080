import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const MoreCustomerStories = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Title style={{ fontSize: "30px", fontWeight: 600 }}>
          More Customer Stories
        </Title>
        <Card>
          <LeftCard>
            <Box>
              <Img src="./image/customer-stories/4.webp"></Img>
              <Text style={{ color: "#46586a", fontWeight: 400 }}>
                How ReviewGenie helped Endeavor take a coaching approach to
                performance management.
              </Text>
              {/* <Text
                style={{
                  color: "var(--color-link-text)",
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                read the story
              </Text> */}
            </Box>
          </LeftCard>
          <CenterCard>
            <Box>
              <Img src="./image/customer-stories/5.webp"></Img>
              <Text style={{ color: "#46586a", fontWeight: 400 }}>
                How can you measure your organization’s health when the results
                are fragmented?
              </Text>
              {/* <Text
                style={{
                  color: "var(--color-link-text)",
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                read the story
              </Text> */}
            </Box>
          </CenterCard>
          <RightCard>
            <Box>
              <Img src="./image/customer-stories/6.webp"></Img>
              <Text style={{ color: "#46586a", fontWeight: 400 }}>
                How the 40-year business advisory firm replaced legacy systems
                with a more intuitive solution.
              </Text>
              {/* <Text
                style={{
                  color: "var(--color-link-text)",
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                read the story
              </Text> */}
            </Box>
          </RightCard>
        </Card>
      </Container>
    </div>
  );
};

export default MoreCustomerStories;

const Container = styled(animated.div)`
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 5rem;
`;

const Text = styled.p`
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: black;
  font-weight: 300;
`;
const Title = styled.h5`
  margin: 0;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

const LeftCard = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const CenterCard = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const RightCard = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const Img = styled.img`
  width: 80%;
  @media (max-width: 768px) {
    width: 50%;
  }
`;
