/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const IncorporateSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container>
        <Box>
          <LeftItem style={props} ref={ref}>
            <Heading>Incorporate goals in performance reviews</Heading>
            <Text>
              Have your employees’ individual goals evaluated in their
              performance reviews. ReviewGenie will automatically pull their
              goals and include them in the review form, showing the current
              goal status, deadline and completion percentage. You can then rate
              them and provide feedback to acknowledge if employees are meeting
              expectations.
            </Text>
            <Text>
              <Link
                to="/demo-request"
                style={{
                  color: "var(--color-link-text)",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                Learn More
              </Link>
            </Text>
          </LeftItem>
          <RightItem>
            <Image>
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                  maxWidth: "100%",
                }}
              >
                <Img src="./image/goalsandOKRs/8.webp"></Img>
              </span>
            </Image>
          </RightItem>
        </Box>
      </Container>
    </div>
  );
};

const Container = styled.div`
  padding-top: 6rem;
  padding-bottom: 8rem;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 980px) {
    padding-top: 0;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    padding-top: 10rem;
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const RightItem = styled.div`
  width: 48.5%;
  padding-left: 4rem;
  position: relative;
  background-color: white;
  z-index: 2;
  @media (max-width: 980px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
`;
const Image = styled.div`
  width: 100%;
  text-align: right;
  display: block;
  position: relative;
  margin-left: auto;
  @media (max-width: 980px) {
    width: 100%;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;
`;

const LeftItem = styled(animated.div)`
  width: 48.5%;
  //   margin: auto;

  @media (max-width: 980px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Heading = styled.h2`
  padding: 2rem 0;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
  word-wrap: break-word;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
  word-wrap: break-word;
`;

export default IncorporateSection;
