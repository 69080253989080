import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const MFCSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Content>
          <Heading>
            Foster Meaningful Conversations Between Managers and Employees
          </Heading>
          <Text>
            Enhance workplace communication and growth with ReviewGenie. Our
            platform offers a range of features designed to help managers
            provide ample feedback, ensure fairness, and keep track of
            individual goals. Empower your team and support their professional
            development over time with ReviewGenie.
          </Text>
        </Content>
      </Container>
      <ForImage style={props} ref={ref}>
        <ImageContainer>
          <Img src="./image/performanceReviews/understand.jpg" />
        </ImageContainer>
      </ForImage>
    </div>
  );
};

const Container = styled(animated.div)`
  width: 80%;
  max-width: 1080px;
  margin: 5rem auto 0 auto;
  position: relative;
  only screen and (min-width: 1350px) {
    padding: 27px 0;
  }
  @media (max-width: 980px) {
    padding: 0;
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Content = styled.div`
  font-size: 18px;
  padding: 0 80px;
  margin-bottom: 49px;
  text-align: center;
  word-wrap: break-word;
  @media (max-width: 980px) {
    padding-right: 0px;
    padding-left: 0px;
    text-align: left;
  }
`;

const Heading = styled.h1`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-weight: 400;
  color: #46586a;
`;

const ForImage = styled(animated.div)`
  width: 35%;
  max-width: 1080px;
  margin: auto;
  position: relative;
  @media (max-width: 980px) {
    padding: 0;
    width: 80%;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ImageContainer = styled.div`
  text-align: center;
  @media (max-width: 980px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
export default MFCSection;
