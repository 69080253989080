import React from "react";
import Title from "./components/Title";

const Audio = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <audio controls id="html-audio" src={block.settings.videoUrl} autoplay>
          Your browser does not support the <code>audio</code> element.
        </audio>
      </div>
    </div>
  );
};

export default Audio;
