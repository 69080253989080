import React from "react";
import Title from "./components/Title";

const ImageBlock = ({ block, blockIndex }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Title
        label={block.settings.fieldLabel}
        required={block.settings.required}
        description={block.settings?.description}
        blockIndex={blockIndex}
      />
      <img src={block.settings.imageUrl} alt="123"></img>
    </div>
  );
};

export default ImageBlock;
