/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const Item = ({ heading, text, imgSrc, onItemClicked, isSelected, index }) => {
  return (
    <ItemBox
      onClick={() => onItemClicked(imgSrc, index)}
      isSelected={isSelected}
    >
      <ItemHeading>{heading}</ItemHeading>
      <ItemText>{text}</ItemText>
    </ItemBox>
  );
};
const GetClearSection = () => {
  const [imageSrc, setImageSrc] = useState(
    "./image/degreeFeedback/Survey_Results.png"
  );
  const [isSelected, setIsSelected] = useState(0);

  const handleItemClicked = (newImageSrc, index) => {
    setImageSrc(newImageSrc);
    setIsSelected(index);
  };

  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Content>
          <Heading>Get clear and concise 360 degree feedback results</Heading>
          <Text>
            Survey results can be viewed instantly and analyzed from different
            angles. You can easily leverage 360 degree feedback data to better
            understand and help improve employee performance.
          </Text>
        </Content>
      </Container>
      <Box style={props} ref={ref}>
        <RightItem>
          <Item
            heading="Insights with Survey Results"
            text={
              <div>
                <p>Quickly analyze responses with ReviewGenie:</p>
                <ul>
                  <li>View rating averages</li>
                  <li>Compare with self-ratings</li>
                  <li>Inform development plans</li>
                </ul>
                <p style={{ marginBottom: 0 }}>
                  Drive growth with data-driven feedback.
                </p>
              </div>
            }
            imgSrc="./image/degreeFeedback/Survey_Results.png"
            onItemClicked={handleItemClicked}
            index={0}
            isSelected={isSelected === 0}
          />

          <Item
            heading="Unleash Comparison Mode:"
            text={
              <div>
                <p>ReviewGenie's enhances 360-degree feedback by:</p>
                <ul>
                  <li>Compare scores</li>
                  <li>Identify top performers</li>
                  <li>Drive data-driven decisions</li>
                </ul>
                <p style={{ marginBottom: 0 }}>
                  Optimize team strengths and growth.
                </p>
              </div>
            }
            imgSrc="./image/degreeFeedback/Comparison_Mode.png"
            onItemClicked={handleItemClicked}
            index={1}
            isSelected={isSelected === 1}
          />
          <Item
            heading="Assessment Bias Detection:"
            text={
              <div>
                <p>ReviewGenie's Report ensures fair feedback by: </p>
                <ul>
                  <li>Detecting bias patterns</li>
                  <li>Showing respondent from lowest to highest</li>
                  <li>Ensuring feedback for informed decisions</li>
                </ul>
                <p style={{ marginBottom: 0 }}>
                  Optimize team strengths and growth.
                </p>
              </div>
            }
            imgSrc="./image/degreeFeedback/Report.png"
            onItemClicked={handleItemClicked}
            index={2}
            isSelected={isSelected === 2}
          />
        </RightItem>
        <LeftItem>
          <Img src={imageSrc} />
        </LeftItem>
      </Box>
    </div>
  );
};

const Container = styled(animated.div)`
  width: 80%;
  margin: auto;
  position: relative;
  only screen and (min-width: 1350px) {
    padding: 27px 0;
  }
  @media (max-width: 980px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Content = styled.div`
  font-size: 18px;
  padding-right: 128px !important;
  padding-left: 128px !important;
  margin-bottom: 49px !important;
  text-align: center;
  word-wrap: break-word;
  @media (max-width: 980px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left;
  }
`;

const Heading = styled.h1`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-weight: 300;
  color: #46586a;
`;

const Box = styled(animated.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  max-width: 1080px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    flex-wrap: wrap-reverse;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const LeftItem = styled.div`
  width: 100%;
  margin-bottom: 1.6rem;
  @media (max-width: 980px) {
    max-width: 60%;
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const Img = styled.img`
  max-width: 100%;
  height: auto;
`;
const RightItem = styled.div`
  width: 70%;
  @media (max-width: 980px) {
    width: 100%;
    margin: auto;
}
  }
  @media (max-width: 768px) {
      width: 100%;
    padding: 0;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const ItemBox = styled.div`
  padding: 2rem 2rem 1rem 2rem;
  text-align: left;
  word-wrap: break-word;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#6b4ab3" : "initial")};
  color: ${(props) => (props.isSelected ? "white" : "#161a24")};
  border-radius: 10px;
  @media (max-width: 980px) {
    max-width: 550px;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const ItemHeading = styled.h3`
  font-size: 20px;
  line-height: 1.2em;
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
`;

const ItemText = styled.p`
  font-size: 16px;
`;

export default GetClearSection;
