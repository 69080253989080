/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const ValuableInsightsSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Container style={props} ref={ref}>
        <Content>
          <Heading>Collect Valuable Insights from Employees</Heading>
          <Text>
            Design and launch employee satisfaction surveys to gather actionable
            feedback from employees. Analyze survey responses to identify how
            satisfied employees are with their current tasks, company policies,
            and programs. Leverage this valuable data to improve workplace
            culture and boost employee engagement.
          </Text>
        </Content>
      </Container>
    </div>
  );
};

const Container = styled(animated.div)`
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
  only screen and (min-width: 1350px) {
    padding: 27px 0;
  }
  @media (max-width: 980px) {
    // padding: 30px 0;
    padding: 0;
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Content = styled.div`
  font-size: 18px;
  padding: 0 50px !important;
  margin-bottom: 49px !important;
  text-align: center;
  word-wrap: break-word;
  @media (max-width: 980px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left;
  }
`;

const Heading = styled.h1`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-weight: 400;
  color: #46586a;
`;

export default ValuableInsightsSection;
