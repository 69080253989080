import React from "react";
import { APP_NAME } from "../../constants";
import GoalsAndOKRs from "../../view/goalsandOKRs";

const GoalsAndOKRsPage = () => {
  document.title = `Goals & OKRs - ${APP_NAME}`;
  return <GoalsAndOKRs />;
};

export default GoalsAndOKRsPage;
