import React from "react";
import { APP_NAME } from "../../constants";
import OpinionSurveys from "../../view/opinionSurveys";

const OpinionSurveysPage = () => {
  document.title = `Opinion Surveys - ${APP_NAME}`;
  return <OpinionSurveys />;
};

export default OpinionSurveysPage;
