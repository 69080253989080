import React from "react";
import Header from "../common/header/Header";
import AnalyticsSection from "./AnalyticsSection";
import DiveSection from "./DiveSection";
import StatusOfLaunchedSection from "./StatusOfLaunchedSection";
import PerformanceReportingSection from "./PerformanceReportingSection";
import IdentifyPatternSection from "./IdentifyPatternSection";
import MeasureROISection from "./MeasureROISection";
import AdvancedReportsSection from "./AdvancedReportsSection";
import FooterSection from "../common/FooterSection";
import PrimalogikSection from "../home/Primalogiksection";
import GetStartedSection from "../product/GetStartedSection";
import DiscoverMoreSection from "../performanceReviews/DiscoverMoreSection";
import Faqssection from "../home/Faqssection";
import OrganizationSection from "../product/OrganizationSection";

const AnalyticsReporting = () => {
  return (
    <>
      <Header />
      <AnalyticsSection />
      <OrganizationSection />
      <DiveSection />
      <StatusOfLaunchedSection />
      <PerformanceReportingSection />
      <GetStartedSection />
      <IdentifyPatternSection />
      <MeasureROISection />
      <AdvancedReportsSection />
      <DiscoverMoreSection />
      <PrimalogikSection />
      <Faqssection />
      <FooterSection />
    </>
  );
};

export default AnalyticsReporting;
