import React from "react";
import styled from "styled-components";

const Logo = ({ link }) => {
  return (
    <div>
      <LogoImg src={link} alt="logo" />
    </div>
  );
};

const OrganizationSection = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--keyFeatures-logo-bg-color)",
        margin: "4rem 0",
        boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ padding: "3rem 0" }}></div>
      <Container>
        <Heading>
          Our services are trusted by renowned organizations globally
        </Heading>
      </Container>
      <Organization>
        <OrganizationLogo>
          <Logo link="./image/home/OrganizationSection/Org_1.svg" />
          <Logo link="./image/home/OrganizationSection/Org_5.png" />
          <Logo link="./image/home/OrganizationSection/Org_2.svg" />
          <Logo link="./image/home/OrganizationSection/Org_3.svg" />
          <Logo link="./image/home/OrganizationSection/Org_4.svg" />
        </OrganizationLogo>
      </Organization>
    </div>
  );
};

const Container = styled.div`
  width: 90%;
  text-align: center;
  max-width: 1050px;
  margin: 0 auto;
  opacity: 0.6;
`;

const Heading = styled.div`
  color: black;
  font-size: 2.5rem;
  -webkit-text-size-adjust: 100%;
  @media (max-width: 980px) {
    padding: 3rem 0;
  }
`;

const Organization = styled.div`
  color: var(--color-text);
  font-size: 100%;
  padding: 4rem 0 4rem 0;
  -webkit-text-size-adjust: 100%;
  @media (max-width: 980px) {
    padding: 3rem 0;
  }
`;

const OrganizationLogo = styled.div`
  width: 90%;
  max-width: 1050px;
  margin: 0 auto;
  opacity: 0.6;
  display: flex;
  justify-content: space-between;
  @media (max-width: 980px) {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
  }
`;

const LogoImg = styled.img`
  height: 13rem;
  width: 13rem;
  @media (max-width: 980px) {
    width: 90%;
    height: 90%;
  }
`;

export default OrganizationSection;
