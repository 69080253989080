import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(180deg, #f0e5ff, #f9effe);
  text-align: center;
`;

const Icon = styled.div`
  font-size: 8rem;
  margin-bottom: 20px;
  color: #6c6f93;
`;

const Heading = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
`;

const Subtext = styled.p`
  font-size: 1.5rem;
  color: #6c6f93;
  max-width: 400px;
  margin-bottom: 30px;
`;

const Error404 = () => {
  return (
    <Container>
      <Icon>❌</Icon> {/* You can replace this with an SVG or any other icon */}
      <Heading>Oops! Page Not Found</Heading>
      <Subtext>
        Even the best explorers know that getting lost is just another way to
        find something new.
      </Subtext>
      <Button
        onClick={() => window.history.back()}
        variant="contained"
        sx={{
          fontSize: 13,
          backgroundColor: "var(--color-primary)",
          "&.MuiButtonBase-root": {
            ":hover": {
              backgroundColor: "var(--color-primary-dark)",
            },
          },
        }}
        startIcon={<ArrowBackIcon />}
      >
        Go Back
      </Button>
    </Container>
  );
};

export default Error404;
