/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";
const OpinionSurveysSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef} id="5">
      <FeedBack>
        <FeedBackBox>
          <FeedBackLeftItem>
            <FeedBackImg src="./image/product/Opinion.jpg"></FeedBackImg>
          </FeedBackLeftItem>
          <FeedBackRightItem style={props} ref={ref}>
            <FeedBackLogo>
              <Logo src="./image/product/11.svg"></Logo>
            </FeedBackLogo>
            <Heading>Opinion Surveys for Organizational Insights</Heading>
            <Text>
              Gather Honest Feedback to Improve Your Workplace: Collect honest
              feedback to learn how your organization measures up by launching
              surveys. Analyze responses to identify key factors that influence
              employee satisfaction.
            </Text>
            <Text>Key Benefits:</Text>
            <ul>
              <Text>
                <li>
                  Gain Valuable Insights: Understand employee perspectives and
                  areas needing improvement.
                </li>
              </Text>
              <Text>
                <li>
                  Enhance Employee Satisfaction: Address issues that matter most
                  to your team.
                </li>
              </Text>
              <Text>
                <li>
                  Improve Organizational Culture: Implement changes based on
                  survey data to foster a positive work environment.
                </li>
              </Text>
            </ul>
            <MoreButton>
              <Linked to="/opinion-surveys">LEARN MORE</Linked>
            </MoreButton>
          </FeedBackRightItem>
        </FeedBackBox>
      </FeedBack>
    </div>
  );
};
const FeedBack = styled.div`
  @media (max-width: 980px) {
    padding-top: 7rem;
    padding-bottom: 0.5rem;
    margin-bottom: 4px;
  }
`;
const FeedBackBox = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 1100px;
  margin: auto;
  @media (max-width: 980px) {
    width: 80%;
    display: flex;
    flex-wrap: wrap-reverse;
  }
`;
const FeedBackLeftItem = styled.div`
  width: 100%;
  z-index: 1;
  background-color: white;
`;
const FeedBackImg = styled.img`
  max-width: 90%;
  height: auto;
`;
const FeedBackRightItem = styled(animated.div)`
  width: 100%;
  padding: 3rem 3.6rem 5rem 3.6rem;
  @media (max-width: 980px) {
    padding: 0;
  }
`;
const FeedBackLogo = styled.div`
  background-color: rgba(228, 239, 255, 0.61);
  width: 80px !important;
  height: 80px !important;
  padding: 23px;
  border-radius: 50%;
  margin-bottom: 30px;
  max-width: 100%;
`;
const Logo = styled.img`
  width: auto;
`;
const Heading = styled.h2`
  padding-bottom: 18px;
  font-size: 27px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4em;
  color: #161a24;
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #46586a;
  font-size: 16px;
  font-weight: 100;
  margin: 0;
  padding-bottom: 1em;
`;

const MoreButton = styled.div`
  width: 40%;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  padding: 0.8rem 1.5rem;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1150px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
`;

const Linked = styled(Link)`
  text-decoration: none;
  color: var(--color-link-text);
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
`;

export default OpinionSurveysSection;
