import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const MobileManuBar = ({ items, hidden }) => {
  const location = useLocation();
  return (
    <MobileMenu isHidden={hidden}>
      <div
        style={{
          padding: "5rem",
        }}
      >
        {items.map(({ title, children, component, link }, index) => (
          <div>
            <MobileMenuItem
              isCurrent={location.pathname === link}
              key={index}
              subMenu={children}
            >
              {component ? (
                component
              ) : link ? (
                <MobileManuLink
                  isCurrent={location.pathname === link}
                  to={link}
                >
                  {title}
                </MobileManuLink>
              ) : (
                <p>{title}</p>
              )}
            </MobileMenuItem>
            {children && (
              <div>
                {children.map((child, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <MobileLink
                      to={child.link}
                      // style={{ textDecoration: "none", color: "#222222" }}
                      isCurrent={location.pathname === child.link}
                    >
                      {/* <MobileSubMenuItem key={child.title}> */}
                      {child.title}
                      {/* </MobileSubMenuItem> */}
                    </MobileLink>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </MobileMenu>
  );
};

export default MobileManuBar;

const MobileMenu = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  border-top: ${(props) =>
    props.isHidden ? "0" : "3px solid var(--color-primary)"};
  font-size: 2.2rem;
  background-color: #fff;
  cursor: pointer;
  transition: height 0.5s ease-in-out;
  height: ${(props) => (props.isHidden ? "0" : "80vh")};
  overflow-y: scroll;

  &&::-webkit-scrollbar {
    display: none;
  }
  && {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media (min-width: 1020px) {
    display: none;
  }
`;

const MobileManuLink = styled(Link)`
  text-wrap: nowrap;
  width: 100%;
  display: flex;
  font-weight: bold;
  text-decoration: none;
  color: ${(props) => (props.isCurrent ? "var(--color-primary)" : "black")};
  background-color: #fafafa;
  &&:hover {
    background-color: #fafafa;
    border-radius: 3px;
  }
  &&:hover > a {
    opacity: 0.7;
  }
`;

const MobileMenuItem = styled.div`
  text-wrap: nowrap;
  align-items: left;
  width: 100%;
  margin-bottom: 0;
  padding: 1.65rem 5%;
  background-color: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  display: flex;

  ${(props) =>
    props.subMenu &&
    `background-color: #fafafa; 
    font-weight: bold;
  `}
  &&:hover > a {
    opacity: 0.7;
  }
  &&:hover {
    opacity: 0.7;
    background-color: #fafafa;
  }
`;

const MobileLink = styled(Link)`
  text-wrap: nowrap;
  align-items: center;
  margin-left: 5rem;
  margin-bottom: 0;
  padding: 2rem 5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  text-decoration: none;
  color: ${(props) => (props.isCurrent ? "var(--color-primary)" : "black")};
  background-color: ${(props) => (props.isCurrent ? "#fafafa" : "transparent")};
  &&:hover {
    background-color: #fafafa;
    border-radius: 3px;
  }
  &&:hover > a {
    opacity: 0.7;
  }
`;
