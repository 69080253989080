/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
const GetStartedSection = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateY(0)"
        : "translateY(100px)"
      : "translateY(0)",
    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <GetStarted>
        <GetStartedContainer style={props} ref={ref}>
          <GetStartedItem>
            <Heading>Start Your Journey with ReviewGenie Today!</Heading>
            <Text>
              Schedule an online demo to discover how ReviewGenie can benefit
              your company. Our expert solutions specialists will guide you
              through the platform and address any questions you may have.
            </Text>
            <Text style={{ paddingBottom: "2rem" }}>
              Request your demo now and see the difference!
            </Text>
          </GetStartedItem>
          <DemoBtn>
            <Link to="/demo-request">
              <DemoButton variant="main">REQUEST A DEMO</DemoButton>
            </Link>
          </DemoBtn>
        </GetStartedContainer>
      </GetStarted>
    </div>
  );
};

const GetStarted = styled.div`
  background-color: #6b4ab3;
  padding: 0.8rem 0;
  margin: 8rem 0;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);
`;

const GetStartedContainer = styled(animated.div)`
  width: 75%;
  // max-width: 1080px;
  padding: 2.7rem 0;
  margin: 3rem auto;
  @media (max-width: 767px) {
    width: 95%;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`;
const GetStartedItem = styled.div`
  padding: 0 5rem;
  text-align: center;
  color: #fff;
`;
const Heading = styled.h2`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.4em;
  margin: 0;
  font-size: 4rem;
  padding: 2rem 0;
  font-weight: 600;
  @media (max-width: 980px) {
    font-size: 6rem;
  }
  @media (max-width: 767px) {
    font-size: 5rem;
  }
`;
const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 21px;
  line-height: 1.5em;
  font-weight: 100;
  margin: 0;
  @media (max-width: 980px) {
    font-size: 19.4px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;
const DemoBtn = styled.div`
  padding: 2rem 8.5rem 2rem 12rem;
  text-align: center;
  @media (max-width: 980px) {
    padding: 2rem 0;
    margin: 3rem 0 0 0;
    text-align: center;
  }
`;
const DemoButton = styled(Button)`
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1.3rem 2rem;
  background-color: transparent;
  border-radius: 28px;
  border: 2px solid #fff;
  @media (max-width: 980px) {
    font-size: 2.5rem;
  }
`;
export default GetStartedSection;
