import { ThemeProvider, Tooltip } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useInView, useSpring } from "react-spring";
import styled from "styled-components";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#f2f2f2",
        },
      },
    },
  },
});

const details = [
  {
    data: [
      {
        title: "Number of surveys",
        description:
          "You can create and send as many surveys, polls, and quizzes as you need with any plan.",
        text: "Unlimited number of surveys",
      },
      {
        title: "Number of surveys",
        description:
          "You can create and send as many surveys, polls, and quizzes as you need with any plan.",
        text: "Unlimited number of surveys",
      },
      {
        title: "Number of surveys",
        description:
          "You can create and send as many surveys, polls, and quizzes as you need with any plan.",
        text: "Unlimited number of surveys",
      },
    ],
  },
  {
    data: [
      {
        title: "Questions per survey",
        description:
          "Some of our plans only allow a certain number of questions for each survey you send. All plans allow you to create and send as many surveys as you want.",
        text: "Unlimited questions per survey",
      },
      {
        title: "Questions per survey",
        description:
          "Some of our plans only allow a certain number of questions for each survey you send. All plans allow you to create and send as many surveys as you want.",
        text: "Unlimited questions per survey",
      },
      {
        title: "Questions per survey",
        description:
          "Some of our plans only allow a certain number of questions for each survey you send. All plans allow you to create and send as many surveys as you want.",
        text: "Unlimited questions per survey",
      },
    ],
  },
  {
    data: [
      {
        title: "Number of responses",
        description:
          "Some of our plans only allow a certain number of questions for each survey you send. All plans allow you to create and send as many surveys as you want.",
        text: "40,000 responses per year**",
      },
      {
        title: "Number of responses",
        description:
          "Some of our plans only allow a certain number of questions for each survey you send. All plans allow you to create and send as many surveys as you want.",
        text: "15,000 responses per year**",
      },
      {
        title: "Number of responses",
        description:
          "Some of our plans only allow a certain number of questions for each survey you send. All plans allow you to create and send as many surveys as you want.",
        text: "1,000 responses per month**",
      },
    ],
  },
  {
    data: [
      {
        title: "24/7 Customer support via email",
        description:
          "We offer 24/7 email support for all customers. Emails from ADVANTAGE and PREMIER customers are prioritized to receive responses as quickly as possible. We also offer phone support for our PREMIER customers.",
        text: "24/7 customer support via email",
      },
      {
        title: "24/7 Customer support via email",
        description:
          "We offer 24/7 email support for all customers. Emails from ADVANTAGE and PREMIER customers are prioritized to receive responses as quickly as possible. We also offer phone support for our PREMIER customers.",
        text: "24/7 customer support via email",
      },
      {
        title: "24/7 Customer support via email",
        description:
          "We offer 24/7 email support for all customers. Emails from ADVANTAGE and PREMIER customers are prioritized to receive responses as quickly as possible. We also offer phone support for our PREMIER customers.",
        text: "24/7 customer support via email",
      },
    ],
  },
  {
    data: [
      {
        title: "Quizzes",
        description:
          "Easily create tests and assessments with automatic scoring for employees, customers, students, and more. You can choose whether you want to display your respondent's score to them after they complete your quiz. You'll also be able to see rankings, segment response data, and access statistics for your results.",
        text: "Quizzes with custom feedback",
      },
      {
        title: "Quizzes",
        description:
          "Easily create tests and assessments with automatic scoring for employees, customers, students, and more. You can choose whether you want to display your respondent's score to them after they complete your quiz. You'll also be able to see rankings, segment response data, and access statistics for your results.",
        text: "Quizzes with custom feedback",
      },
      {
        title: "Quizzes",
        description:
          "Easily create tests and assessments with automatic scoring for employees, customers, students, and more. You can choose whether you want to display your respondent's score to them after they complete your quiz. You'll also be able to see rankings, segment response data, and access statistics for your results.",
        text: "Quizzes with custom feedback",
      },
    ],
  },
  {
    data: [
      {
        title: "Data exports",
        description:
          "Create and print custom charts and reports quickly and easily. You'll be able to download survey results in several file formats, including as CSV, PDF, PPT, or XLS files. Export for SPSS for Advantage and Premier members.",
        text: "Data exports (CSV, PDF, PPT, XLS)",
      },
      {
        title: "Data exports",
        description:
          "Create and print custom charts and reports quickly and easily. You'll be able to download survey results in several file formats, including as CSV, PDF, PPT, or XLS files. Export for SPSS for Advantage and Premier members.",
        text: "Data exports (CSV, PDF, PPT, XLS)",
      },
      {
        title: "Data exports",
        description:
          "Create and print custom charts and reports quickly and easily. You'll be able to download survey results in several file formats, including as CSV, PDF, PPT, or XLS files. Export for SPSS for Advantage and Premier members.",
        text: "Data exports (CSV, PDF, PPT, XLS)",
      },
    ],
  },
  {
    data: [
      {
        title: "Customized survey experience",
        description:
          "Get more ways to personalize each respondent's experience while taking your survey. Use skip logic to create custom paths through a survey based on each respondent's answers. With question & answer piping, you can insert answer text from a question into another question on a later page in the survey.",
        text: "Skip logic, question & answer piping",
      },
      {
        title: "Customized survey experience",
        description:
          "Get more ways to personalize each respondent's experience while taking your survey. Use skip logic to create custom paths through a survey based on each respondent's answers. With question & answer piping, you can insert answer text from a question into another question on a later page in the survey.",
        text: "Skip logic, question & answer piping",
      },
      {
        title: "Customized survey experience",
        description:
          "Get more ways to personalize each respondent's experience while taking your survey. Use skip logic to create custom paths through a survey based on each respondent's answers. With question & answer piping, you can insert answer text from a question into another question on a later page in the survey.",
        text: "Skip logic only",
      },
    ],
  },
  {
    data: [
      {
        title: "Advanced survey analysis",
        description:
          "Get more context for your data. See if there are statistically significant differences between response groups. And use text analysis to search and categorize open-ended responses and quickly see frequently used words and phrases.",
        text: "Text analysis",
      },
      {
        title: "Advanced survey analysis",
        description:
          "Get more context for your data. See if there are statistically significant differences between response groups. And use text analysis to search and categorize open-ended responses and quickly see frequently used words and phrases.",
        text: "Text analysis & statistical significance",
      },
      {
        title: "Advanced survey analysis",
        description:
          "Get more context for your data. See if there are statistically significant differences between response groups. And use text analysis to search and categorize open-ended responses and quickly see frequently used words and phrases.",
        text: "Text analysis",
      },
    ],
  },
];
const Individual = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <div ref={inViewRef}>
      <Table style={props} ref={ref}>
        <thead>
          <tr>
            <TopTh>
              <H2>Premier Annual</H2>
              <Text
                style={{
                  fontWeight: 400,
                  fontSize: "3rem",
                  marginTop: "2rem",
                  color: "black",
                }}
              >
                ₨ 4,999
                <span
                  style={{
                    fontSize: "2rem",
                    color: "#6B787F",
                    marginLeft: "5px",
                  }}
                >
                  / month
                </span>
              </Text>
            </TopTh>
            <TopTh>
              <Ribbon backColor={"#00BF6F"}>BEST VALUE</Ribbon>
              <H2>Advantage Annual</H2>
              <Text
                style={{
                  fontWeight: 400,
                  fontSize: "3rem",
                  marginTop: "2rem",
                  color: "black",
                }}
              >
                ₨ 1,999
                <span
                  style={{
                    fontSize: "2rem",
                    color: "#6B787F",
                    marginLeft: "5px",
                  }}
                >
                  / month
                </span>
              </Text>
            </TopTh>
            <TopTh>
              <H2>Standard Monthly</H2>
              <Text
                style={{
                  fontWeight: 400,
                  fontSize: "3rem",
                  marginTop: "2rem",
                  color: "black",
                }}
              >
                ₨ 899
                <span
                  style={{
                    fontSize: "2rem",
                    color: "#6B787F",
                    marginLeft: "5px",
                  }}
                >
                  / month
                </span>
              </Text>
            </TopTh>
          </tr>
          <tr>
            <Th>
              <Text style={{ marginTop: "1rem" }}>
                ₨ 59,988 billed annually
              </Text>
            </Th>
            <Th>
              <Text style={{ marginTop: "1rem" }}>
                ₨ 23,988 billed annually
              </Text>
            </Th>
            <Th>
              <Text style={{ marginTop: "1rem" }}></Text>
            </Th>
          </tr>
          <tr>
            <Th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverColor="#05467E"
                    hoverBackgroundColor={"#fff"}
                    backColor={"#05467E"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Th>
            <Th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverColor="#00BF6F"
                    hoverBackgroundColor={"#fff"}
                    backColor={"#00BF6F"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Th>
            <Th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverColor="#2DCCD3"
                    hoverBackgroundColor={"#fff"}
                    backColor={"#2DCCD3"}
                  >
                    CONTACT SALES
                  </Button>
                </CustomLink>
              </div>
            </Th>
          </tr>
        </thead>
        <tbody>
          {details.map((detail, index) => (
            <tr key={index}>
              {detail.data.map((item, index) => (
                <Td key={index}>
                  <ThemeProvider theme={theme}>
                    <Tooltip
                      title={
                        <div>
                          <Text
                            style={{
                              marginTop: "1rem",
                              fontWeight: 600,
                              color: "var(--color-primary)",
                            }}
                          >
                            {item.title}
                          </Text>
                          <Text style={{ marginTop: "1rem" }}>
                            {item.description}
                          </Text>
                        </div>
                      }
                      arrow
                      placement="top"
                    >
                      <TableText>
                        <ul>
                          <li style={{ fontWeight: "large" }}>{item.text}</li>
                        </ul>
                      </TableText>
                    </Tooltip>
                  </ThemeProvider>
                </Td>
              ))}
            </tr>
          ))}
          <tr>
            <LastTh>
              <Text style={{ textAlign: "left", margin: "2rem " }}>
                {/* <Link to="/">See all plan features</Link> */}
              </Text>
            </LastTh>
            <LastTh>
              <Text style={{ textAlign: "left", margin: "2rem " }}>
                {/* <Link to="/">See all plan features</Link> */}
              </Text>
            </LastTh>
            <LastTh>
              <Text style={{ textAlign: "left", margin: "2rem 2rem 0 2rem" }}>
                {/* <Link to="/">See all plan features</Link> */}
              </Text>
            </LastTh>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <Td style={{ border: "none" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverColor="#05467E"
                    hoverBackgroundColor={"#fff"}
                    backColor={"#05467E"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Td>
            <Td style={{ border: "none" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverColor="#00BF6F"
                    hoverBackgroundColor={"#fff"}
                    backColor={"#00BF6F"}
                  >
                    SIGN UP
                  </Button>
                </CustomLink>
              </div>
            </Td>
            <Td style={{ border: "none" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              >
                <CustomLink to="/free-trial">
                  <Button
                    hoverColor="#2DCCD3"
                    hoverBackgroundColor={"#fff"}
                    backColor={"#2DCCD3"}
                  >
                    CONTACT SALES
                  </Button>
                </CustomLink>
              </div>
            </Td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default Individual;

const CustomLink = styled(Link)`
  width: 100%;
`;

const Text = styled.p`
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
  color: #6b787f;
  font-weight: 300;
  word-break: break-word;
  padding: 0 5px;
`;
const Table = styled(animated.table)`
  border-collapse: separate;
  border-spacing: 20px 0;
  margin: 0 auto 4rem auto;
  // width: 1080px;
  width: 60%;
  tbody tr:hover td {
    border-color: var(--color-primary);
    background-color: #f9f9f9;
  }
  @media (max-width: 1550px) {
    width: 70%;
  }
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const TopTh = styled.th`
  position: relative;
  width: 33%;
  border-radius: 5px 5px 0 0;
  border: 1px solid #d0d2d3;
  border-bottom: 0;
  overflow: hidden;
`;

const Th = styled.th`
  width: 33%;
  border: 1px solid #d0d2d3;
  border-bottom: 0;
  border-top: 0;
`;
const Td = styled.td`
  width: 33%;
  border: 1px solid #d0d2d3;
  border-bottom: 0;
  border-top: 0;
`;

const H2 = styled.h2`
  color: #333e48;
  font-size: 21px;
  font-weight: 500;
  padding: 32px 5px 0 5px;
  text-align: center;
  word-break: break-word;
`;

const Ribbon = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  background-color: ${(props) => props.backColor};
  color: white;
  padding: 1px 0;
  transform: translate(50%, -50%) rotate(45deg);
  z-index: 1;
  font-size: 12px;
  width: 100%;
  text-align: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Button = styled.button`
  width: 90%;
  padding: 1rem 0;
  background-color: ${(props) => props.backColor};
  color: white;
  line-height: 1.5;
  font-size: 15px;
  border: 1px solid ${(props) => props.backColor};
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
    border: 1px solid ${(props) => props.hoverColor};
  }
  transition: all 0.5s ease;
  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

const LastTh = styled.th`
  width: 33%;
  border: 1px solid #d0d2d3;
  border-top: 0;
  border-radius: 0 0 5px 5px;
`;

const TableText = styled.p`
  font-size: 1.5rem;
  margin: 1rem 5px 0 5px;
  text-align: left;
  color: #6b787f;
  font-weight: 300;
  word-break: break-word;
`;