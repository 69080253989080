import React from "react";
import Header from "../common/header/Header";
import PrimalogikForFreeSection from "../common/PrimalogikForFreeSection";
import GoalsAndOKRsSection from "./GoalsAndOKRsSection";
import SuccessSection from "./SuccessSection";
import GetStartedSection from "./GetStartedSection";
import BreakDownSection from "./BreakDownSection";
import TrackGoalsSection from "./TrackGoalsSection";
import IncorporateSection from "./IncorporateSection";
import DiscoverMoreSection from "./DiscoverMoreSection";
import FooterSection from "../common/FooterSection";
import OrganizationSection from "../common/OrganizationSection";

const GoalsAndOKRs = () => {
  return (
    <>
      <Header />
      <GoalsAndOKRsSection />
      <OrganizationSection />
      <SuccessSection />
      <GetStartedSection />
      <BreakDownSection />
      <TrackGoalsSection />
      <IncorporateSection />
      <PrimalogikForFreeSection />
      <DiscoverMoreSection />
      <FooterSection />
    </>
  );
};

export default GoalsAndOKRs;
