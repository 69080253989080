import { CircularProgress } from "@mui/material";
import React from "react";
import FooterSection from "../common/FooterSection";
import Header from "../common/header/Header";
import Description from "./Description";
import Features from "./Features";
import RelatedTemplates from "./RelatedTemplates";
import SurveySection from "./SurveySection";

const Template = ({ template, templatesLoading }) => {
  // Dependency is now valid

  return (
    <>
      <Header />
      {templatesLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={20}
            sx={{
              mr: 2,
            }}
          />
        </div>
      ) : (
        <>
          <SurveySection templates={template} />
          <RelatedTemplates
            categoryId={template?.categoryId}
            slug={template?.slug}
          />
          <Description templates={template} />
          <Features />
          {/* <SurveyProjects /> */}
        </>
      )}
      <FooterSection />
    </>
  );
};

export default Template;
