/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import { animated, useInView, useSpring } from "react-spring";
import { Link } from "react-router-dom";

const GetStarted = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,

    config: { duration: 1000 },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });
  return (
    <div ref={inViewRef}>
      <Feedback style={props} ref={ref}>
        <WorkForce>
          <Box>
            <BoxLeftItem>
              <Title>survey</Title>
              <Heading>Get Started with Ready-Made Survey Templates</Heading>
              <Text style={{ opacity: 0.8 }}>
                Build surveys and forms in minutes using our pre-made templates.
                Easily customizable for better response rates, our templates
                help you create professional surveys quickly.
              </Text>
              <Div>
                <Btn>
                  <Link to={`${process.env.REACT_APP_ADMIN_URL}/register`}>
                    <ReqButton variant="secondary">
                      Sign up with email
                    </ReqButton>
                  </Link>
                </Btn>
                <Btn>
                  <Link to={`${process.env.REACT_APP_ADMIN_URL}/register`}>
                    <ReqButton variant="secondary">
                      Sign up with Google
                    </ReqButton>
                  </Link>
                </Btn>
                <Btn>
                  <Link to={`${process.env.REACT_APP_ADMIN_URL}/register`}>
                    <ReqButton variant="secondary">
                      Sign up with Microsoft
                    </ReqButton>
                  </Link>
                </Btn>
              </Div>
            </BoxLeftItem>
            {/* <BoxRightItem>
              <Img src="https://prod.smassets.net/assets/content/sm/survey-templates-hub-hero.webp"></Img>
            </BoxRightItem> */}
          </Box>
        </WorkForce>
      </Feedback>
    </div>
  );
};

const Feedback = styled(animated.div)`
  background-image: url("./image/all-template/Product_Bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: 500px) {
    background-size: cover;
    background-position: center;
  }
`;

const WorkForce = styled.div`
  padding-top: 14rem;
  padding-bottom: 9rem;
  margin-bottom: 1px;
  bottom: auto;

  @media (max-width: 980px) {
    // padding: 0;
    margin-bottom: 4px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  // padding: 7rem 0 0.5rem 0;
  margin: 2rem auto 0.4rem auto;
  max-width: 1180px;
  width: 90%;
  @media (max-width: 980px) {
    padding-top: 0;
    flex-wrap: wrap;
    width: 80%;
  }
  @media (max-width: 480px) {
    width: 80%;
  }
`;

const BoxLeftItem = styled.div`
  padding-right: 3rem;
  margin: 0 1rem 1.6rem 0;
  text-align: center;
  @media (max-width: 980px) {
    padding-right: 0;
  }
  @media (max-width: 480px) {
    margin: 0 0 5.6rem 0;
  }
`;

const Title = styled.h4`
  color: var(--color-primary);
  font-size: 19px;
  font-weight: 600;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  min-height: 17px;
  padding: 1rem 0 0 0;
  margin: 3rem 0 0.4rem 0;
  word-wrap: break-word;
  @media (max-width: 980px) {
    margin: 0;
    padding-bottom: 1rem;
  }
`;

const Heading = styled.h1`
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 32px;
  color: #000000;
  line-height: 1.4em;
  font-weight: 900;
  padding-bottom: 10px;
  margin: 0;
  word-wrap: break-word;
`;

const Text = styled.p`
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #23282d;
  line-height: 1.8em;
  padding-bottom: 2.1rem;
  margin: 0;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 3.2rem;
    padding-bottom: 2.5rem;
  }
`;

const Btn = styled.div`
  font-family: Poppins, Helvetica, Arial, Lucida, sans-serif;
  cursor: pointer;
  && ::after {
    font-family: ETmoudles;
    content: $;
    font-size: 1.6rem;
    position: absolute;
  }
  @media (max-width: 980px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
  }
`;

const ReqButton = styled(Button)`
  font-size: 1.5rem;
  padding: 1.3rem 3rem;
  border-radius: 28px;
  font-weight: 100;
  word-wrap: break-word;
  @media (max-width: 980px) {
    font-size: 2.5rem;
    font-weight: 100;
    padding: 1rem 3rem;
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
// const BoxRightItem = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const Img = styled.img`
//   width: 100%;
// `;

export default GetStarted;
