import React from "react";

import { APP_NAME } from "../../constants";
import About from "../../view/about";

const AboutPage = () => {
  document.title = `About Us - ${APP_NAME}`;
  return <About />;
};

export default AboutPage;
