import React from "react";
import { APP_NAME } from "../../constants";
import InstantFeedback from "../../view/instantFeedback";

const InstantFeedbackPage = () => {
  document.title = `Instant Feedback - ${APP_NAME}`;
  return <InstantFeedback />;
};

export default InstantFeedbackPage;
