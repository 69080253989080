import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animated, config, useInView, useSpring } from "react-spring";
import styled from "styled-components";
// import { APP_NAME } from "../../constants";

const Culture = () => {
  const ref = useRef();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const props = useSpring({
    opacity: !hasAnimated ? (inView ? 1 : 0) : 1,
    transform: !hasAnimated
      ? inView
        ? "translateX(0)"
        : "translateX(-100px)"
      : "translateX(0)",
    config: config.gentle,
    from: { opacity: 0, transform: "translateX(-100%)" },
    onRest: () => {
      if (inView && !hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  return (
    <Container ref={inViewRef}>
      <Box>
        <RightBox style={props} ref={ref}>
          <Text
            style={{ marginTop: "3rem", fontSize: "30px", fontWeight: 600 }}
          >
            A Culture of Diversity, Equity, and Inclusion
          </Text>
          <Text
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              fontSize: "16px",
              fontWeight: 100,
            }}
          >
            At Our Organization, we prioritize listening and incorporating
            feedback from our employees to foster mutual growth. Achieving our
            goals in diversity, equity, and inclusion is as vital to our success
            as launching innovative products and meeting financial targets.
          </Text>
          <Link to="/demo-request">
            <Button>Learn more</Button>
          </Link>
        </RightBox>
        <LeftBox>
          <Img src="./image/about/culture.jpg" />
        </LeftBox>
      </Box>
    </Container>
  );
};

export default Culture;

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`;

const Box = styled.div`
  display: flex;
  margin-top: 5rem;
  width: 100%;
  @media (max-width: 1230px) {
    padding: 0 6rem;
  }
  @media (max-width: 990px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftBox = styled.div`
  width: 50%;
  background-color: white;
  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: black;
  line-height: 1.5;
  text-align: left;
  margin: 0;
`;

const RightBox = styled(animated.div)`
  margin-left: 1rem;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; // Ensures children align to the start
  @media (max-width: 990px) {
    width: 80%;
  }
`;

const Img = styled.img`
  width: 100%;
  @media (max-width: 990px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
`;
const Button = styled.button`
  padding: 0.8rem 1.5rem;
  background-color: ${(props) =>
    props.backColor ? props.backColor : "transparent"};
  border: 1px solid var(--color-primary);
  border-radius: 20px;
  color: var(--color-primary);
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 990px) {
    font-size: 3rem;
    padding: 0.5rem 1.7rem;
  }
  @media (max-width: 540px) {
    font-size: 2.3rem;
    padding: 0.8rem 1.7rem;
  }
`;
